import axios from "axios";
import axiosRetry from "axios-retry";
// import { toast } from "react-toastify";
// import logger from "./logService";
const { REACT_APP_API_ENDPOINT, REACT_APP_CONSOLE_ERROR } = process.env;

// setting environment specific base URL
axios.defaults.baseURL = REACT_APP_API_ENDPOINT;

// configuring response data & unexpected error middleware
axios.interceptors.response.use(
  (response) => {
    return response.data;
  }
  // (error) => {
  //   const expectedError =
  //     error.response &&
  //     error.response.status >= 400 &&
  //     error.response.status < 500;

  //   if (!expectedError) {
  //     logger.log("Logging the error", error);
  //     toast.error("An unexpected error occurred.");
  //   }

  //   return Promise.reject(error);
  // }
);

// retry HTTP calls when reponse error status is 0
axiosRetry(axios, {
  retries: 3,
  retryDelay: () => 10 * 1000,
  retryCondition: (error) => error?.response?.status === 0,
  onRetry: (retryCount, error, requestConfig) => {
    if (REACT_APP_CONSOLE_ERROR === "yes") {
      const { baseURL, url } = requestConfig;
      const apiUrl = baseURL + url;
      console.log(`Retrying ${apiUrl}. Retry count ${retryCount}`);
    }
  },
});

export default {
  get: axios.get,
  put: axios.put,
  post: axios.post,
  delete: axios.delete,
};
