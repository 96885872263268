import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

const FormField = tw.div`h-48`;
const TextAreaContainer = tw.div`relative mt-6 mb-1 border-b-2 focus-within:border-primary-500`;
const InputField = tw.input`pb-1 font-normal block w-full appearance-none focus:outline-none bg-transparent`;
const TextAreaLabel = tw.label`absolute top-0 -z-1 duration-300 origin-0 text-gray-500 font-medium`;
const TextAreaField = styled(InputField).attrs({ as: "textarea" })`
  ${tw`h-32`}
`;
const Error = tw.small`text-red-500`;

const TextArea = ({ name, label, error, ...rest }) => {
  return (
    <FormField>
      <TextAreaContainer>
        <TextAreaField
          name={name}
          id={name}
          placeholder=" "
          {...rest}
        ></TextAreaField>
        <TextAreaLabel htmlFor={name}>{label}</TextAreaLabel>
      </TextAreaContainer>
      {error && <Error>{error}</Error>}
    </FormField>
  );
};

export default TextArea;
