import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import {
  PrimaryButton as PrimaryButtonBase,
  SecondaryButton as SecondaryButtonBase,
} from "components/misc/Buttons.js";
import ProcessingIcon from "components/misc/ProcessingButtonIcon";
import Form from "./../common/form";
import Joi from "joi";
import _logger from "services/logService";
import _notification from "services/notificationService";
import { checkServiceability } from "services/serviceabilityService";
import "./../../styles/serviceability.css";

// for styled modal
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 text-base max-w-screen-sm absolute inset-x-0 top-0 flex flex-col justify-center rounded-md bg-white outline-none`}
  }
  .content {
    ${tw`w-full p-5`}
  }
`;
const ModalTopBar = tw.div`flex flex-row justify-between bg-primary-500 py-4 px-5 rounded-t-md`;
const ModalContent = tw.div`w-full p-5`;
const ModalFooter = tw.div`flex flex-row w-full p-5 pt-0`;
const Heading = tw.h1`text-xl text-white`;
const CloseModalButton = tw.button`text-white`;
// for styled modal

const FormTag = tw.form`text-sm flex flex-col w-full`;
const SubmitButton = tw(PrimaryButtonBase)`px-6 py-2`;
const BackButton = tw(SecondaryButtonBase)`px-6 py-2`;
const Error = tw.small`text-red-500 mb-1`;

class CheckServiceability extends Form {
  state = {
    data: {
      source: "",
      destination: "",
      captcha: "",
    },
    errors: {},
    serviceabilityData: {},
    loadingIndicator: false,
    pincodeErrorMsg: "",
  };

  schema = Joi.object({
    source: Joi.string()
      .min(6)
      .max(6)
      .empty("")
      // .required()
      .messages({
        "string.min": `"Source Pincode" length must be at least 6 digits long`,
        "string.max": `"Source Pincode" length must be less than or equal to 6 digits long`,
      })
      .label("Source Pincode"),
    destination: Joi.string()
      .min(6)
      .max(6)
      .empty("")
      // .required()
      .messages({
        "string.min": `"Destination Pincode" length must be at least 6 digits long`,
        "string.max": `"Destination Pincode" length must be less than or equal to 6 digits long`,
      })
      .label("Destination Pincode"),
    captcha: Joi.string().required().label("Captcha"),
  });

  validatePincodes = (e) => {
    e.preventDefault();

    const pincodeErrorMsg = "Please provide atleast one pincode.";
    const { source, destination } = this.state.data;

    if (!source && !destination) {
      this.setState({ pincodeErrorMsg });
    } else {
      this.setState({ pincodeErrorMsg: "" });
    }

    this.handleSubmit(e);
  };

  toggleLoader = (boolean) => {
    this.setState({ loadingIndicator: boolean });
  };

  handleBack = () => {
    this.resetForm();
    this.setState({ serviceabilityData: {} });
  };

  doSubmit = async () => {
    try {
      this.toggleLoader(true);

      const { data: body } = this.state;
      const {
        success,
        data: serviceabilityData,
        msg,
      } = await checkServiceability(body);

      if (success) {
        this.setState({ serviceabilityData });
      } else {
        _notification.show(msg, "warn");
      }
    } catch (error) {
      _logger.log(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  render() {
    const {
      loadingIndicator,
      data: formData,
      serviceabilityData,
      pincodeErrorMsg,
    } = this.state;
    const { modalIsOpen, toggleModal } = this.props;

    return (
      <StyledModal
        closeTimeoutMS={300}
        className="mainHeroModal"
        isOpen={modalIsOpen}
        onRequestClose={() => toggleModal()}
        shouldCloseOnOverlayClick={true}
      >
        <ModalTopBar>
          <Heading>Check Serviceability</Heading>
          <CloseModalButton onClick={() => toggleModal()}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
        </ModalTopBar>
        <ModalContent>
          {Object.keys(serviceabilityData).length ? (
            <div className="serviceability-details">
              <p>
                <span>Source Pincode: </span>
                <span>{formData.source || "Not Available"}</span>
              </p>

              <p>
                <span>Destination Pincode: </span>
                <span>{formData.destination || "Not Available"}</span>
              </p>

              <p>
                <span>Source Zone: </span>
                <span>{serviceabilityData.sourceZone || "Not Available"}</span>
              </p>

              <p>
                <span>Destination Zone: </span>
                <span>
                  {serviceabilityData.destinationZone || "Not Available"}
                </span>
              </p>

              <p>
                <span>Delivery Items: </span>
                <span>{serviceabilityData.deliverable}</span>
              </p>

              <p>
                <span>Couriers Available: </span>
                <span>
                  {serviceabilityData.servicableBy
                    .map(
                      (d) =>
                        d.courier.charAt(0).toUpperCase() +
                        d.courier.slice(1) +
                        " (" +
                        d.paymentType.join(", ") +
                        ")"
                    )
                    .map((courier, index) => (
                      <div key={index}>{courier}</div>
                    ))}
                </span>
              </p>
            </div>
          ) : (
            <FormTag>
              {pincodeErrorMsg && <Error>{pincodeErrorMsg}</Error>}
              {this.renderInput("source", "Source Pincode", "number", "6")}
              {this.renderInput(
                "destination",
                "Destination Pincode",
                "number",
                "6"
              )}
              <div tw="mt-2">{this.renderCaptcha("captcha")}</div>
            </FormTag>
          )}
        </ModalContent>
        <ModalFooter>
          {Object.keys(serviceabilityData).length ? (
            <BackButton
              type="button"
              style={{ width: "fit-content" }}
              onClick={this.handleBack}
            >
              Back
            </BackButton>
          ) : (
            <SubmitButton
              type="submit"
              disabled={loadingIndicator}
              style={{ width: "fit-content" }}
              onClick={this.validatePincodes}
            >
              {loadingIndicator && <ProcessingIcon />}
              Check
            </SubmitButton>
          )}
        </ModalFooter>
      </StyledModal>
    );
  }
}

export default CheckServiceability;
