import { toast } from "react-toastify";

function show(msg, type = "default") {
  const { INFO, SUCCESS, WARNING, ERROR, DEFAULT } = toast.TYPE;

  let toastType = "";

  switch (type) {
    case "info":
      toastType = INFO;
      break;

    case "success":
      toastType = SUCCESS;
      break;

    case "warn":
      toastType = WARNING;
      break;

    case "error":
      toastType = ERROR;
      break;

    default:
      toastType = DEFAULT;
      break;
  }

  toast(msg, { type: toastType, hideProgressBar: true });
}

export default {
  show,
};
