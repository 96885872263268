import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import styles from "../styles/termsService.module.css";
import privacyStyle from "../styles/privacy.module.css";
import { Link } from "react-router-dom";

const HeadingRow = tw.div`flex justify-center text-center font-bold`;
const SubHeading = tw.div`flex font-bold`;
const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`my-1 leading-relaxed`}
    a {
      ${tw`border-b-2 border-transparent text-primary-700 hocus:text-primary-300 hocus:border-primary-300 pb-1 transition duration-300`}
    }
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`font-bold`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default () => {
  return (
    <AnimationRevealPage disabled={true}>
      <Header />
      <Container>
        <ContentWithPaddingXl className={styles.service}>
          <HeadingRow>
            <h2 className={styles.mainHeadline}>
              Data Privacy and Security Policy
            </h2>
          </HeadingRow>
          <Text>
            <p className={styles.effectiveDate}>Effective date: Aug 24, 2022</p>

            <div class={privacyStyle.divspacing}>
              <p style={{ fontStyle: "italic" }}>
                This document is an electronic record in terms of Information
                Technology Act, 2000 and rules there under as applicable. This
                electronic record is generated by a computer/electronic system
                and does not require any physical or digital signatures.
              </p>
            </div>

            <div class={privacyStyle.divspacing}>
              <ol style={{ paddingLeft: "0px" }}>
                <li>
                  <strong>INTRODUCTION</strong>
                  <ol>
                    <li>
                      <strong>
                        Saged Retail Solutions Private Limited, (“We” / “Us” /
                        “Our” / “Company” / “Shippigo”)&nbsp;
                      </strong>
                      is a company duly incorporated under the provisions of the
                      Indian Companies Act, 2013, which provides various
                      logistics, supply chain & technology related services /
                      platforms under the brand name <strong>“Shippigo”</strong>
                      .
                    </li>
                    <li>
                      This privacy policy{" "}
                      <strong>(“Privacy Policy”)&nbsp;</strong>
                      applies to the use or access of the domain name/website{" "}
                      <Link to="/" style={{ textDecoration: "underline" }}>
                        www.shippigo.com
                      </Link>
                      , Shippigo’s mobile application and any of the
                      platform/tool developed by Shippigo from time to time.
                    </li>
                    <li>
                      The Privacy Policy constitutes an integral part of the
                      agreements / terms and conditions of use between Shippigo
                      and its customers / merchants, its users/end customers and
                      partners/affiliates.
                    </li>
                    <li>
                      Our Website, mobile application and platforms/tools (both
                      online and offline) developed by Shippigo are individually
                      referred to as the <strong>“Platform”</strong>.
                    </li>
                    <li>
                      The Platform(s) inter-alia facilitates more comfortable
                      form of e-commerce in relation to various technology
                      related services including but not limited to logistics
                      management services/solutions, checkout services,
                      early/faster COD services and other services that the
                      Company may provide to its Users from time to time whether
                      online or offline <strong>(“Services”)</strong>.
                    </li>
                    <li>
                      The Services would be made available to such persons who
                      have agreed to become user of the Platform (referred to as
                      <strong>“You”</strong> or <strong>“Your”</strong> or{" "}
                      <strong>“Yourself”</strong> or <strong>“User”</strong>,
                      which term shall also include persons who are accessing
                      the Platform merely as visitors or who undertakes any of
                      the Service) in accordance with the Platform’s terms of
                      use as may be formulated by Shippigo and uploaded on the
                      Platform from time to time{" "}
                      <strong>(“Terms of Use”)</strong>.
                    </li>
                    <li>
                      Shippigo considers user relationship and data security to
                      be an important component of Our products and services
                      offering through Our Website. We are committed to
                      maintaining the confidentiality, integrity and security of
                      any Personal Information (as defined below) of Our Users.
                      We are proud of Our privacy practices and the strength of
                      the security controls in relation to Our Platform and want
                      the User to know how We protect information of the User
                      and use it to provide Our Services to the User.
                    </li>
                    <li>
                      This Privacy Policy gives a broad outline as to how We
                      protect information provided by Users during the course of
                      access and usage of the Platform. We constantly
                      re-evaluate this Privacy Policy and adapt it to meet data
                      security standards and to deal with new challenges.
                    </li>
                    <li>
                      Please read this Privacy Policy carefully while accessing
                      or using the Platform or availing of any Services provided
                      by the Company. You agree to be bound by the terms
                      described herein. By indicating Your acceptance of this
                      Privacy Policy on our Platform and/or physically, You
                      hereby consent to the collection, processing, usage,
                      transfer of your Personal Information (including sensitive
                      personal information) in accordance with this Privacy
                      Policy.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>Purpose</strong>
                  <p>
                    This Privacy Policy has been designed and developed to help
                    You to understand the following:
                  </p>
                  <ol>
                    <li>
                      The type of Personal Information (including Sensitive
                      Personal Data or Information) and Non-Personal Information
                      that we collect from the Users;
                    </li>
                    <li>
                      The purpose of collection, means and modes of usage of
                      such Personal Information & Non-Personal Information by
                      the Company;
                    </li>
                    <li>
                      How and to whom the Company will disclose such
                      information;
                    </li>
                    <li>
                      How the Company will protect the Personal Information
                      including Sensitive Personal Data or Information and
                      Non-Personal Information that is collected from the Users;
                      and
                    </li>
                    <li>
                      How Users may access and/or modify their Personal
                      Information and Non-Personal Information.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>Definitions</strong>
                  <p>
                    In this Privacy Policy: (i) capitalized terms defined by
                    inclusion in quotations and / or parenthesis have the
                    meanings so ascribed; and (ii) the following terms shall
                    have the following meanings assigned to them herein below:
                  </p>
                  <ol>
                    <li>
                      <strong>“Applicable Law”</strong> includes all applicable
                      Indian statutes, enactments, acts of the state legislature
                      or parliament, laws, ordinances, rules, bye-laws,
                      regulations, notifications, guidelines, directions,
                      directives and orders of any governmental authority,
                      statutory authority, board, as may be applicable and in
                      each case, any implementing regulation or interpretation
                      issued thereunder including any successor applicable law;
                    </li>
                    <li>
                      <strong>“Agents” </strong>refers to such entities, or
                      persons engaged by, or associated/affiliated with the
                      Company in connection with the Company’s business
                      activities in any form;
                    </li>
                    <li>
                      <strong>“Our Website”</strong> shall mean and
                      include&nbsp;
                      <Link to="/" style={{ textDecoration: "underline" }}>
                        “https://shippigo.com”
                      </Link>
                      &nbsp;
                      <Link to="/" style={{ textDecoration: "underline" }}>
                        https://shippigo.com
                      </Link>
                      &nbsp;including its sub domains, mobile & desktop
                      application of the Company, including any successor
                      website/ applications, any website of related entity or
                      any other channel facilitated and permitted by the Company
                      including but not limited to any other digital medium
                      including phone, displays, emails, social media
                      interfaces, messaging interfaces, wallet, payment
                      intermediaries using Company’s interface;
                    </li>
                    <li>
                      <strong>“Non-Personal Information”</strong> shall have the
                      meaning as provided under Paragraph 3(f) of this Privacy
                      Policy;
                    </li>
                    <li>
                      <strong>“Person”</strong> shall mean any individual
                      (including personal representatives, executors or heirs of
                      a deceased individual) or legal entity, including but not
                      limited to, any partnership, joint venture, corporation,
                      trust, unincorporated organisation, limited liability
                      company, limited liability partnership or governmental
                      authority;
                    </li>
                    <li>
                      <strong>“Personal Information”</strong> shall mean any
                      information that relates to the User, which, either
                      directly or indirectly, is capable of identifying the
                      person concerned and as the details of the User as
                      specified under Paragraph 4a of this Privacy Policy;
                    </li>
                    <li>
                      <strong>“Sensitive Personal Information”</strong> shall
                      mean personal information of a User relating to password;
                      financial information such as bank account or credit card
                      or debit card or other payment instrument details;
                      physical, physiological and mental health condition;
                      sexual orientation; medical records and history; biometric
                      information; any detail relating to the above as provided
                      to or received by the Company for processing or storage.
                      However, any data/information that is freely available or
                      accessible in public domain or furnished under the Right
                      to Information Act, 2005 or any other law shall not
                      qualify as Sensitive Personal Data or Information or
                      Personal Information.
                    </li>
                    <li>
                      <strong>“User” / “You” / “Your” </strong>shall mean any
                      natural or legal person who has access to and is using the
                      Platform; and
                    </li>
                    <li>
                      <strong>“User Information”</strong> shall have the meaning
                      as provided under Paragraph 4c & 4d of this Privacy
                      Policy.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>Collection, Storage and Use of Information</strong>
                  <ol>
                    <li>
                      <em style={{ textDecoration: "underline" }}>
                        Personal Information:
                      </em>
                      <ol>
                        <li>
                          We may collect Your Personal Information when You
                          voluntarily and successfully submit information
                          against relevant fields on the Platform. Personal
                          Information is the data that, alone or in combination
                          with any other information, can be used to uniquely
                          identify You <strong>(“Personal Information”)</strong>
                          . Personal Information may include, without
                          limitation, the following:
                          <ol>
                            <li>Name</li>
                            <li>User ID(s)</li>
                            <li>Password chosen by the User</li>
                            <li>Email address (es)</li>
                            <li>
                              Mailing address (es) (including country and Zip /
                              Posatal code)
                            </li>
                            <li>
                              Geographical location through the IP address of
                              the Users
                            </li>
                            <li>
                              Financial account information like bank account
                              details, GST certificate, PAN Card, MSME, TAN,
                              etc. and transactional information in relation to
                              transactions where the Company is involved
                            </li>
                            <li>Banking and other financial data</li>
                            <li>
                              Government identification numbers, e.g., driver’s
                              license number
                            </li>
                            <li>Date of birth</li>
                            <li>Age</li>
                            <li>Gender</li>
                            <li>Health and disability data</li>
                            <li>Family information</li>
                            <li>Financial and asset information</li>
                            <li>
                              Such other information that may be requested by
                              third party service providers associated with the
                              Platform for accessing services provided by them.
                            </li>
                            <li>
                              Any of the aforesaid information pertaining to the
                              customer / buyer of the User.
                            </li>
                            <li>
                              All other personally identifiable information /
                              details as the User may share from time to time
                              (including personally identifiable information /
                              details of the customer / buyer of the User).
                            </li>
                            <li>Phone numbers</li>
                          </ol>
                        </li>
                        <li>
                          In particular, if You register/log-in on the Platform
                          through any social media platform (for example,
                          Google, Facebook, Microsoft, Amazon, Apple, Shopify,
                          etc.), then We may collect the following information:
                          <ol>
                            <li>
                              The email/phone number used on the social media
                              platform to register/ log-in;
                            </li>
                            <li>
                              The profile image (if any) connected to the
                              relevant social media account used.
                            </li>
                            <li>
                              The display name connected to the relevant social
                              media account used; and
                            </li>
                          </ol>
                        </li>
                        <li>
                          Please note that the information We obtain from such
                          social media accounts also depends on the privacy
                          policies of the social media platforms and Your
                          respective settings therein. Please check the
                          respective policies to understand the privacy
                          practices of those social media platforms.
                        </li>
                        <li>
                          In case of Agents, We may request such additional
                          Personal Information as may be specified in or as part
                          of the respective agreement between the Company and
                          the Agent.
                        </li>
                        <li>
                          <span style={{ textDecoration: "underline" }}>
                            Purpose of Collection of Personal Information:
                          </span>
                          &nbsp; Your Personal Information is collected when You
                          voluntarily submit such information. It is used,
                          handled and stored by Us for the purposes of Your
                          identification/ verification and/or creation of Your
                          account on the Platform and/or for accessing services
                          provided through the Platform. Accordingly, such
                          information shall be retained by Us till the time You
                          have access and use the Platform or You communicate to
                          Us Your decision to withdraw Your permission to store
                          and retain such information.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <span style={{ textDecoration: "underline" }}>
                        Non-Personal Information:
                      </span>
                      <ol>
                        <li>
                          When You visit Our Website or use the Platform, We may
                          collect certain non-personal information for the
                          purpose of enhancing Your use of Our Website and/or
                          the Platform{" "}
                          <strong>(“Non-Personal Information”)</strong>. This
                          Non-Personal Information is in the nature of technical
                          and navigational information generated each time You
                          visit the Our Website or use the Platform, which are
                          saved in Our server logs. The Non-Personal Information
                          may include, without limitation, the following details
                          in respect of:
                          <ol>
                            <li>
                              User’s browsing history that includes the Uniform
                              Resource Locator (URL) of the site that the User
                              visited prior to visiting the platform as well as
                              the the server / Internet Protocol (Internet
                              Protocol) address of each User’s computer (or the
                              proxy server a User used to access the World Wide
                              Web) from where Our Website is being accessed;
                            </li>
                            <li>
                              The browser and operating system used to browse
                              Our Website;
                            </li>
                            <li>
                              name of the Internet Service Provider (ISP){" "}
                            </li>
                            <li>links clicked, scrolled and pages visited;</li>
                            <li>
                              details of Your last visit to Our Website or use
                              of the Platform, including time, date and the
                              duration of Your session on Our Website, etc.;
                              and/or
                            </li>
                            <li>
                              cookies as per Paragraph 4 of this Privacy Policy.
                            </li>
                          </ol>
                        </li>
                        <li>
                          <span style={{ textDecoration: "underline" }}>
                            Purpose of Collection of Non-Personal Information:
                          </span>
                          &nbsp; Non-Personal Information is in the form of
                          encrypted statistics, which helps Us in improving the
                          efficiency of the Platform by giving Us information
                          relating to Your use of the Platform.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Personal Information, Non-Personal Information and
                      Sensitive Personal Information are together referred to as
                      “User Information” in this Privacy Policy.
                    </li>
                    <li>
                      In order to avail the Services, the Users may also be
                      required to upload/share certain documents (for instance,
                      Aadhaar, PAN Card, GST certificate, TAN, MSME, Utility
                      Bill, Shops & Establishment, Certificate of Incorporation,
                      Memorandum of Association, Articles of Association,
                      Partnership Deed, etc.), on the Platform and/or e-mail the
                      same to the Company. Accordingly, the term{" "}
                      <strong>“User Information”</strong> shall also include any
                      such documents uploaded or otherwise provided by the
                      Users. We may also keep records of telephone calls
                      received and/or email received and made for making
                      inquiries, orders, or other purposes necessary for the
                      administration of Services.
                    </li>
                    <li>
                      By using the Platform, You authorize Us to collect, store,
                      process, handle and use such User Information, in
                      accordance with this Privacy Policy and any other terms
                      and conditions of use of the Platform (as amended from
                      time to time) including but not limited to the Terms of
                      Use.
                    </li>
                    <li>
                      We reserve the right to retain such User Information that
                      forms part of anonymized and aggregated data derived from
                      User Information which may be used for improvement of the
                      Platform, to produce analytical reports, marketing,
                      advertising or such other activities as We may deem fit.
                    </li>
                    <li>
                      We do not knowingly collect any Personal Information and
                      any Sensitive Personal Information from Persons below the
                      age of 18.
                    </li>
                    <li>
                      We may in future include other optional requests for
                      information from the User including through User surveys
                      in order to help Us customize the Platform to deliver
                      personalized information to the User and for other
                      purposes as mentioned herein. Such information may also be
                      collected in the course of surveys / contests conducted by
                      Us. Any such additional Personal Information will also be
                      processed in accordance with this Privacy Policy.
                    </li>
                    <li>
                      <strong>
                        Purposes for which the Company may use the Information:
                      </strong>
                      <ol>
                        <li>
                          We will retain User Information only to the extent it
                          is necessary to provide one or more Services.
                        </li>
                        <li>
                          By providing your information, You consent to the
                          collection, sharing, disclosure and usage of the
                          information in accordance with this Privacy Policy.
                        </li>
                        <li>
                          The information, which we collect may be utilized for
                          various business and/or regulatory purposes including
                          but not limited for the following purposes:
                          <ol>
                            <li>Registration of the User on the Platform;</li>
                            <li>
                              Processing the User’s orders / requests and
                              provision of various Services;
                            </li>
                            <li>
                              To provide shipment data to authorities of country
                              in transit and / or destination for customs and
                              tax clearance or for security screening, as
                              required by the laws of such country.
                            </li>
                            <li>
                              Sending timely / periodical updates to the User
                              and its customers;
                            </li>
                            <li>
                              Completing transactions with Users effectively and
                              billing for the products / Services provided;
                            </li>
                            <li>
                              Technical administration and customization of
                              Platform;
                            </li>
                            <li>
                              Ensuring that the Platform content is presented to
                              the Users in an effective manner;
                            </li>
                            <li>
                              Delivery of personalized information and targeted
                              as well as non-targeted advertisements by the
                              Company to the User;
                            </li>
                            <li>
                              Improvement of Services, features and
                              functionality of the Platform;
                            </li>
                            <li>
                              Research and development and for User
                              administration (including conducting user
                              surveys);
                            </li>
                            <li>
                              For purposes of research, analysis, business
                              intelligence, reporting and improvement /
                              development / advancement of the Company’s
                              business, Platform and / or the Services;
                            </li>
                            <li>
                              Dealing with requests, enquiries, complaints or
                              disputes and other customer care related
                              activities including those arising out of the
                              Users’ request of the Services and all other
                              general administrative and business purposes;
                            </li>
                            <li>
                              Communicate any changes in our Services or this
                              Privacy Policy or the Terms of Use to the Users;
                            </li>
                            <li>
                              Verification of identity of Users and to perform
                              checks to prevent frauds;
                            </li>
                            <li>
                              Investigating, enforcing, resolving disputes and
                              applying our Terms of Use and Privacy Policy,
                              either ourselves or through third party service
                              providers;
                            </li>
                            <li>
                              To comply with applicable legal requirements and
                              our various policies/terms; and
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <p>
                      Any other purpose that may be necessary to provide the
                      Services that You have opted for.
                    </p>
                  </ol>
                </li>
                <li>
                  <strong>Cookies</strong>
                  <ol>
                    <li>
                      Like most other sites, We use data collection devices
                      known as “cookies” to collect and store information of
                      Users visiting Our Website. A cookie is an alpha-numeric
                      identifier, which is small amount of data file that is
                      sent to a User’s browser from a web server and is
                      eventually stored on a User’s computer hard drive. Cookies
                      are a reliable mechanism to remember the activities of the
                      User on Our Website and help in improving Your experience
                      on Our Website.
                    </li>
                    <li>
                      We may set and access cookies on Your computer to track
                      and store preferential information about You. We may
                      gather information about You through cookie technology.
                      This anonymous information is maintained distinctly and is
                      not linked to the Personal Information You submit to Us.
                      The option of accepting cookies is up to You, however
                      certain features of Our Website including Content and the
                      forms may not be accessible without accepting cookies. If
                      You choose to eliminate cookies, the full functionality of
                      Our Website may be impaired for You.
                    </li>
                    <li>
                      Most cookies are session cookies that are automatically
                      deleted from Your device’s hard drive when You close Our
                      Website. Additionally, You may encounter cookies or other
                      similar devices on certain pages of Our Website that are
                      placed by third parties. We do not control the use of
                      cookies by third parties and shall not be liable for any
                      reason whatsoever for these third-party cookies. We use
                      persistent cookies for retaining information about
                      visitors who repeatedly access one of our internet pages.
                    </li>
                    <li>
                      We may use third party service providers to help Us
                      analyse certain online activities. For example, these
                      service providers may help us measure the performance of
                      Our online campaigns or analyse visitor activity on Our
                      Website. We may permit these service providers to use
                      cookies and other technologies to perform these services
                      for the Company. We do not share any Personal Information
                      with these third-party service providers, and these
                      service providers do not collect such information on Our
                      behalf.
                    </li>
                    <li>
                      These cookies may be used for:
                      <ol>
                        <li>
                          offering optimal user guidance as well as to
                          “recognize” you and thus be able to present (as much
                          as possible) diversified internet pages and new
                          contents during repeated use,
                        </li>
                        <li>technical administration of the platform,</li>
                        <li>research and development, and</li>
                        <li>for User administration</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>User Rights</strong>
                  <ol>
                    <li>
                      All the information provided to the Company by a User,
                      including Sensitive Personal Data or Information, is
                      voluntary.
                    </li>
                    <li>
                      <span style={{ textDecoration: "underline" }}>
                        Review:
                      </span>
                      &nbsp; To ensure the accuracy and adequacy of the Personal
                      Information provided by You, You shall at all times have
                      the option of reviewing the same by requesting Us in the
                      manner as provided under{" "}
                      <span style={{ textDecoration: "underline" }}>
                        Paragraph 10
                      </span>
                      &nbsp; of this Privacy Policy.
                    </li>
                    <li>
                      <span style={{ textDecoration: "underline" }}>
                        Withdrawal of Consent:
                      </span>
                      <ol>
                        <li>
                          You shall at all times have the option of withdrawing
                          Your consent for the collection, storage and retention
                          of Your Personal Information in accordance with the
                          terms of this Privacy Policy and the Terms of Use.
                        </li>
                        <li>
                          In case of Agents, their right to withdraw consent
                          shall also be subject to the respective agreement.
                        </li>
                        <li>
                          Please note that withdrawal of consent will not be
                          retrospective.
                        </li>
                      </ol>
                    </li>
                    <li>
                      In case the User does not provide his / her information or
                      consent for usage of Personal Information or subsequently
                      withdraws consent for usage of the Personal Information so
                      collected, the Company reserves the right to restrict /
                      revoke access and / or usage of the Platform or
                      discontinue, in part or full, the Services / associated
                      features and benefits for which the said information was
                      sought. Please further refer to{" "}
                      <span style={{ textDecoration: "underline" }}>
                        Paragraph 11
                      </span>
                      &nbsp; of this Privacy Policy for the manner of
                      communication with Us.
                    </li>
                    <li>
                      <strong>Modify:</strong>
                      <ol>
                        <li>
                          Users can access, modify and correct the Personal
                          Information provided by them which has been
                          voluntarily given by the User and collected by the
                          Company in accordance with this Privacy Policy and
                          Terms of Use.
                        </li>
                        <li>
                          However, if the User updates his / her information,
                          the Company may keep a copy of the information which
                          User originally provided to the Company in its
                          archives for User documented herein.
                        </li>
                        <li>
                          In case the User seeks to update or correct, his / her
                          Personal Information, the User may exercise these
                          rights by emailing the Company at{" "}
                          <a
                            href="mailto:support@shippigo.com"
                            style={{ textDecoration: "underline" }}
                          >
                            support@shippigo.com
                          </a>
                          &nbsp;and communicate the change(s) for updating the
                          Company’s records.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <span style={{ textDecoration: "underline" }}>
                        Account Deletion:
                      </span>
                      &nbsp;You may choose to delete Your account at any time
                      You like by requesting Us in the manner as provided
                      under&nbsp;
                      <span style={{ textDecoration: "underline" }}>
                        Paragraph 11
                      </span>
                      . Please note that it may take up to 180 (One Hundred &
                      Eighty) days to delete all of Your information, like the
                      data stored in our backup systems. However, We may also
                      preserve such information/data as required for legal
                      reasons and as permitted under Applicable Law or to
                      prevent harm.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Disclosure and Transfer of User’s Information</strong>
                  <ol>
                    <li>
                      We do not share Your User Information with any third
                      parties for commercial use or revenue generation. However,
                      subject to Applicable Law, We may share User Information
                      with third party service providers who may be located in
                      India or outside India:
                      <ol>
                        <li>
                          for enabling services provided by such third party
                          service providers through the Platform;
                        </li>
                        <li>
                          for sending SMS / Email communications to You in
                          relation to the Platform and Our products and
                          services; and
                        </li>
                        <li>
                          to improve and personalize Our products and services.
                          This may involve, for example, activities such as
                          troubleshooting and protection against errors; data
                          analysis and testing; and developing new features.
                        </li>
                      </ol>
                    </li>
                    <li>
                      We ensure that such third-party service providers maintain
                      strict confidentiality (ensuring the same level of
                      confidentiality as maintained by Us) in respect of User
                      Information. Our third-party service providers are
                      required to comply fully with this Privacy Policy.
                    </li>
                    <li>
                      Further, We reserve the right to utilize, share and/or
                      disclose User Information if:
                      <ol>
                        <li>
                          required to do so to comply with orders of
                          governmental authorities that have jurisdiction over
                          it or as otherwise required by Applicable Law after
                          providing You a written intimation prior to such
                          disclosure; and/or
                        </li>
                        <li>
                          We determine, in Our sole discretion that disclosure
                          of User Information is necessary to identify, contact,
                          or bring legal action against You.
                        </li>
                      </ol>
                    </li>
                    <li>
                      We may need to disclose / transfer User’s Information to
                      Governmental and judicial institutions/authorities, to the
                      extent required:
                      <ol>
                        <li>
                          Under the laws, rules, and regulations and/or under
                          orders of any relevant judicial or quasi-judicial
                          authority;
                        </li>
                        <li>
                          To protect and defend the rights or property of the
                          Company;
                        </li>
                        <li>To fight fraud and credit risk;</li>
                        <li>
                          To enforce the Company's Terms of Use (to which this
                          Privacy Policy is also a part); or
                        </li>
                        <li>
                          When the Company, in its sole discretion, deems it
                          necessary in order to protect its rights or the rights
                          of others.
                        </li>
                      </ol>
                    </li>
                    <li>
                      The Company may also make all User Information accessible
                      to its employees and data processors / third party vendors
                      only on a need-to-know basis and for the purposes set out
                      in this Privacy Policy.
                    </li>
                    <li>
                      The Company takes adequate steps to ensure that all the
                      employees and data processors / third party vendors, who
                      have access to, and are associated with the processing of
                      User Information, respect its confidentiality and that
                      such data processors / third party vendors adopt at least
                      such reasonable level of security practices and procedures
                      as required under applicable law.
                    </li>
                    <li>
                      However, the Company does not disclose information,
                      individually labelled or aggregated, obtained through
                      Marketplace Application programming interface on behalf of
                      a User to other Users or any third parties, unless
                      required by law.
                    </li>
                    <li>
                      Non-personally identifiable information may be disclosed
                      to third party ad servers, ad agencies, technology vendors
                      and research firms to serve non-targeted advertisements to
                      the Users.
                    </li>
                    <li>
                      The Company may also share its aggregate findings (not
                      specific information) in a non-personally identifiable
                      form based on information relating to the User’s internet
                      use (to the extent set out in this Privacy Policy) to
                      prospective, investors, strategic partners, sponsors and
                      others in order to help growth of Company's business.
                    </li>
                    <li>
                      We may also disclose or transfer the User Information, to
                      another third party as part of reorganization or a sale of
                      the assets or business of Company.
                    </li>
                    <li>
                      Any third party to which the Company transfers or sells
                      its assets will have the right to continue to use the
                      Personal Information and/or other information that a User
                      provides to Us in a manner consistent with this Privacy
                      Policy.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>Links to Third-Party:</strong>
                  <ol>
                    <li>
                      The links to third-party advertisements, third party
                      websites or any third-party electronic communication
                      services (referred to as{" "}
                      <strong>“Third Party Links”)</strong> may be provided on
                      the platform which are operated by third parties and are
                      not controlled by, or affiliated to, or associated with
                      the Company, unless expressly specified on the Platform.
                    </li>
                    <li>
                      If You access any such Third-Party Links, we request You
                      to review the concerned website’s privacy policy.
                    </li>
                    <li>
                      We shall not be responsible for the policies or practices
                      of such third parties.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>Indemnification</strong>
                  <p>
                    You agree to indemnify, defend and hold harmless Us and Our
                    parent, subsidiaries, affiliates, agents, officers,
                    directors, agents, contractors, licensors, service
                    providers, subcontractors, suppliers, interns and employees,
                    harmless from any claim or demand, including reasonable
                    attorneys’ fees, made by any third-party due to or arising
                    out of Your breach of this Privacy Policy or the documents
                    they incorporate by reference (including the respective
                    agreement, where applicable for use of the Platform), or
                    Your violation of any law or the rights of a third party.
                  </p>
                </li>

                <li>
                  <strong>Security Precautions</strong>
                  <ol>
                    <li>
                      For the purpose of providing the Services and for other
                      purposes identified in this Privacy Policy, we are
                      required to collect and host certain data and information
                      of the Users.
                    </li>
                    <li>
                      We are committed to protecting Your Personal Information,
                      and to that end, the Company adopts reasonable security
                      practices and procedures to implement technical,
                      operational, managerial and physical security control
                      measures in order to protect the Personal Information in
                      its possession from loss, unlawful interception, misuse
                      and unauthorized access, disclosure, alteration and
                      destruction.
                    </li>
                    <li>
                      We use reasonable secure and technologically appropriate
                      measures, in compliance with the Information Technology
                      Act, 2000 and the rules related thereto to protect You
                      against loss or misuse of Your User Information including
                      internal reviews of data collection, storage and
                      processing practices and other reasonable security
                      measures which are equivalent to security measures that We
                      use to protect Our own confidential information.
                    </li>
                    <li>
                      While we try our best to provide security that is
                      commensurate with the industry standards, due to the
                      inherent vulnerabilities of the internet, no internet
                      website or online platform is completely free of security
                      risks, we cannot ensure or warrant complete security of
                      all information that is being transmitted to Us and We do
                      not make any representation in respect of the same.
                    </li>
                    <li>
                      The Company takes adequate steps to ensure that third
                      parties to whom the Personal Information may be
                      transferred adopt at least such reasonable level of
                      security practices and procedures as required under
                      applicable law to ensure security of Personal Information.
                    </li>
                    <li>
                      You hereby acknowledge that the Company is not responsible
                      for any information sent via the internet that has been
                      intercepted beyond Our control after having adopted
                      reasonable security practices and procedures, and You
                      hereby release Us from any and all claims arising out of
                      or related to the use of intercepted information in any
                      unauthorized manner.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>
                    For how long the Company keeps Personal Information:
                  </strong>
                  <ol>
                    <li>
                      Any personal information collected or processed in
                      accordance with this Privacy Policy will be collected by
                      Us, and retained on our behalf either by Us or with Amazon
                      Web Services and / or Microsoft Azure and / or Google
                      Cloud Platform and / or IBM Cloud and/or any other Cloud
                      Services Subscribed by the Company.
                    </li>
                    <li>
                      Company will retain your Personal Information for as long
                      as needed to fulfil the purpose for which it was collected
                      and for any longer period thereafter in order to comply
                      with applicable law.
                    </li>
                    <li>
                      In case a User has withdrawn or cancelled their
                      registration from the Platform, we are obliged under law
                      to retain their Personal Information for a period of one
                      hundred and eighty days after such cancellation.
                    </li>
                    <li>
                      We may retain non-personally identifiable data
                      indefinitely or to the extent allowed by applicable law.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>Change in Privacy Policy</strong>
                  <p>
                    We may update this Privacy Policy from time to time and
                    shall notify you regarding the Policy as well as any such
                    changes periodically and at least once a year. These changes
                    will become effective immediately on posting. We shall not
                    be liable for any failure or negligence on Your part to
                    review the updated Privacy Policy before accessing the
                    Platform. Your continued access to the Platform, following
                    changes to this Privacy Policy, will constitute Your
                    acceptance of those changes.
                  </p>
                </li>

                <li>
                  <strong>Contacting Us</strong>
                  <p>
                    If You have any queries regarding: (i) this Privacy Policy;
                    (ii) information and/or Services available on the Platform,
                    or (iii) Your dealings with Us or believe that We have not
                    adhered to it, You may contact Us at:
                    <div style={{ marginTop: "0.7rem" }}>
                      Md Kalimulla, FP & Analysis - Head <br />
                      Saged Retail Solutions Pvt Ltd <br />
                      303, 3rd Floor, Neelkanth Corporate Park, <br />
                      Vidhyavihar West, Mumbai 400086, <br />
                      <a href="mailto:support@shippigo.com">
                        support@shippigo.com
                      </a>
                    </div>
                  </p>
                </li>

                <li>
                  <strong>Email Opt-Out</strong>
                  <p>
                    You can opt out of receiving Our marketing and update
                    emails. To stop receiving Our promotional emails, please
                    email at{" "}
                    <a href="mailto:support@shippigo.com">
                      support@shippigo.com
                    </a>
                    . It may take about 90 (ninety) days to process Your
                    request. Please note that Your opting out of getting
                    marketing emails/messages would still enable You to receive
                    transactional messages through email and SMS in relation to
                    Your usage/access of the Platform.
                  </p>
                </li>
              </ol>
            </div>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
