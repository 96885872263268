import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { Link } from "react-router-dom";

import styles from "../styles/termsService.module.css";

const HeadingRow = tw.div`flex justify-center text-center font-bold`;
const SubHeading = tw.div`flex font-bold mt-4`;
const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`mt-1 leading-relaxed`}
    a {
      ${tw`border-b-2 border-transparent text-primary-700 hocus:text-primary-300 hocus:border-primary-300 pb-1 transition duration-300`}
    }
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`font-bold`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default () => {
  return (
    <AnimationRevealPage disabled={true}>
      <Header />
      <Container>
        <ContentWithPaddingXl className={styles.service}>
          <HeadingRow>
            <h2 className={styles.mainHeadline}>
              CANCELLATION AND REFUND POLICY
            </h2>
          </HeadingRow>
          <Text>
            <div>
              <p className={styles.effectiveDate}>
                Effective date: Aug 24, 2022
              </p>
              <SubHeading>
                <h2 className="subHeading">
                  SAGED RETAIL SOLUTIONS PRIVATE LIMITED
                </h2>
              </SubHeading>

              <p>
                <em>
                  This document is an electronic record in terms of Information
                  Technology Act, 2000 and rules there under as applicable. This
                  electronic record is generated by a computer/electronic system
                  and does not require any physical or digital signatures.
                </em>
              </p>
              <p style={{ margin: "5px 0 10px 0" }}>
                Capitalized terms not defined in this Policy shall have the
                meaning ascribed to them in the{" "}
                <Link to="/terms-of-use">Terms of Use</Link> of the Company.
              </p>

              <ol style={{ paddingLeft: "0px" }}>
                <li>
                  <strong>INTRODUCTION</strong>
                  <ol>
                    <li>
                      <strong>
                        Saged Retail Solutions Pvt Ltd, (“Company”)
                      </strong>{" "}
                      is a company duly incorporated under the provisions of the
                      Indian Companies Act, 2013, which provides various
                      logistics, supply chain & technology related services /
                      platforms under the brand name <strong>“Shippigo”</strong>
                      .
                    </li>
                    <li>
                      This cancellation and refund policy (hereinafter referred
                      to as the “Policy”) states the terms and conditions with
                      respect to the cancellation and refund by/to the User in
                      relation to the Services.
                    </li>
                    <li>
                      This Policy shall form an integral part of the{" "}
                      <Link
                        to="/terms-of-use"
                        style={{ textDecoration: "underline" }}
                      >
                        Terms of Use
                      </Link>
                      &nbsp; of the Company and shall be read in unison with
                      the&nbsp;
                      <Link
                        to="/terms-of-use"
                        style={{ textDecoration: "underline" }}
                      >
                        Terms of Use
                      </Link>
                      .
                    </li>
                    <li>
                      This Policy gives a broad outline wherein the Company
                      provides the option for cancellation and the right to
                      refund to the User in relation to the Services availed.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>CANCELLATION AND REFUND OF THE SHIPMENT</strong>
                  <p>
                    The following events have been provided below wherein the
                    User has the right to cancel and/or avail refund in respect
                    of the Services:
                  </p>
                  <ol>
                    <li>
                      <div>
                        User agrees and acknowledges that unless stated
                        otherwise, User is entitled to cancel the Services at
                        any time from the User Panel before the Shipment is
                        picked up by the executive of the courier and cargo
                        delivery services company. Upon such cancellation, the
                        refund shall be credited by the Company in the form of a
                        credit note (as per the terms of liability clause in
                        the&nbsp;
                        <Link
                          to="/terms-of-use"
                          style={{ textDecoration: "underline" }}
                        >
                          Terms of Use
                        </Link>
                        ) to the Users Shippigo Credit Account, within 10 (ten)
                        working days.
                      </div>

                      <p>
                        Provided however that the User shall not be entitled to
                        cancel the Services, in the event the courier or cargo
                        delivery services company’s executive has picked the
                        Shipment from the pickup location of the User.
                      </p>
                    </li>
                    <li>
                      User agrees and acknowledges that after the Shipment has
                      been handed over by the User to the courier or cargo
                      delivery services company’s executive, the User cannot
                      cancel the Services and is not entitled to any refund by
                      the Company.
                    </li>
                    <li>
                      In the event the Shipment received by the User, has
                      arrived in a damaged condition during the transit of such
                      Shipment, the Company shall conduct an investigation and
                      if the damage is attributable to the actions of the
                      courier or cargo delivery services company, the Company
                      shall initiate the refund to the User in the form of a
                      credit note (as per the terms of liability clause in
                      the&nbsp;
                      <Link
                        to="/terms-of-use"
                        style={{ textDecoration: "underline" }}
                      >
                        Terms of Use
                      </Link>
                      ) to the User’s Shippigo Credit Account, within 30
                      (thirty) working days.
                    </li>
                    <li>
                      In the event the Shipment received by Consignee (User’s
                      recipient) has been delivered in a damaged condition, the
                      Company shall investigate if the Consignee has mentioned a
                      remark of damage to outer layer of packaging on the
                      courier or cargo delivery services company’s Proof of
                      Delivery (“POD”) sheet. If the Consignee has not mentioned
                      any remark, the Shipment delivery shall be termed as
                      delivered in clean POD without any liability / refund
                      to/by Shippigo / the courier or cargo delivery services
                      company.
                    </li>
                    <li>
                      User agrees and acknowledges that there shall be no refund
                      provided towards damage to Shipments that are liquid,
                      fragile and perishable items, irrespective of knowledge to
                      Shippigo and / or the courier or cargo delivery services
                      company.
                    </li>
                    <li>
                      In the event the courier or cargo delivery services
                      company has declared a Shipment as lost in transit, the
                      Company shall initiate a refund to the User in the form of
                      a credit note (as per the terms of liability clause
                      in&nbsp;
                      <Link
                        to="/terms-of-use"
                        style={{ textDecoration: "underline" }}
                      >
                        Terms of Use
                      </Link>
                      ) to the User’s Shippigo Credit Account, within 30
                      (thirty) working days.
                    </li>
                    <li>
                      In the event the Shipment received by Consignee (User’s
                      recipient) has been delivered in a condition where few
                      products within the Shipment package is missing, the
                      Company shall investigate if the Consignee has mentioned a
                      remark of suspected tampering of packaging on the courier
                      or cargo delivery services company’s POD sheet. If the
                      Consignee has not mentioned any remark, the Shipment
                      delivery shall be termed as delivered in clean POD without
                      any liability / refund to/by Shippigo / the courier or
                      cargo delivery services company.
                    </li>
                    <li>
                      There shall be no refund towards chargeable weight of
                      Shipment levied by courier or cargo delivery services
                      company that is higher than chargeable weight of Shipment
                      shared by the User at the time of Shipment manifestation.
                    </li>
                    <li>
                      In case of claims pertaining to lost / approved short
                      claim, if the claim amount is already credited to the User
                      on account of incorrect status of the Shipment, the same
                      shall be refunded by the User (by way of deduction from
                      User’s Shippigo Credit Account, future COD Amounts or
                      otherwise) in case the concerned Shipment has been traced
                      and delivered/RTO delivered to the User.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>MISCELLANEOUS</strong>
                  <ol>
                    <li>
                      In the event the User is a company/enterprise
                      /firm/partnership/body corporate, the User is required to
                      maintain a Shippigo Credit Account, which shall maintain
                      such amount as agreed between the User and the Company.
                    </li>
                    <li>
                      In the event the User is eligible for a refund as per this
                      Policy and the payment for Services has been made through
                      a payment gateway by the User, then the realization of
                      refund shall be as per the payment gateway’s refund policy
                      and the Company shall not be liable in the event of any
                      delay.
                    </li>
                    <li>
                      Wherever Shippigo issues a credit note to the User in case
                      of any adjustment in the invoice value, the User shall
                      adjust its input tax credit within the month in which the
                      discrepancy is communicated by Shippigo to the User. If
                      the same is not rectified by the User which results into
                      additional levy / liability for Shippigo, the tax and
                      interest amount will be recovered from the User.
                    </li>
                    <li>
                      In the event the User disputes any payment, where the
                      transaction has been made through a payment gateway with
                      respect to any Services, the refund, if any approved,
                      shall be as per the agreement between the Company and the
                      payment gateway.
                    </li>
                    <li>
                      The User can request Shippigo to refund the credit balance
                      of their Shippigo Credit Account. Any such refund request
                      shall be subject to refund being made to the original
                      source/mode of payment, standard time taken to process
                      such refund and mandatorily providing of necessary KYC
                      documents by the User to process the refund. Further,
                      Shippigo reserves a right to: (i) deny any request to
                      refund the credit balance to a source being different from
                      the original source/mode of payment; (ii) levy a surcharge
                      (as per its sole discretion) to refund the credit balance
                      to a source being different from the original source/mode
                      of payment; and/or (iii) levy appropriate damages/charges
                      (as per its sole discretion) in case Shippigo is of the
                      opinion that the Shippigo Credit Account is being/has been
                      used by the User for any unscrupulous/illegal activities
                      or for purposes other than for payment to Shippigo.
                    </li>
                    <li>
                      Shippigo Services with respect to a User, shall stay
                      active till 10 (ten) days from the date of the last unpaid
                      invoice. The User shall be charged for the period for
                      which the invoice has been raised. The User must request
                      termination before the next billing cycle starts and / or
                      the next invoice, is generated, or the cancellation
                      request does not count. There is no pro-rated refund of
                      remaining service period in the current billing cycle.
                    </li>
                    <li>
                      If the User has any queries regarding: (i) this Policy;
                      and/or (ii) the cancellation and refund mechanism, User
                      may contact the Company at support@shippigo.com.
                    </li>
                    <li>
                      The User may cancel their User Account at any time by
                      emailing the Company at:{" "}
                      <a
                        href="mailto:support@shippigo.com"
                        style={{ textDecoration: "underline" }}
                      >
                        support@shippigo.com
                      </a>
                      .
                    </li>
                    <li>
                      Once the User Account is cancelled, all content of the
                      User will be immediately deleted from the Service. Since
                      deletion of all data is final, the User is required to be
                      rest assured and satisfied that the User does in fact want
                      to cancel the User Account before doing so.
                    </li>
                    <li>
                      If the User cancels the Service in the middle of the
                      month, the User will receive one final invoice via email.
                      Once the invoice has been paid, the User will not be
                      charged again.
                    </li>
                    <li>
                      Shippigo reserves the right to modify or terminate
                      Shippigo Services for any reason, without notice at any
                      time.
                    </li>
                    <li>
                      No refunds are offered towards: (i) cancellation of
                      Shippigo’s Service plan in the middle of a month, (ii)
                      set-up Cost, (iii) annual or semi-annual or quarterly or
                      monthly subscription cost.
                    </li>
                    <li>
                      Company may update this Policy from time to time and shall
                      notify the User regarding the Policy as well as any such
                      changes periodically. These changes will become effective
                      immediately on posting. Company shall not be liable for
                      any failure or negligence on User’s part to review the
                      updated Policy before availing the Services. User’s
                      continued access to the Website/Mobile App, following
                      changes to this Policy, will constitute User’s acceptance
                      of those changes.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
