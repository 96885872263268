import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomizeIconImage from "images/customize-icon.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

// font awesome icons
import ChartAreaIconImage from "images/font-awesome/chart-area-solid.svg";
import DesktopIconImage from "images/font-awesome/desktop-solid.svg";
import MousePointerIconImage from "images/font-awesome/mouse-pointer-solid.svg";
import SlidersIconImage from "images/font-awesome/sliders-h-solid.svg";
import CubesIconImage from "images/font-awesome/cubes-solid.svg";
import FilterIconImage from "images/font-awesome/filter-solid.svg";
import GlobeIconImage from "images/font-awesome/globe-solid.svg";
import MapIconImage from "images/font-awesome/map-marked-alt-solid.svg";
import PaperPlaneIconImage from "images/font-awesome/paper-plane-solid.svg";
import PrinterIconImage from "images/font-awesome/print-solid.svg";
import TruckIconImage from "images/font-awesome/truck-solid.svg";
import WarehouseIconImage from "images/font-awesome/warehouse-solid.svg";

const Heading = tw(SectionHeading)``;
const Subheading = tw(SubheadingBase)`text-center mb-3`;
const Description = tw(SectionDescription)`text-center mx-auto`;
const ThreeColumnContainer = styled.div`
  ${tw`mt-10 flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap lg:justify-center max-w-screen-lg mx-auto`}
`;
const Column = styled.div`
  ${tw`lg:w-1/3 max-w-xs`}
`;

const Card = styled.a`
  ${tw`flex flex-col items-center text-center h-full mx-4 px-4 py-8 rounded transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 `}
  .imageContainer {
    ${tw`text-center rounded-full p-4 bg-gray-100`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .title {
    ${tw`mt-4 font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-4 text-sm font-medium text-secondary-300`}
  }

  .link {
    ${tw`mt-auto inline-flex items-center pt-5 text-sm font-bold text-primary-300 leading-none hocus:text-primary-900 transition duration-300`}
    .icon {
      ${tw`ml-2 w-4`}
    }
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

export default ({
  cards = [
    {
      imageSrc: ChartAreaIconImage,
      title: "Compare and Ship",
      description:
        "With Shippigo you get to make an informed decision by comparing rates offered by our carrier partners across locations in real time.",
    },
    {
      imageSrc: DesktopIconImage,
      title: "World at One Place",
      description:
        "Shippigo is pre-integrated with the top shipment carriers and ecommerce platforms around. Just add your store and start shipping.",
    },
    {
      imageSrc: MousePointerIconImage,
      title: "Click Away Pickups",
      description:
        "Shippigo is designed to get your orders out the door in minutes. With Bulk Shipping Batches process upto 1000 shipments with just a click.",
    },
    {
      imageSrc: SlidersIconImage,
      title: "No Commitments",
      description:
        "We work on a zero commitment model. When you have a shipment to be picked up just click and we’ll be there. No minimum guarantee needed.",
    },
    {
      imageSrc: CubesIconImage,
      title: "Get Fulfilled",
      description:
        "Selling on online marketplaces (Flipkart, Amazon, Myntra, Jabong, Nykaa)? Fulfil all your appointment based deliveries with Shippigo.",
    },
    {
      imageSrc: PaperPlaneIconImage,
      title: "The Maze Runners",
      description:
        "No more spending hours on the phone with different support centres. Shippigo gives you exhautive post pick up tools that keep the RTO count in check.",
    },
    {
      imageSrc: GlobeIconImage,
      title: "Branched Out",
      description:
        "Shippigo offers Cash On Delivery and Prepaid shipping to around 24,000 pincodes and export - import solutions spanning 220 countries.",
    },
    {
      imageSrc: TruckIconImage,
      title: "Easy Reverse Pickup",
      description:
        "There are bound to be some returned items now and then. Through Shippigo you can service reverse pickup with just a click.",
    },
    {
      imageSrc: PrinterIconImage,
      title: "Branded Communications",
      description:
        "Leave your company’s imprint on every shipment. With Shippigo get access to customization options enabling personalised labels and invoices.",
    },
    {
      imageSrc: WarehouseIconImage,
      title: "Warehousing",
      description:
        "We offer different types of fully operational warehouse space to small as well as big businesses depending upon one’s requirement.",
    },
    {
      imageSrc: MapIconImage,
      title: "Branded Tracking Page",
      description:
        "Direct customers to your website with a customisable tracking page, resulting in increased after-sale engagement and customer retention.",
    },
    {
      imageSrc: FilterIconImage,
      title: "Custom Batches",
      description:
        "Process and sort bulk shipments through sales channels, partners etc. Create custom batches of upto 1000 shipments per batch.",
    },
  ],
  linkText = "Learn More",
  heading = "",
  subheading = "",
  description = "",
  imageContainerCss = null,
  imageCss = null,
}) => {
  /*
   * This componets accepts a prop - `cards` which is an array of object denoting the cards. Each object in the cards array can have the following keys (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  4) url - the url that the card should goto on click
   */
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        {heading && <Heading>{heading}</Heading>}
        {description && <Description>{description}</Description>}
        <ThreeColumnContainer>
          {cards.map((card, i) => (
            <Column key={i}>
              <Card href={card.url}>
                <span className="imageContainer" css={imageContainerCss}>
                  <img src={card.imageSrc} alt="" css={imageCss} />
                </span>
                <span className="title">{card.title}</span>
                <p className="description">{card.description}</p>
                {linkText && (
                  <span className="link">
                    <span>{linkText}</span>
                    <ArrowRightIcon className="icon" />
                  </span>
                )}
              </Card>
            </Column>
          ))}
        </ThreeColumnContainer>
      </ContentWithPaddingXl>
      <DecoratorBlob />
    </Container>
  );
};
