import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

const FormField = tw.div`h-20`;
const SelectContainer = tw.div`relative mt-6 mb-1 border-b-2 focus-within:border-primary-500`;
const InputField = tw.input`pb-1 font-normal block w-full appearance-none focus:outline-none bg-transparent`;
const SelectField = styled(InputField).attrs({ as: "select" })`
  ${tw`bg-transparent cursor-pointer`}
`;
const SelectLabel = tw.label`absolute top-0 -z-1 duration-300 origin-0 text-gray-500 font-medium`;
const Error = tw.small`text-red-500`;

const Select = ({ name, label, options, error, ...rest }) => {
  return (
    <FormField>
      <SelectContainer>
        <SelectField name={name} id={name} placeholder=" " {...rest}>
          <option value="">Choose from the list...</option>
          {options.map((option) => (
            <option value={option.value} key={option.name}>
              {option.name}
            </option>
          ))}
        </SelectField>
        <SelectLabel htmlFor={name}>{label}</SelectLabel>
      </SelectContainer>
      {error && <Error>{error}</Error>}
    </FormField>
  );
};

export default Select;
