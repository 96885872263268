import _http from "./httpService";

export function createTicket(body) {
  return _http.post("user/tickets/createFront", body, {
    headers: { tenant: "shippigo" },
  });
}

export function getUserIP() {
  return _http.get("https://api.ipify.org/?format=json");
}
