import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import ProcessingIcon from "components/misc/ProcessingButtonIcon";
import EmailIllustrationSrc from "images/contact-us.svg";
import Form from "./../common/form";
import Joi from "joi";
import { createTicket, getUserIP } from "services/contactService";
import _logger from "services/logService";
import _notification from "services/notificationService";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col-reverse md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const FormColumn = tw(Column)`md:w-5/12 flex-shrink-0 md:h-auto md:mt-8`;
const TextAndImageColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.img((props) => [
  // `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-96 my-4`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const FormTag = tw.form`mt-8 md:mt-10 text-sm flex flex-col w-full mx-auto md:mx-0`;
const SubmitButton = tw(PrimaryButtonBase)`mt-12`;
const ContactDetailsContainer = tw.div`mt-5`;
const ContactDetails = tw.p`mt-1 text-center md:text-left text-base leading-relaxed text-secondary-100`;

class ContactUsForm extends Form {
  state = {
    data: {
      name: "",
      email: "",
      phone: "",
      message: "",
      queryType: "",
      orderNumber: "",
      awb: "",
      captcha: "",
    },
    queryTypeOptions: [
      {
        name: "Business Query",
        value: "Business Query",
      },
      {
        name: "Order Related Issue",
        value: "Order Related Issue",
      },
      {
        name: "Other",
        value: "Other",
      },
    ],
    errors: {},
    userIP: "",
    loadingIndicator: false,
  };

  toggleLoader = (boolean) => {
    this.setState({ loadingIndicator: boolean });
  };

  get areOrderDetailsRequired() {
    const { queryType } = this.state.data;
    return queryType === "Order Related Issue";
  }

  validateAWB = (value, helpers) => {
    // Use error to return an existing error code
    if (!value.trim() && this.areOrderDetailsRequired) {
      return helpers.error("any.required");
    }

    // Return the value unchanged
    return value;
  };

  schema = Joi.object({
    name: Joi.string().required().trim().label("Full Name"),
    email: Joi.string()
      .pattern(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      .messages({
        "string.pattern.base": `"Email Address" must be valid`,
      })
      .required()
      .trim()
      .label("Email Address"),
    phone: Joi.string()
      .max(10)
      .min(10)
      .pattern(/^([6-9][0-9]*)$/)
      .messages({
        "string.pattern.base": `"Mobile Number" must be valid`,
        "string.min": `"Mobile Number" length must be at least 10 digits long`,
        "string.max": `"Mobile Number" length must be less than or equal to 10 digits long`,
      })
      .required()
      .trim()
      .label("Mobile Number"),
    message: Joi.string().required().trim().label("Message"),
    queryType: Joi.string().required().label("Subject"),
    orderNumber: Joi.any().label("Order Number"),
    awb: Joi.any().custom(this.validateAWB).label("AWB Number"),
    captcha: Joi.string().required().label("Captcha"),
  });

  async componentDidMount() {
    try {
      const { ip: userIP } = await getUserIP();
      this.setState({ userIP });
    } catch (error) {
      // no need to log or console this error
    }
  }

  doSubmit = async () => {
    try {
      this.toggleLoader(true);

      const { data, userIP } = this.state;
      const { origin } = window.location;

      const body = {
        referrer: origin || "Not Found",
        ip: userIP || "Not Found",
        captcha: data.captcha,
        content: {},
      };

      for (let key in data) {
        if (!!data[key]) {
          body.content[key] = data[key];
        }
      }

      const { success, msg } = await createTicket(body);

      if (success) {
        // reset form
        this.resetForm();
        // notify user
        _notification.show(
          "Thank you for emailing us. We will get back to you soon.",
          "success"
        );
      } else {
        _notification.show(msg, "warn");
      }
    } catch (error) {
      _logger.log(error);
    } finally {
      // reset captcha
      this.resetCaptcha();
      this.toggleLoader(false);
    }
  };

  render() {
    const subheading = "Contact Us";
    const heading = (
      <>
        Feel free to <span tw="text-primary-500">get in touch</span>
        <wbr /> with us.
      </>
    );
    const description =
      "Jump start your business with the right Logistic and Shipping partner - Shippigo.";
    const textOnLeft = true;
    const { loadingIndicator } = this.state;

    return (
      <Container id="contact">
        <TwoColumn>
          <FormColumn>
            <FormTag onSubmit={this.handleSubmit}>
              {this.renderInput("name", "Your Full Name *", "text", "100")}
              {this.renderInput("email", "Your Email Address *", "text", "100")}
              {this.renderInput("phone", "Your Mobile Number *", "number")}
              {this.renderSelect(
                "queryType",
                "Your Subject Here *",
                this.state.queryTypeOptions
              )}
              {this.areOrderDetailsRequired &&
                this.renderInput("awb", "AWB Number *", "text", "100")}
              {this.areOrderDetailsRequired &&
                this.renderInput("orderNumber", "Order Number", "text", "100")}
              {this.renderTextArea("message", "Your Message Here *")}
              {this.renderCaptcha("captcha")}
              <SubmitButton type="submit" disabled={loadingIndicator}>
                {loadingIndicator && <ProcessingIcon />}
                Send
              </SubmitButton>
            </FormTag>
          </FormColumn>

          <TextAndImageColumn textOnLeft={textOnLeft}>
            <TextContent>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
              {description && <Description>{description}</Description>}

              <ContactDetailsContainer>
                <ContactDetails>
                  <span tw="font-medium text-primary-500">Address: </span>
                  <span>
                    Unit 158-159, JMD Megapolis, Sohna Road, Sector 48,
                    Gurugram, Haryana, 122018
                  </span>
                </ContactDetails>

                <ContactDetails>
                  <span tw="font-medium text-primary-500">Phone: </span>
                  <span>+91-7291995773</span>
                </ContactDetails>

                <ContactDetails>
                  <span tw="font-medium text-primary-500">Email: </span>
                  <span>support@shippigo.com</span>
                </ContactDetails>
              </ContactDetailsContainer>
            </TextContent>
            <Image
              src={EmailIllustrationSrc}
              loading="lazy"
              alt="Contact Illustration"
            />
          </TextAndImageColumn>
        </TwoColumn>
      </Container>
    );
  }
}

export default ContactUsForm;
