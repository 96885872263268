import React, { Component } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import ReCAPTCHA from "react-google-recaptcha";

const FormField = tw.div`h-24`;
const Error = tw.small`text-red-500`;
const captchaKey = "6LcmcG8UAAAAAGD_qp8IQS9Gs_eGt_8MYqf2x6rS";
const recaptchaRef = React.createRef();

class Captcha extends Component {
  resetCaptcha = () => {
    if (recaptchaRef && recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  render() {
    const { name, error, onReset, ...rest } = this.props;

    return (
      <FormField>
        <ReCAPTCHA sitekey={captchaKey} ref={recaptchaRef} {...rest} />
        {error && <Error>{error}</Error>}
      </FormField>
    );
  }
}

export default Captcha;
