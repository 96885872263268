import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import styles from "../styles/investor.module.css";
import { ReactComponent as CallIcon } from "images/svg-call.svg";
import pdfUrl from "../docs/sample.pdf";

const HeadingRow = tw.div`flex justify-center text-center font-bold`;
const GetInTouch = tw.div`flex flex-col justify-center text-center font-bold capitalize`;
const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`mt-1 leading-relaxed`}
    a {
      ${tw`border-b-2 border-transparent text-primary-700 hocus:text-primary-300 hocus:border-primary-300 pb-1 transition duration-300`}
    }
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`font-bold`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

// let downloadFile = () => {
//   window.location.href = "../docs/sample.pdf";
// };

export default () => {
  return (
    <AnimationRevealPage disabled={true}>
      <Header />
      <Container>
        <ContentWithPaddingXl className={styles.service}>
          <HeadingRow>
            <h2 className={styles.mainHeadline}>Investor's Column</h2>
          </HeadingRow>
          <Text>
            <div className={styles.flexCenter}>
              <a className={styles.downloadBtn} href="#">
                FY 2020-21
              </a>
            </div>
          </Text>
          <GetInTouch>
            <h2>Want to get in touch right away?</h2>
            <div
              className="contactNumber"
              style={{
                display: "inline-flex",
                justifyContent: "center",
                marginTop: "0.8rem",
              }}
            >
              <CallIcon style={{ marginRight: "10px" }} />
              <a href="tel:+917291995773">+91-7291995773</a>
            </div>
          </GetInTouch>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
