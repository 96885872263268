import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../images/hero-banner.svg";
// import CustomersLogoStripImage from "../../images/customers-logo-strip.png";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-2xl mx-auto my-4 md:my-8 py-12 md:py-20`;
const LeftColumn = tw.div`relative lg:w-4/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-2xl md:text-4xl lg:text-5xl xl:text-7xl leading-tight tracking-wide`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const SubParagraph = tw.p`my-5 lg:my-8 text-gray-600 text-sm`;
const StepDescription = tw.p`mt-3 max-w-xs leading-loose text-sm `;

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0 flex items-center justify-center lg:justify-start`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300 focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full bg-primary-500 text-gray-100 font-bold my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

// const Actions = styled.div`
//   ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
//   input {
//     ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
//   }
//   button {
//     ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
//   }
// `;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

// const CustomersLogoStrip = styled.div`
//   ${tw`mt-12 lg:mt-20`}
//   p {
//     ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
//   }
//   img {
//     ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
//   }
// `;

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Shipping <span tw="text-primary-500">simplified!</span>
            </Heading>
            <Paragraph>
              Save time and money with the #1 cloud based shipping software.
            </Paragraph>
            <SubParagraph>
              Shippigo supercharges shipping for Ecommerce Companies,
              Dropshippers, Manufacturers, Online Marketplace Sellers, Trading
              Houses and Logistic Companies.
            </SubParagraph>
            {/* <Paragraph>
              Shippigo supercharges shipping for Ecommerce Companies,
              Dropshippers, Manufacturers, Online Marketplace Sellers, Trading
              Houses and Logistic Companies.
            </Paragraph> */}
            <Actions>
              {/* <input type="text" placeholder="Your E-mail Address" /> */}
              <button
                onClick={() => {
                  window.location.href = "https://app.shippigo.com/register";
                }}
              >
                Get Started
              </button>
            </Actions>
            {/* <CustomersLogoStrip>
              <p>Our TRUSTED Customers</p>
              <img src={CustomersLogoStripImage} alt="Our Customers" />
            </CustomersLogoStrip> */}
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full max-w-lg xl:max-w-4xl"
                src={DesignIllustration}
                alt="Delivery Process"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
