import FAQ from "components/faqs/SingleCol.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import heroScreenshotImageSrc from "images/delivery-service-process.svg";
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import React from "react";
import tw from "twin.macro";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  const faqs = [
    {
      question: "Are all the templates easily customizable ?",
      answer:
        "Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      question: "How long do you usually support an standalone template for ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      question: "What kind of payment methods do you accept ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      question:
        "Is there a subscribption service to get the latest templates ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      question: "Are the templates compatible with the React ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
    {
      question: "Do you really support Internet Explorer 11 ?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    },
  ];

  return (
    <AnimationRevealPage disabled={true}>
      <Hero roundedHeaderButton={true} />

      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            The feature list of Shippigo is{" "}
            <HighlightedText>endless!</HighlightedText>
          </>
        }
        description="You have the best tools required to keep your business at the top. It’s like moving shipments with your mind - Simple, Effective and Efficient."
        linkText={false}
      />

      <MainFeature
        subheading={<Subheading>Process</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        // imageBorder={true}
        // imageDecoratorBlob={true}
      />

      <FeatureStats />

      {/* <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      /> */}

      {/* <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={faqs}
      /> */}

      <ContactUsForm />

      <Footer />
    </AnimationRevealPage>
  );
};
