import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import styles from "../styles/termsService.module.css";

const HeadingRow = tw.div`flex justify-center text-center font-bold`;
const SubHeading = tw.div`flex font-bold mt-4`;
const Text = styled.div`
  ${tw`text-lg text-gray-800`}
  p {
    ${tw`mt-1 leading-relaxed`}
    a {
      ${tw`border-b-2 border-transparent text-primary-700 hocus:text-primary-300 hocus:border-primary-300 pb-1 transition duration-300`}
    }
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`font-bold`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default () => {
  return (
    <AnimationRevealPage disabled={true}>
      <Header />
      <Container>
        <ContentWithPaddingXl className={styles.service}>
          <HeadingRow>
            <h2 className={styles.mainHeadline}>TERMS OF USE</h2>
          </HeadingRow>
          <Text>
            <div>
              <p className={styles.effectiveDate}>
                Effective date: Aug 24, 2022
              </p>

              <SubHeading>
                <h2 className={styles.subHeading}>
                  SHIPPIGO PLATFORM AND LOGISTICS MANAGEMENT SOLUTION
                </h2>
              </SubHeading>

              <p>
                This <strong>Merchant Agreement</strong> (“
                <strong>Agreement</strong>” or “
                <strong>Terms & Conditions</strong>” or “
                <strong>Terms of Use</strong>” or “
                <strong>Terms of Service</strong>”) is between you company /
                individual / enterprise / firm / partnership / body corporate),
                together with any company or other business entity you are
                representing, if any, (hereinafter collectively referred as “
                <strong>Merchant</strong>” or “<strong>you</strong>” or “
                <strong>your</strong>” or “<strong>User</strong>” or “
                <strong>Shipper</strong>”); and{" "}
                <strong>Saged Retail Solutions Private Limited</strong>, a
                company registered under{" "}
                <strong>The Companies Act, 2013</strong>, having its registered
                office at 120, 707, 7th Floor, JMD Megapolis, Sector 48, Sohna
                Road, Gurugram, Haryana 122018, and corporate office at 303, 3rd
                Floor, Neelkanth Corporate Park, Vidyavihar West, Mumbai 400086,
                including any of its current or future subsidiaries or joint
                ventures or majority holding businesses together collectively
                offering <strong>‘Logistics Management Services’</strong>,
                collectively referred under the name ‘Shippigo’ (hereinafter
                referred to as <strong>“we”</strong> or{" "}
                <strong>“Shippigo”</strong> or <strong>“Shippigo™”</strong> or{" "}
                <strong>“Company”</strong>, and together with the User referred
                jointly as the <strong>“Parties”</strong> and individually as a{" "}
                <strong>“Party”</strong>).
              </p>

              <ol style={{ paddingLeft: "0px", marginTop: "1rem" }}>
                <li>
                  <strong>USER ACCOUNT USAGE & TERMS</strong>
                  <ol>
                    <li>
                      This Agreement is a master agreement which governs the
                      relationship between the Parties in relation to one or
                      more Services that are offered by Shippigo to the User,
                      inter-alia as per the terms and specifications mentioned
                      in{" "}
                      <strong>
                        Annexure-A (“Shippigo Service Specifications” or
                        “Service Level Agreement”)
                      </strong>
                      . Shippigo authorizes the User to view and access the
                      content available on the Website/Mobile App solely for
                      ordering, receiving, delivering, and communicating as per
                      this Agreement. The contents in relation to the Services,
                      including but not limited to, information, text, graphics,
                      images, logos, button icons, software code, design, and
                      the collection, arrangement, and assembly of content on
                      the Website and Mobile App (collectively,{" "}
                      <strong>"Shippigo Content"</strong>), are the property of
                      Shippigo and are protected under copyright, trademark, and
                      other applicable laws. User shall not modify the Shippigo
                      Content or reproduce, display, publicly perform,
                      distribute, or otherwise use the Shippigo Content in any
                      manner, without the prior written consent of Shippigo.
                    </li>
                    <li>
                      The Merchant agrees that the Merchant and all the Users
                      invited / added by the Merchants to the Shippigo Account
                      (or <strong>“User Account”</strong> or{" "}
                      <strong>“Merchant Account”</strong> or{" "}
                      <strong>“User Panel”</strong>
                      or <strong>“Merchant Panel”</strong> or
                      <strong> “Your Account”</strong> or
                      <strong>“Your Panel”</strong> or{" "}
                      <strong>“Shipper Account”</strong> or{" "}
                      <strong>“Shipper Panel”</strong>) are 18 years or older to
                      use the Merchant Account and Service.
                    </li>
                    <li>
                      The Merchant agrees to provide full legal name of the
                      company, current registered address, current
                      correspondence address, valid email address for all
                      communication with the company, and any other information
                      needed to complete the sign-up process.
                    </li>
                    <li>
                      The Merchant agrees to provide and ensure all Users
                      invited / added by the Merchants to Shippigo Account
                      provide their full legal name, valid email address, valid
                      cellular (mobile) number (Either Personal Mobile Number
                      linked to Aadhar or Business Number issued by the
                      Merchant), current GSTN registered business address,
                      correct & authorized (Image Trademark / Copyright owned by
                      the Merchant or License Letter sought from the owner of
                      the product or Free Service image) product details &
                      images, correct packaging details and locations, legal
                      trademark name, valid & legal trademark logo and / or any
                      other information needed in order to complete the account
                      configuration process and operations.
                    </li>
                    <li>
                      The Merchant may not use Shippigo™ service for any illegal
                      or unauthorized purpose nor may the Merchant, in the use
                      of the Service, violate any laws in your jurisdiction
                      (including but not limited to copyright laws) as well as
                      the laws of India.
                    </li>
                    <li>
                      The Merchant is responsible & liable for all activity and
                      content (data, graphics, photos, links) that is uploaded
                      within their Merchant Account.
                    </li>
                    <li>
                      The Merchant must not transmit any worms or viruses or any
                      code of a destructive nature and/or disruptive nature.
                    </li>
                    <li>
                      Any breach or violation of any of the Account Terms, as
                      determined by Shippigo in its sole discretion will result
                      in an immediate termination of the services under this
                      Agreement.
                    </li>
                    <li>
                      User shall not transfer or share (including by way of
                      sublicense, lease, assignment, distribute, exchange or
                      other transfer, including by operation of law) their
                      log-in or right to use the Service to any third party
                      and/or frame, scrap, hack, data-mine, extract, collect,
                      copy, adapt, modify any content or material from the
                      website, including but not limited to any data, search
                      results, text, images, audio, video or links for any
                      business, commercial or public purpose. The User shall be
                      solely responsible for the way anyone you have authorized
                      to use the Services and for ensuring that all such users
                      comply with all the terms and conditions of this
                      Agreement. Any violation of the terms and/or conditions of
                      this Agreement by any such user shall be deemed to be a
                      violation thereof by you, towards which Shippigo shall
                      have no liability or responsibility.
                    </li>
                    <li>
                      User agrees not to reproduce, duplicate, copy, sell,
                      resell, or exploit any portion of the Service, use of the
                      Service, or access to the Service without the express
                      written permission by Shippigo™.
                    </li>
                    <li>
                      The User should not mirror any material contained on this
                      Website or the sites of its Service Provider or their
                      associates or affiliates. Shippigo or its associates or
                      affiliates shall take recourse through law and equity for
                      damages arising out any such activity listed. Shippigo
                      grants you a non-exclusive, non- transferable, limited
                      right to enter, view, use and transact on this Website.
                      The User agrees not to interrupt or attempt to interrupt
                      the operation of this Website in any way or access certain
                      areas of the Website which may only be available to
                      registered members. To become a registered member, you may
                      be required to answer certain questions and provide
                      certain details. Answers to such questions and details is
                      mandatory.
                    </li>
                    <li>
                      The User represent and warrant that all information you
                      supply to us, about yourself, and others, is true and
                      accurate.
                    </li>
                    <li>
                      The User understand that except for information relating
                      to Services being offered by a third party on the Website,
                      we do not operate, control, or endorse any information,
                      products or services provided by the third parties on the
                      Website in anyway.
                    </li>
                    <li>
                      Shippigo has taken all reasonable steps to ensure the
                      accuracy of the information on this website. However, we
                      can give no guarantee or warranty regarding the accuracy
                      or completeness of the content of this website. Hence, we
                      accept no liability for any losses or damages (whether
                      direct, indirect, special, consequential, or otherwise)
                      arising out of errors or omissions contained in this
                      website. Shippigo reserves the right to update, add,
                      amend, remove, replace, or change any part of the website
                      content, User Account Panel, including but not limited to
                      functionality and navigation at any time without prior
                      notice. Shippigo shall not be liable for distortion of
                      data arising from any technical fault including
                      transmission errors, technical defects, interruptions,
                      third party intervention or viruses.
                    </li>
                    <li>
                      The User also understand and accept that Shippigo cannot
                      and does not guarantee or warrant that (a) the website
                      shall be available or accessible on an uninterrupted
                      basis, (b) files available for downloading through the
                      Website will be free of viruses, worms or other code that
                      may be damaging, (c) the website would be safe from any
                      hacking or spam attack or the contents of the website is
                      changed by unauthorized third parties, (d) the data is
                      100% secure from any loss or computer viruses, trojan
                      attacks, etc. The User agrees that Shippigo shall not be
                      liable for any losses, costs or damages incurred by the
                      User arising due to the website and the User assumes all
                      risk pertaining to the access and use of the website.
                    </li>
                    <li>
                      The User is responsible for implementing procedures to
                      satisfy their requirements and for accuracy of data input
                      and output.
                    </li>
                    <li>
                      The User agrees and accept that Shippigo may add, change,
                      discontinue, remove, or suspend any other Content or
                      services posted on this Website, including features of
                      services described or depicted on the Website, temporarily
                      or permanently, at any time, without notice and without
                      liability.
                    </li>
                    <li>
                      Multiple users are not permitted to share the same/single
                      log-in. You agree and understand that you are responsible
                      for maintaining the confidentiality of passwords
                      associated with any log-in you use to access the Services.
                    </li>
                    <li>
                      You agree that any information you give to Shippigo will
                      always be true, accurate, correct, complete and up to date
                      to the best of your knowledge. Any phone number used to
                      register with the Service be either registered in the
                      Users name and shall be linked to the Users Aadhar or
                      shall be a business number owned by the Merchant and
                      issued to the User and you might be asked to provide
                      supporting documents to prove the same.
                    </li>
                    <li>
                      You agree that you will not use the Services provided by
                      Shippigo for any unauthorized and unlawful purpose. You
                      will not impersonate another person to any of the
                      aforesaid.
                    </li>
                    <li>
                      You agree to use the Services only for purposes that are
                      permitted by: (a) the terms of usage as outlined herein;
                      and (b) any applicable law, regulation and generally
                      accepted practices or guidelines in the relevant
                      jurisdictions (including any laws regarding the export of
                      goods, data, or software to and from India or other
                      relevant countries).
                    </li>
                    <li>
                      You agree not to access (or attempt to access) any of the
                      Services by any means other than through the interface
                      that is provided by Shippigo, unless you have been
                      specifically allowed to do so in a separate agreement with
                      Shippigo.
                    </li>
                    <li>
                      You agree that you will not engage in any activity that
                      interferes with or disrupts the Services (or the servers
                      and networks which are connected to the Services).
                    </li>
                    <li>
                      You agree that Shippigo reserves the right to modify or
                      terminate any Service for any reason, without notice at
                      any time.
                    </li>
                    <li>
                      You agree that Shippigo reserves the right to refuse
                      service to anyone for any reason at any time.
                    </li>
                    <li>
                      You agree that you are solely responsible for (and that
                      Shippigo has no responsibility to you or to any third
                      party for) any breach of your obligations as per the Terms
                      under this Agreement, supporting Annexures and Commercials
                      shared by Shippigo and for the consequences (including any
                      loss or damage which Shippigo may suffer) of any such
                      breach.
                    </li>
                    <li>
                      You expressly acknowledge and agree that your use of the
                      Services is at your sole risk and that the Services are
                      provided <strong>“as is”</strong> and{" "}
                      <strong>“as available”</strong> basis without any warranty
                      or condition, express, implied, or statutory, and Shippigo
                      at its discretion, will provide any customization or
                      modification and communicate any changes or updates to
                      help the Merchant with reasonable timeframe to make
                      changes to their process. Any exceptional cases where
                      changes would be made effective immediately, will be
                      communicated to the registered ID.
                    </li>
                    <li>
                      Shippigo™ does not warrant that the service will be
                      uninterrupted, timely, secure, or error-free.
                    </li>
                    <li>
                      Shippigo™ does not warrant that the results that may be
                      obtained from the use of the service will be accurate or
                      reliable.
                    </li>
                    <li>
                      You understand that your content (hereinafter referred to
                      as <strong>“Merchant Content”</strong>) (not including
                      credit card information), may be transferred unencrypted
                      and involve (a) transmissions over various networks; and
                      (b) changes to conform and adapt to technical requirements
                      of connecting networks or devices.
                    </li>
                    <li>
                      We may, but have no obligation to, remove Merchant Content
                      and Accounts containing Merchant Content that we determine
                      in our sole discretion is unlawful, offensive,
                      threatening, libelous, defamatory, pornographic, obscene,
                      or otherwise objectionable or violates any party’s
                      intellectual property or these Terms of Service and are
                      not liable or responsible for such content associated to
                      any User.
                    </li>
                    <li>
                      Shippigo™ does not warrant that the quality of any
                      products, services, information, or other material
                      purchased or obtained by you through the Service will meet
                      your expectations, or that any errors in the Service will
                      be corrected.
                    </li>
                    <li>
                      You expressly understand and agree that Shippigo™ shall
                      not be liable for any direct, indirect, incidental,
                      special, consequential, or exemplary damages, including
                      but not limited to, damages for loss of profits, goodwill,
                      use, data, or other intangible losses resulting from the
                      use of or inability to use the service.
                    </li>
                    <li>
                      In no event shall Shippigo™ or our suppliers be liable for
                      lost profits or any special, incidental, or consequential
                      damages arising out of or in connection with our site, our
                      services, or this agreement (however arising including
                      negligence). You agree to indemnify and hold us and (as
                      applicable) our parent, subsidiaries, affiliates,
                      Shippigo™ partners, officers, directors, agents, and
                      employees, harmless from any claim or demand, including
                      reasonable attorneys’ fees, made by any third party due to
                      or arising out of your breach of this Agreement or the
                      documents it incorporates by reference, or your violation
                      of any law or the rights of a third party.
                    </li>
                    <li>
                      Technical support is only provided to paid & subscribed
                      account holders and is only available via email and if
                      agreed upon earlier via phone and request ticketing
                      system.
                    </li>
                    <li>
                      Verbal or written abuse of any kind (including threats of
                      abuse or retribution) of any Shippigo™ customer, Shippigo™
                      employee, member, or officer will result in immediate
                      account termination.
                    </li>
                    <li>
                      We do not claim any intellectual property rights over the
                      material you provide to Shippigo™ service.
                    </li>
                    <li>
                      By uploading images and item description content to
                      Shippigo.com or the website of its subsidiaries, its
                      affiliates and/or its partners and/or its service
                      providers and/or outsourced technology platforms, you
                      agree to allow other internet users to view them and you
                      agree to allow Shippigo™ and / or its subsidiaries and /
                      or its affiliates and / or its affiliates and / or its
                      partners and / or its service providers and / or its
                      outsourced technology platform to display and store them
                      and you agree that Shippigo™ can, at any time, review all
                      the content submitted by you to its Service.
                    </li>
                    <li>
                      The failure of Shippigo™ to exercise or enforce any right
                      or provision of the Terms of Service shall not constitute
                      a waiver of such right or provision. The Terms of Service
                      constitutes the entire agreement between you and Shippigo™
                      and govern your use of the Service, superseding any prior
                      agreements between you and Shippigo™ (including, but not
                      limited to, any prior versions of the Terms of Service).
                    </li>
                    <li>
                      You shall not purchase search engine or other pay per
                      click keywords (such as Google AdWords), or domain names
                      that use Shippigo™ or Shippigo™ trademarks and/or
                      variations and misspellings thereof.
                    </li>
                    <li>
                      Shippigo™ does not pre-screen Content and it is in their
                      sole discretion to refuse or remove any Content that is
                      available via the Service.
                    </li>
                    <li>
                      Questions about the Terms of Service should be sent to
                      support at Shippigo. (dot)com or to any email id
                      communicated by Shippigo over an email from time to time
                      or on the support email id mentioned on its website and
                      updated from time to time.
                    </li>
                    <li>
                      You agree that this Agreement and the Services of Shippigo
                      form a part of subject to any modification or be removed
                      by Shippigo with change in government regulations,
                      policies, and local laws as applicable.
                    </li>
                    <li>
                      <strong>COMMUNICATION POLICY OF THE SITE:</strong>
                      <ol>
                        <li>
                          Upon transacting on the Website, the User will receive
                          an e-mail or an update in their User Account panel on
                          Website (for registered users) from Shippigo informing
                          the status of Users transaction. The e-mail will be
                          sent to the e-mail address provided by the User and
                          Shippigo is not responsible towards the receipt of the
                          said e-mail in your inbox or your junk mailbox. You
                          will have to take due care and caution and be
                          responsible for entering the proper and accurate
                          contact details including your name, email ID to
                          ensure that we can effectively communicate with you.
                        </li>
                        <li>
                          The User acknowledges that the SMS (Short Messaging
                          Service) and/or messaging to any third-party
                          applications (eg: WhatsApp, telegram, etc.) provided
                          by Shippigo is an additional facility provided for the
                          User’s convenience. It is not mandatory under the law
                          in force to provide SMS / any messaging service alerts
                          to the End Customers. In case you / end consumer do
                          not receive any SMS / message for whatsoever reason,
                          Shippigo is not liable for the same.
                        </li>
                        <li>
                          Shippigo is not responsible to provide information on
                          any change in the schedules of the Service Provider.
                          Such information may be provided on best effort basis
                        </li>
                        <li>
                          Any grievance regarding the Service should be
                          communicated as per the grievance policy laid out
                          herein.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>RIGHTS:</strong>
                      <ol>
                        <li>
                          Shippigo Website contains bulletin boards, blogs,
                          access to mailing lists or other message or
                          communication facilities, and the User agrees to use
                          the same only to send and receive messages and
                          materials that are proper and related thereto.
                        </li>
                        <li>
                          By way of example and not as a limitation, the User
                          agree that when using the Website or any facility
                          available here from, the User shall indemnify Shippigo
                          (including its subsidiaries, joint venture business,
                          majority holding business, partners, service providers
                          and outsourced technology platforms) on their acts
                          that is prohibited for any of the following:
                          <ol>
                            <li>
                              Defame, abuse, harass, stalk, threaten or
                              otherwise violate the legal rights (such as rights
                              of privacy and publicity) of others.
                            </li>
                            <li>
                              Publish, post, distribute or disseminate any
                              defamatory, infringing, obscene, indecent, or
                              unlawful material or information.
                            </li>
                            <li>
                              Upload or attach files that contain software or
                              other material protected by intellectual property
                              laws (or by rights of privacy and publicity)
                              unless the User owns or controls the rights
                              thereto or has received all consents thereof as
                              may be required by law.
                            </li>
                            <li>
                              Upload or attach files that contain viruses,
                              corrupted files or any other similar software or
                              programs that may damage the operation of
                              another’s computer.
                            </li>
                            <li>
                              Delete any author attributions, legal notices or
                              proprietary designations or labels in any file
                              that is uploaded.
                            </li>
                            <li>
                              Falsify the origin or source of software or other
                              material contained in a file that is uploaded.
                            </li>
                            <li>
                              Advertise or offer to sell any goods or services,
                              or conduct or forward surveys, contests, or chain
                              letters.
                            </li>
                            <li>
                              Use Websites of Shippigo, its subsidiaries, joint
                              venture, majority holding companies or its
                              contents for any commercial purpose.
                            </li>
                            <li>
                              Make any speculative, false, or fraudulent booking
                              or any booking in anticipation of demand.
                            </li>
                            <li>
                              Access, monitor or copy any content or information
                              of this Website using any robot, spider, scraper
                              or other automated means or any manual process for
                              any purpose without our express written
                              permission.
                            </li>
                            <li>
                              Violate the restrictions in any robot exclusion
                              headers on this Website or bypass or circumvent
                              other measures employed to prevent or limit access
                              to this Website.
                            </li>
                            <li>
                              Take any action that imposes, or may impose, in
                              our discretion, an unreasonable or
                              disproportionately large load on our
                              infrastructure.
                            </li>
                            <li>
                              Deep link to any portion of this Website
                              (including, without limitation, the transaction
                              path for any logistics services) for any purpose
                              without our express written permission; or
                            </li>
                            <li>
                              Host, display, upload, modify, publish, transmit,
                              update, or share the following:
                              <ol>
                                <li>
                                  Information belonging to another person for
                                  which the user does not have any right.
                                </li>
                                <li>
                                  Information that is hateful, grossly harmful
                                  including harmful to minors, pedophilic,
                                  invasive of another privacy, racially,
                                  ethnically objectionable, disparaging,
                                  encouraging money laundering or gambling.
                                </li>
                                <li>
                                  Information that infringes any Patent,
                                  Trademark, Copyright, or other Proprietary
                                  rights.
                                </li>
                                <li>
                                  Information that deceives or misleads the
                                  addressee about the origin of such message or
                                  communicates any information which is grossly
                                  offensive or menacing in nature.
                                </li>
                                <li>
                                  Information that threatens the unity,
                                  integrity, defense, Security or Sovereignty of
                                  India, friendly relation with foreign states
                                  or public order or cause incitement to the
                                  commission of any cognizable offence or
                                  prevents investigation of any offence or
                                  insulting any other nation.
                                </li>
                                <li>
                                  Information that impersonates another person.
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                        <li>
                          If the User Account used by the User to book Services
                          offered by Shippigo shows signs of fraud, abuse, or
                          suspicious activity, Shippigo may cancel any Service
                          bookings associated with your name, email address, or
                          User Account, and close any associated Shippigo User
                          Accounts.
                        </li>
                        <li>
                          In addition, Shippigo may verify (i.e., preauthorize)
                          your credit card.
                        </li>
                        <li>
                          If the User has conducted any fraudulent activity,
                          Shippigo reserves the right to take any necessary
                          legal action and the User may be liable for monetary
                          losses to Shippigo, including litigation costs and
                          damages.
                        </li>
                        <li>
                          To contest the cancellation of a booking or freezing
                          or closure of a User Account, please contact Shippigo
                          customer service.
                        </li>
                        <li>
                          Shippigo reserves the right to undertake all necessary
                          steps to ensure that the security, safety, and
                          integrity of Shippigo’s systems as well as its
                          customer’s interests are and remain, well protected.
                          Towards this end, Shippigo may take various steps to
                          verify and confirm the authenticity, enforceability
                          and validity of orders placed by you.
                        </li>
                        <li>
                          If Shippigo, in its sole and exclusive discretion,
                          concludes that the said transactions are not or do not
                          reasonably appear to be, authentic, enforceable, or
                          valid, then Shippigo may cancel the said orders at any
                          time up before the scheduled time of the services
                          booked through Shippigo.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>AUTHORITY AND AUTHORISATION OF THE USER:</strong>
                      <div>
                        <strong>The User:</strong>
                      </div>
                      <ol>
                        <li>
                          Confirms that it has an unrevoked, existing authority
                          of the board of directors or partners to enter into
                          this Agreement
                        </li>
                        <li>
                          Agrees and authorizes Shippigo and its employee(s),
                          vendor(s), agent(s) and partner(s), to conduct a due
                          diligence on the information provided in the KYC page.
                        </li>
                        <li>
                          Authorizes Shippigo to use any method for using any
                          logistics service provider for transporting goods as
                          Shippigo deems fit.
                        </li>
                        <li>
                          Authorizes Shippigo, Service Provider and any
                          government authority to search any shipment if and as
                          required in accordance with applicable laws.
                        </li>
                        <li>
                          The Customer authorizes Shippigo to maintain copies of
                          shipping documents in accordance with applicable laws.
                        </li>
                        <li>
                          Shall pay Shippigo the amounts payable for shipping as
                          decided by Shippigo.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>THIRD PARTY LINKS:</strong>
                      <ol>
                        <li>
                          The web site contains links to other web sites which
                          are hosted and maintained by third parties.
                        </li>
                        <li>
                          Shippigo makes no representation as to the accuracy,
                          completeness or relevance of the information contained
                          on such third-party sites.
                        </li>
                        <li>
                          The User following links to such sites is at their own
                          risk, and Shippigo will not be liable for any loss or
                          damage arising from the User(s) reliance upon or use
                          of third-party sites.
                        </li>
                        <li>
                          Although Shippigo’s preferred local suppliers have
                          privacy policies in accordance with the new privacy
                          laws, you accept that some third parties can be
                          located overseas, e.g., forwarders, importer on
                          record, exporter on record, customs broker, carriers,
                          system operating system, app ecosystem, search
                          engines, etc., and may not have in place equivalent
                          privacy policies. When you click on links to other web
                          sites, we encourage you to read their privacy
                          policies. Their standards may differ from ours.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>
                    FEES, PAYMENT, MODIFICATION TO SERVICES & PRICES
                  </strong>
                  <ol>
                    <li>
                      Subject to the provisions of this Agreement, the User will
                      pay Shippigo the fees and other amounts set forth in this
                      Agreement, or as otherwise agreed by the Parties.
                    </li>
                    <li>
                      There are different payment term options available, and
                      depending on the payment term decided with Shippigo, the
                      merchant must pay on the pre-decided monthly, quarterly,
                      half-yearly or yearly terms.
                    </li>
                    <li>
                      Shippigo may add new services for additional fees and
                      charges or may proactively amend fees and charges for
                      existing services, at any time in its sole discretion.
                      Fees stated prior to the services being provided, as
                      amended at Shippigo’s sole discretion from time to time,
                      shall apply. Any changes in charges (permanent or
                      temporary) shall be communicated from time to time along
                      with effective date of such charges. The Merchant can
                      refer to their existing charges within their account panel
                      along with the commercial document shared by their Sales
                      Contact Point.
                    </li>
                    <li>
                      If you purchase any subscription based paid service, you
                      authorize Shippigo to charge you applicable fees at the
                      beginning of every subscription period or at such
                      intervals as applicable to the said service, and you
                      authorize Shippigo make such modification to the fee
                      structure as required and agree to abide by such modified
                      fee structure.
                    </li>
                    <li>
                      You agree that the billing credentials provided by you for
                      any purchases from Shippigo will be accurate and you shall
                      not use billing credentials that are not lawfully owned by
                      you.
                    </li>
                    <li>
                      The User agrees to pay all subscription fees, service fees
                      and other fees applicable to User’s use of Services or any
                      other services which are beyond the scope of the Services
                      and/or this Agreement, and the User shall not (directly or
                      indirectly) circumvent the fee structure.
                    </li>
                    <li>
                      The User is solely responsible for payment of all taxes,
                      legal compliances, and statutory registrations and
                      reporting under applicable law. Shippigo is in no way
                      responsible for any of the User’s taxes or legal or
                      statutory compliances.
                    </li>

                    <li>
                      Unless otherwise specified, all fees shall be exclusive of
                      taxes, and Goods and Service tax and other statutory
                      taxes, as applicable, shall be levied on every
                      purchase/Service.
                    </li>
                    <li>
                      The payment process would be considered complete only on
                      receipt of full fees and all other charges (as payable)
                      into Shippigo’s designated bank account.
                    </li>
                    <li>
                      If applicable, Shippigo shall raise an invoice for the
                      Services and the freight amount (if payable) four times in
                      a calendar month (on a predefined working day of a week),
                      or twice in a calendar month (preferably in mid of the
                      month and end of the month) or once a month. The invoice
                      shall be available on the billing / payments / finance
                      section of the User’s dashboard on Shippigo’s platform.
                    </li>
                    <li>
                      The User shall be required to clear the invoice within 7
                      (seven) days from the date of the invoice. Terms of
                      payment for the pre-paid accounts have been specified in
                      <strong> Clause 4</strong> of <strong>Annexure A</strong>.
                    </li>
                    <li>
                      If the User fails to pay the full invoice amount in
                      accordance with the time period mentioned above, Shippigo
                      will have the right to: (i) retain & adjust the amounts
                      received from the end customer of the User through the
                      cash on delivery method (<strong>“COD Amount”</strong>),
                      and/or (ii) retain the custody of the shipments of the
                      User which are in the possession of Shippigo or it’s
                      logistics partner(s), and/or (iii) levy an interest of 18%
                      per annum from the due date of payment, till such time
                      that the User makes entire payment towards the invoice,
                      and/or (iv) forfeit the security deposit amount of the
                      User (if any) lying with Shippigo, and/or (v) close /
                      terminate the online User Account. Without being prejudice
                      to the above, the User hereby agrees that it shall become
                      liable to pay the freight & other applicable charges as
                      soon as a shipment is picked up by the Shippigo’s courier
                      partner, and that Shippigo shall have a right to recover
                      such freight charges from the User (for all the shipments
                      which have been picked-up/shipped) as per the various
                      modes agreed under this Agreement, including but not
                      limited to retaining the COD Amounts for the shipments of
                      the User. Merchant will be able to use services from the
                      positive balance in their Shippigo account.
                    </li>
                    <li>
                      In the event the User closes its account with Shippigo, or
                      this Agreement expires or is terminated, Shippigo will
                      deduct the Fees and the freight amounts due to it from the
                      User, from the COD Amount. Shippigo shall, thereafter,
                      remit the remaining COD Amount after such deduction,
                      within 30 (thirty) days from the date of such closure /
                      expiration / termination, subject to reconciliation and
                      completion of all the shipments and transactions
                      pertaining to the User / his account. In the event, the
                      COD Amount falls short of the outstanding amount payable
                      by the User, the User shall within 5 (five) days from the
                      date of such closure / expiration / termination pay the
                      outstanding amount to Shippigo, and until the payment of
                      the entire outstanding amount, Shippigo shall retain the
                      custody of the shipments of the User which are in the
                      possession of Shippigo or it’s logistics partner or in
                      Shippigo’s warehouse. In the event of any delay in payment
                      of outstanding amount by the User (as required under this
                      clause), Shippigo shall have a right to levy an interest
                      of 18% per annum on the outstanding amount from the due
                      date of payment till the date of actual payment and / or
                      to forfeit the security deposit amount of the User (if
                      any) lying with Shippigo.
                    </li>
                    <li>
                      Save as otherwise stated in this Agreement, for any claims
                      by the User regarding non- connectivity of the shipment
                      (i.e. where the User is claiming that the shipment has
                      been picked up but not connected) - the signed (by the
                      pick-up executive of the logistics company) copy of the
                      manifest sheet of the pick up against the disputed
                      shipment has to be submitted along with the claim request
                      by the User within 2 (two) days from the pickup date.
                      Without the signed manifest any such request shall not be
                      considered valid. The signed manifest to carry the name of
                      the pick-up executive, date & time of pick-up, number of
                      packages picked up, mobile number of the pick-up executive
                      and any other information communicated by Shippigo from
                      time to time.
                    </li>
                    <li>
                      The User agrees that in case of shipments booked under
                      Cash on Delivery (“COD”), Shippigo’s logistics partner
                      shall collect cash, as per the instructions of Shippigo
                      from the consignee as per the details mentioned on the COD
                      order form and remit/reimburse the amount to Shippigo
                      which then forthwith would be reimbursed to the User. In
                      relation to the same, it is hereby clarified that: (i) the
                      User engages Shippigo and in turn its Service Provider as
                      an agent of the User for the purpose of collection of the
                      COD amount; (ii) Shippigo may receive certain
                      consideration (as mutually agreed) in lieu of such
                      services as an agent; and (iii) Shippigo shall not have
                      any title to the goods for which the COD amount will be
                      collected. However, Shippigo shall not be held liable in
                      case the COD amount has been delayed or misplaced by the
                      courier company. The User shall seek its claim, loss or
                      any damages suffered from the courier company directly,
                      and in no way shall recover from Shippigo or hold Shippigo
                      liable for the same. In this regard, the User agrees that
                      Shippigo shall have the right to deduct the freight
                      charges from the COD Amounts received by Shippigo, and
                      then remit/reimburse the balance amount to the User. The
                      COD order form shall be filled in on Shippigo’s website to
                      create a single or bulk shipments and/or the information
                      pushed to Shippigo at the time of booking through
                      Shippigo’s Application Program Interface (API) integrated
                      by the User and/or through any platform that is integrated
                      with Shippigo and facilitates shipment order booking on
                      Shippigo through their integration.
                    </li>
                    <li>
                      Shippigo may, from time to time, in its sole discretion,
                      provide/allocate a credit limit to the User for the
                      Services, which can be used by the User within a specified
                      time. In this regard, the User hereby acknowledges and
                      agrees to pay the service fees and all other amounts
                      (payable by it pursuant to this Agreement), and in the
                      event User fails to timely pay the same, Shippigo shall
                      have an unconditional and irrevocable right, in addition
                      to other rights and remedies available under this
                      Agreement elsewhere, applicable law or otherwise, to
                      recover the unpaid fees and amounts from the user inter
                      alia by way of: (a) retaining the COD Amounts; and/or (b)
                      retaining the custody of the shipments of the User which
                      are in the possession of Shippigo or it’s logistics
                      partner(s); and/or (c) forfeit the security deposit amount
                      of the User (if any) lying with Shippigo.
                    </li>
                    <li>
                      Shippigo reserves the right to modify the fee structure by
                      providing a 7 (seven) days’ prior notice, either by notice
                      on your dashboard or through email to the authorized or
                      registered User, which shall be considered as valid and
                      agreed communication. Upon the User not communicating any
                      negative response/objection to Shippigo to such notice,
                      Shippigo shall apply the modified Fee structure effective
                      from the expiry of the said notice period.
                    </li>
                    <li>
                      To process the fee payments, Shippigo might require
                      details of User’s bank account, credit card number and
                      other such financial information. Users shall be
                      responsible to maintain the confidentiality of such
                      information provided by Users. Such information provided
                      by the Users shall not be stored by Shippigo.
                    </li>
                    <li>
                      You can cancel your access to the Services using any of
                      the cancellation methods listed in the Annexures or by
                      contacting our customer support by email at
                      support@Shippigo.com. The one-time set-up fees shall not
                      be refunded to the User.
                    </li>
                    <li>
                      All fees are exclusive of all state and central taxes,
                      service, sales tax or other taxes, custom duties, VAT,
                      cess, fees, or charges now in force or enacted in the
                      future (“Taxes”) under relevant legislation.
                    </li>
                    <li>
                      Shippigo™ reserves the right to not provide any refunds.
                    </li>
                    <li>
                      Shippigo™ reserves the rights to only provides credit
                      notes (CN) against any agreed liability as per maximum
                      liability limit on respective carrier or for full/ partial
                      charge reversal or for full/partial charge waiver. All CNs
                      are updated in the Shippigo account of the User.
                    </li>
                    <li>
                      The User agrees to accept the credit notes / debit notes
                      raised by Shippigo and its corresponding tax implications
                      in case of any mismatch.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>LIABILITY</strong>
                  <ol>
                    <li>
                      Shippigo shall not be responsible or liable in any manner
                      to the Users for any losses, damage, injuries, or expenses
                      incurred by the Users because of any action taken by
                      Shippigo, where the User has consented for the same.
                    </li>
                    <li>
                      Shippigo does not provide or make any representation,
                      warranty, or guarantee, express or implied about the
                      Services. Shippigo does not verify any content or
                      information provided by Users and to the fullest extent
                      permitted by law disclaims all liability arising out of
                      the User’s use or reliance upon the Services.
                    </li>
                    <li>
                      The Services of Shippigo may be linked to the services of
                      third parties, affiliates, and business partners. Shippigo
                      has no control over, and not liable or responsible for
                      content, accuracy, validity, reliability, quality of such
                      services or made available by/through our Services.
                    </li>
                    <li>
                      Notwithstanding anything contrary provided in this
                      Agreement, in no event, including but not limited to
                      negligence, shall Shippigo, or any of its directors,
                      officers, employees, agents or content or service
                      providers (collectively, the{" "}
                      <strong>“Protected Entities”</strong>) be liable for any
                      direct, indirect, special, incidental, consequential,
                      exemplary or punitive damages arising from, or directly or
                      indirectly related to, the use of, or the inability to
                      use, the Services or the content, materials and functions
                      related thereto, User’s provision of information via the
                      Services, lost business or lost sales, even if such
                      Protected Entity has been advised of the possibility of
                      such damages. In no event shall the total aggregate
                      liability of the Shippigo to a User for all damages,
                      losses, and causes of action (whether in contract or tort,
                      including, but not limited to, negligence or otherwise)
                      arising from the terms and conditions of this Agreement or
                      a User’s use of the Services exceed, in the aggregate, the
                      maximum liability amount on each courier company mentioned
                      in the pricing commercial shared with the User.
                    </li>
                    <li>
                      In no event shall Shippigo or any of the Protected
                      Entities be liable for failure on the part of the Users to
                      provide agreed Services. In no event shall Shippigo or any
                      of the Protected Entities be liable for any activity in
                      relation to the Services provided to a User.
                    </li>
                    <li>
                      Shippigo or any of the Protected Entities shall not be
                      liable for any act or omission of any other person/ entity
                      furnishing a portion of the Service, or from any act or
                      omission of a third party, including those vendors
                      participating in the Services, or for any unauthorized
                      interception of your communications or other breaches of
                      privacy attributable in part to the acts or omissions of
                      the User or third parties, or for damages associated with
                      the Service, or equipment that it does not furnish, or for
                      damages that result from the operation of the User
                      provided systems, equipment, facilities or services that
                      are interconnected with the Service.
                    </li>
                    <li>
                      Shippigo shall neither be liable to remit the
                      Cash-on-Delivery (COD) that is to be received as payment
                      by the User in case of forcible snatching of the delivery
                      package nor be liable to provide any claim towards
                      forcible return of open package by the End Customer
                      (Consignee). Such incidents/cases shall be the sole
                      responsibility of the User and the User is liable to
                      initiate actions with the end consumer to resolve such
                      incidents, if any, on its own, including but not limited
                      to legal processes.
                    </li>
                    <li>
                      The User undertakes to resolve the disputes raised, if
                      any, by the buyer(s) within a period of 24 hours from the
                      raising of such dispute(s). Failure to do so shall
                      enable/authorize Shippigo to hold the COD remittance, till
                      the time such dispute(s) is rectified by the User.
                    </li>
                    <li>
                      Shippigo shall not be liable to you or to any third party
                      for any modification, price change, change in carrier
                      liability, suspension, or discontinuance of the service.
                    </li>
                    <li>
                      The liability of Shippigo arising due to unmatched credits
                      with the User under the GST Regime would be limited to the
                      amount of tax (to the extent of mismatch) charged by
                      Shippigo for the specific invoice.
                    </li>
                    <li>
                      Shippigo shall coordinate with the Service Provider to
                      facilitate resolution of disputes / issues / claims raised
                      by the User.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>GENERAL REPRESENTATIONS AND WARRANTIES</strong>
                  <ol>
                    <li>
                      Each Party represents and warrants to the other Party
                      that:
                      <ol>
                        <li>
                          it has all necessary rights, powers, and authority to
                          enter and perform this Agreement; and
                        </li>
                        <li>
                          It is a validly existing business entity and is duly
                          licensed and qualified to carry on its present
                          business and operations to perform its obligations
                          hereunder.
                        </li>
                        <li>
                          It and its personnel have all rights, licenses,
                          permits, qualifications and consents necessary to
                          perform its obligations hereunder.
                        </li>
                        <li>
                          It and its personnel will comply with all terms of
                          this Agreement, all laws appliable to this Agreement
                          and their performance hereunder.
                        </li>
                        <li>
                          The entrance and performance of this Agreement by it
                          shall not violate any applicable law and shall not
                          breach any agreement, covenant, court order, judgment,
                          or decree to which such Party or by which it is bound.
                        </li>
                        <li>
                          It is not in default of and there are no proceedings
                          threatened or pending under any order of any court,
                          arbitrator, administrative agency, or other
                          governmental authority which would affect its
                          performance of this Agreement.
                        </li>
                        <li>
                          Its performance of services pursuant to this Agreement
                          does not infringe or misappropriate the Intellectual
                          Property Rights or other rights of any third party.
                        </li>
                        <li>
                          It agrees to furnish such information and documents as
                          are necessary to comply with applicable laws, rules,
                          and regulations.
                        </li>
                        <li>
                          It agrees to not make any statements that criticize,
                          ridicule, disparage or are otherwise derogatory about
                          the Other Party, any of its subsidiaries, affiliates,
                          employees, directors, or shareholders.
                        </li>
                      </ol>
                    </li>
                    <li>
                      The User represents and warrants to Shippigo as follows,
                      that:
                      <ol>
                        <li>
                          It is solely responsible for the packaging the goods
                          in a manner that the package and the product inside
                          the package can withstand transit pressure, multiple
                          handling, and any form of stress.
                        </li>
                        <li>
                          Shippigo or its authorized agent or subcontractor or
                          service provider’s responsibility ends when the
                          shipment is delivered to the consignee or to the
                          shipper or the representative of either the shipper or
                          consignee.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>INDEMNITY</strong>
                  <ol>
                    <li>
                      The User (<strong>“Indemnifying Party”</strong>) hereby
                      agrees to indemnify, defend and hold Shippigo, its
                      affiliates, officers, directors, employees, contractors,
                      sub-contractors, consultants, licensors, other third party
                      service providers, agents and representatives
                      (individually & collectively called{" "}
                      <strong>“Indemnified Party”</strong>) harmless from and
                      against claims, demands, actions, liabilities, costs,
                      interest, damages and expenses of any nature whatsoever
                      (including all legal and other costs, charges and
                      expenses) incurred or suffered (directly or indirectly) by
                      the Indemnified Party, on account of: (a) Indemnifying
                      Party’s access to or use of Services; (b) violation of
                      this Agreement or any terms of use of the Services by the
                      Indemnifying Party (and/or its officers, directors,
                      employees, contractors, consultants and agents); (c)
                      violation of applicable law by the Indemnifying Party
                      (and/or its officers, directors, employees, contractors,
                      consultants and agents); (d) wrongful or negligent act or
                      omission of the Indemnifying Party (and/or its officers,
                      directors, employees, contractors, consultants and
                      agents); (e) any third party action or claim made against
                      the Indemnified Party, by reason of any actions undertaken
                      by the Indemnifying Party (and/or its officers, directors.
                      Employees, consultants and agents); and (f) any duties,
                      taxes, octroi, cess, clearance charges and any other
                      charge/levy by whatsoever name called, levied on the
                      shipments, and (g) denial or delay of credit, refund, or
                      other benefit to Shippigo and / or to the Service Provider
                      due to non-compliance by the User (failure to upload
                      details of the sale on the GSTN portal, failure to pay
                      goods and service tax (GST) to the government) and / or
                      non-furnishing or furnishing of incorrect or incomplete
                      documents by the Customer, and (h) any error or violation
                      resulting in variation between declaration of goods and
                      its value and actual goods and its value; and (i) any
                      dispute and actions with the consignees related to
                      quality, quantity and condition of the contents including,
                      but not limited to transit damages; and (j) any loss or
                      damage arising due to force majeure events; and (k) any
                      manufacturing defect, defective or expired goods.
                    </li>
                    <li>
                      The User undertakes to fully indemnify and hold the
                      third-party providing shipping and courier services (
                      <strong>“Courier Company”</strong>) and Indemnified Party
                      harmless in case of any breach of security procedures,
                      breach of any term or condition of this Agreement, or
                      breach of any applicable law, by the User (and/or its
                      officers, directors, and employees) and / or by its
                      customers.
                    </li>
                    <li>
                      Shippigo will notify the User promptly of any such claim,
                      loss, liability, or demand, and in addition to the User
                      foregoing obligations, the User agrees to provide Shippigo
                      with all reasonable assistance, at the User’s expense, in
                      defending any such claim, loss, liability, damage, or
                      cost.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>COMPLIANCE WITH LAWS</strong>
                  <ol>
                    <li>
                      Each Party shall at all times and at its/his/her own
                      expense: (a) strictly comply with all applicable laws, now
                      or hereafter in effect, relating to its/his/her
                      performance of this Agreement; (b) pay all fees and other
                      charges required by such applicable law; and (c) maintain
                      in full force and effect all licenses, permits,
                      authorizations, registrations and qualification from any
                      authority to the extent necessary to perform its
                      obligations hereunder.
                    </li>
                    <li>
                      The User hereby agrees and acknowledges that it shall be
                      responsible for all warranties (whether express or
                      implied) relating to the performance of Services, to the
                      maximum extent permissible under applicable law.
                    </li>
                    <li>
                      The User warrants at their own expense, to have secured
                      the necessary permits, franchises, licenses, or other
                      authorities required to lawfully effect the transportation
                      of the Shipment.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>USE OF CONFIDENTIAL INFORMATION</strong>
                  <ol>
                    <li>
                      Each Party may be given access to Confidential Information
                      from the other Party to perform its obligations under this
                      Agreement. The Party that receives Confidential
                      Information shall be known as{" "}
                      <strong>“Receiving Party”</strong>. The Party that
                      discloses Confidential Information shall be known as
                      <strong>“Disclosing Party”</strong>.
                    </li>
                    <li>
                      The Receiving Party acknowledges that the Confidential
                      Information is received on a confidential basis, and that
                      the Disclosing Party shall remain the exclusive owner of
                      its Confidential Information and of Intellectual Property
                      rights contained therein. No license or conveyance of any
                      such rights to the Receiving Party is granted or implied
                      under this Agreement.
                    </li>
                    <li>
                      The Receiving Party shall:
                      <ol>
                        <li>
                          use the Confidential Information of the Disclosing
                          Party only for purposes of complying with its
                          obligations under this Agreement and, without limiting
                          the generality of the foregoing, shall not, directly
                          or indirectly, deal with, use, exploit or disclose
                          such Confidential Information or any part thereof to
                          any person or entity or for any purpose whatsoever (or
                          in any manner which would benefit any competitor of
                          the Disclosing Party) except as expressly permitted
                          hereunder or unless and until expressly authorized in
                          writing to do so by the Disclosing Party.
                        </li>
                        <li>
                          use reasonable efforts to treat, and to cause all its
                          officers, agents, servants, employees, professional
                          advisors and contractors and prospective contractors
                          to treat, as strictly confidential all Confidential
                          Information. In no event shall such efforts be less
                          than the degree of care and discretion as the
                          Receiving Party exercises in protecting its own
                          valuable confidential information. Any contractors
                          engaged by or prospective contractors to be engaged by
                          the Receiving Party in connection with the performance
                          of the Services shall be required to assume
                          obligations of secrecy equal to or greater than the
                          obligations that the Receiving Party has assumed in
                          this Agreement with respect to the Confidential
                          Information.
                        </li>
                        <li>
                          not, without the prior written consent of the
                          Disclosing Party, disclose or otherwise make available
                          the Disclosing Party’s Confidential Information or any
                          part thereof to any party other than those of its
                          directors, officers, agents, servants, employees,
                          professional advisors, contractors, or prospective
                          contractors who need to know the Confidential
                          Information for the purposes set forth herein.
                        </li>
                        <li>
                          not copy or reproduce in any manner whatsoever the
                          Confidential Information of the Disclosing Party or
                          any part thereof without the prior written consent of
                          the Disclosing Party, except where required for its
                          own internal use in accordance with this Agreement;
                          and
                        </li>
                        <li>
                          promptly, upon termination or expiration of this
                          Agreement, return and confirm in writing the return of
                          all originals, copies, reproductions, and summaries of
                          Confidential Information or, or at the option of the
                          Disclosing Party, destroy and confirm in writing the
                          destruction of the Confidential Information (this sub-
                          clause being applicable only on the User).
                        </li>
                      </ol>
                    </li>
                    <li>
                      Provided, however that nothing herein shall restrict in
                      any manner the ability of either Party to use or disclose
                      Confidential Information owned by it in any manner
                      whatsoever, and the obligations of confidentiality herein
                      shall apply to each Party only to the extent that the
                      Confidential Information or portion thereof is not owned
                      by that particular Party.
                    </li>

                    <li>
                      <strong>OWNERSHIP:</strong>
                      <ol>
                        <li>
                          All materials on Shippigo Website, including but not
                          limited to audio, images, software, text, icons, and
                          such like (the <strong>“Content”</strong>), are
                          protected by copyright under international conventions
                          and intellectual property laws. The User cannot use or
                          license the Content, except as specified herein. The
                          User agrees to follow all instructions on the Website
                          limiting the way the User may use the Content.
                        </li>
                        <li>
                          There are several proprietary logos, service marks and
                          trademarks found on Shippigo Website whether
                          owned/used by Shippigo or otherwise. By displaying
                          them on the Website, Shippigo is not granting you any
                          license to utilize those proprietary logos, service
                          marks, or trademarks. Any unauthorized use of the
                          Content may violate copyright laws, trademark laws,
                          the laws of privacy and publicity, civil and criminal
                          statutes.
                        </li>
                        <li>
                          The content and information on Shippigo Website
                          including, the availability of logistics services, as
                          well as the infrastructure used to provide such
                          content and information, is proprietary to Shippigo or
                          our Service Providers.
                        </li>
                        <li>
                          While the User may make limited copies of their
                          transactions for Service or Service reservations
                          booked through the Website, the User agrees not to
                          otherwise modify, copy, distribute, transmit, display,
                          perform, reproduce, publish, license, create
                          derivative works from, transfer, or sell or re- sell
                          any information, software, products, or services
                          obtained from or through this Website.
                        </li>
                        <li>
                          All data, records and reports produced pertaining to
                          the Services under this Agreement, shall be treated by
                          the User and its subcontractors as the exclusive
                          property of Shippigo and furnishing of such data,
                          records, and reports to any third party, whether for
                          commercial or non-commercial purposes, shall have the
                          prior written consent of Shippigo.
                        </li>
                      </ol>
                    </li>

                    <li>
                      <strong>SHIPPIGO RIGHTS:</strong>
                      <ol>
                        <li>
                          If the User sends any communication or materials to
                          Shippigo by electronic mail or otherwise, including
                          any comments, data, questions, suggestions or the
                          like, all such communications are, and will be treated
                          by Shippigo, as non-confidential.
                        </li>
                        <li>
                          The User hereby gives up all claim that any use of
                          such material violates any of the User’s rights
                          including moral rights, privacy rights, proprietary or
                          other property rights, publicity rights, rights to
                          credit for material or ideas, or any other right,
                          including the right to approve the way Shippigo uses
                          such material.
                        </li>
                        <li>
                          Any material submitted to this Website may be adapted,
                          broadcasted, changed, copied, disclosed, licensed,
                          performed, posted, published, sold, transmitted, or
                          used by Shippigo anywhere and in any medium, forever
                          for the purpose of rendering the services to the User.
                        </li>
                      </ol>
                    </li>

                    <li>
                      Information shall not be deemed Confidential Information
                      and the provisions of this Clause shall not apply to:
                      <ol>
                        <li>
                          Information which was already known to the Party
                          before being furnished by the Other Party pursuant to
                          this Agreement or other Confidential arrangements with
                          the Party or any of its affiliates, provided the Party
                          can prove to the satisfaction of the Other Party, that
                          it was in possession of the information prior to the
                          disclosure by the Disclosing Party.
                        </li>
                        <li>
                          Disclosure that is required by law or by the order of
                          a Court of competent jurisdiction.
                        </li>
                      </ol>
                    </li>

                    <li>
                      The User agrees that Shippigo shall not be liable for
                      below:
                      <ol>
                        <li>
                          The User fails to exit the login panel and platforms
                          at the end of each session.
                        </li>
                        <li>
                          An unauthorized use of the account information or any
                          other breach of security had been undertaken due to
                          reasons attributed to the User.
                        </li>
                        <li>
                          The User uses the shipping information of the Service
                          provider and any information of the End Consumer.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
                  <ol>
                    <li>
                      The User acknowledges that the Intellectual Property
                      rights in all the materials that have been developed by
                      Shippigo and provided to the User, shall vest with
                      Shippigo.
                    </li>
                    <li>
                      The User hereby agrees and acknowledges that the
                      Intellectual Property rights in all the material created
                      and developed by the User, including any material created
                      and developed by the User for the performance of Services
                      under the terms of this Agreement, shall vest with
                      Shippigo.
                    </li>
                    <li>
                      All the Intellectual Property already developed and/or
                      owned by each Party shall continue to vest with the
                      concerned Party.
                    </li>
                    <li>
                      The Parties recognize that all third-party Intellectual
                      Property rights are the exclusive property of their
                      respective owners.
                    </li>
                    <li>
                      Each Party agrees that the brands, logos, trademarks, etc.
                      belonging to Each Party are the exclusive property of the
                      respective Party and cannot, in any circumstances be used,
                      or copied, or altered in any manner which is identical /
                      similar to the brands / logos / trademarks of the Other
                      Party, without being specifically authorized in writing by
                      that Other Party.
                    </li>
                    <li>
                      Each Party authorizes the Other Party to use and include
                      the trademarks, brand names, logos, company write-up and
                      corporate name, on their website and any marketing &
                      promotional material.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>NON-SOLICITATION</strong>
                  <p>
                    The User agrees and undertakes that, during the term of this
                    Agreement, and for a period of 36 (thirty-six) months
                    thereafter, it shall not directly or indirectly attempt in
                    any manner to solicit, any client/customer, or to persuade
                    any person, firm or entity which is a
                    client/customer/supplier/vendor/partner of Shippigo, to
                    cease doing business or to reduce the amount of business
                    which any such client/customer/supplier/vendor/partner has
                    customarily done or might propose doing with Shippigo.
                  </p>
                </li>

                <li>
                  <strong>TERM, CANCELLATION AND TERMINATION</strong>
                  <ol>
                    <li>
                      This Agreement shall come into force on and from the date
                      from which the User started procuring Services in any form
                      or capacity and shall remain in existence while the User
                      is a user of any of the Services in any form or capacity,
                      until terminated by either Party in accordance with the
                      provisions of this Agreement.
                    </li>
                    <li>
                      The User can request for termination of the Agreement at
                      any time with a 30 (thirty) day prior written notice
                      subject to the provisions in the annexure for the Services
                      undertaken. During this notice period, Shippigo will
                      investigate and ascertain the fulfilment of any ongoing
                      Services and pending dues related to fees or any other
                      amount payable by the User. The User shall be obligated to
                      clear any dues with Shippigo for any of its Services which
                      the User has availed in accordance with this Agreement.
                      Shippigo shall not be liable to the User or any third
                      party for any termination of User’s access to the
                      Services.
                    </li>
                    <li>
                      Without limiting any other remedies, Shippigo reserves the
                      right to immediately suspend access to the client account
                      and / or terminate this Agreement in cases where:
                      <ol>
                        <li>
                          the User breaches any terms and conditions of this
                          Agreement. In the event the breach can be rectified,
                          the User’s failure to cure the breach within 7 (seven)
                          business days of identifying or alternatively being
                          notified by Shippigo of such breach.
                        </li>
                        <li>
                          Shippigo believes in its sole discretion that the
                          User’s actions may cause legal liability for such User
                          or for Shippigo or are contrary to the terms of use of
                          the Services, or terms of this Agreement; and
                        </li>
                        <li>
                          Shippigo suspects that the User (by conviction,
                          settlement, insurance, or escrow investigation, or
                          otherwise) have engaged in fraudulent activity in
                          connection with the site, or
                        </li>
                        <li>
                          A petition for relief under any bankruptcy or
                          insolvency is filed by or against the User, or
                        </li>
                        <li>
                          Assignment for the benefit of the creditors, or a
                          receiver or an administrative receiver or
                          administrator is appointed for all or a substantial
                          part of the assets of the User.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Shippigo reserves the right to immediately terminate this
                      agreement in case where Shippigo deems fit for its own
                      convenience, without providing any reason with a 15 days’
                      notice.
                    </li>
                    <li>
                      Once suspended or terminated, the User shall not continue
                      to use the Services under the same account, a different
                      account or re-register under a new account, unless
                      explicitly permitted by Shippigo.
                    </li>
                    <li>
                      Once your account is cancelled all your Content will be
                      immediately deleted from the Service. Since deletion of
                      all data is final, please be sure that you have taken a
                      download of all your data and are completely sure to
                      cancel your account. Shippigo shall not be liable or
                      responsible for facilitating any User data once the
                      account had been cancelled by the User or Terminated by
                      Either Party.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>MISUSE OF THE SERVICES</strong>
                  <p>
                    Shippigo may restrict, suspend, or terminate the account of
                    any User who abuses or misuses the Services. Misuse includes
                    creating multiple or false profiles, infringing any
                    Intellectual Property rights, violating any of the terms and
                    conditions of this Agreement, or any other behaviour that
                    Shippigo, in its sole discretion, deems contrary to its
                    purpose. In addition, and without limiting the foregoing,
                    Shippigo has adopted a policy of terminating accounts of
                    Users who, in Shippigo’s sole discretion, are deemed to be
                    repeat infringers of any terms of use even after being
                    warned by it. In addition, Shippigo may also restrict,
                    deactivate, suspend, or terminate the account of any User
                    upon the request/instructions of Shippigo’s courier partner.
                  </p>
                </li>

                <li>
                  <strong>GOVERNING LAW AND DISPUTE RESOLUTION</strong>
                  <ol>
                    <li>
                      This Agreement shall be governed by the laws of India and
                      subject to the Clause below, the courts of Mumbai shall
                      have exclusive jurisdiction to determine any disputes
                      arising out of, under, or in relation, to the provisions
                      of this Agreement.
                    </li>
                    <li>
                      Any dispute arising under this Agreement shall be settled
                      by arbitration to be held in Mumbai in accordance with the
                      (Indian) Arbitration and Conciliation Act, 1996, in the
                      English language, and shall be heard and determined by a
                      sole arbitrator appointed by Shippigo. The decision of the
                      sole arbitrator shall be final, conclusive, and binding on
                      the Parties. Notwithstanding the foregoing, nothing
                      contained herein shall be deemed to prevent either Party
                      from seeking and obtaining injunctive and/or equitable
                      relief from any court of competent jurisdiction.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>SEVERABILITY & WAIVER</strong>
                  <p>
                    The invalidity or unenforceability of any provision in this
                    Agreement shall in no way affect the validity or
                    enforceability of any other provision herein. In the event
                    of the invalidity or unenforceability of any provision of
                    this Agreement, the Parties will immediately negotiate in
                    good faith to replace such a provision with another, which
                    is not prohibited or unenforceable and has, as far as
                    possible, the same legal and commercial effect as that which
                    it replaces.
                  </p>
                </li>

                <li>
                  <strong>FORCE MAJEURE</strong>
                  <ol>
                    <li>
                      Neither Party shall be liable for any failure or delay in
                      performance of any obligation, under this Agreement to the
                      extent that such failure or delay is due to a Force
                      Majeure Event. The Party having any such cause shall
                      promptly notify the other Party in writing of the nature
                      of such cause and the expected delay.
                    </li>
                    <li>
                      If, however, it is not feasible for a Party to prevent the
                      occurrence of the Force Majeure Event as a result of which
                      that Party is prevented from performing its obligation for
                      more than 30 (thirty) days due to such Force Majeure Event
                      (<strong>“Aggrieved Party”</strong>), the other Party may
                      decide to release the Aggrieved Party from performing its
                      obligation hereunder or may modify the relevant provisions
                      of this Agreement affected by the Force Majeure Event so
                      long as the Force Majeure Event continues, in order to
                      enable the Aggrieved Party to perform its other
                      obligations hereunder as so modified. However, in the
                      event, Force Majeure Event continues for a period of more
                      than 60 (sixty) days, the Aggrieved Party may terminate
                      this Agreement with a written notice to the other Party.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>ENTIRE AGREEMENT, ASSIGNMENT AND SURVIVAL</strong>
                  <ol>
                    <li>
                      This Agreement, the annexures, Standard Operating
                      Procedures (<strong>“SOPs”</strong>), Service Level
                      Agreement (<strong>“SLA”</strong>), pricing proposal or
                      any pricing communication from time to time, addendums,
                      announcements & notifications, and any other documents
                      entered into or delivered as contemplated in this
                      Agreement herein sets out the entire agreement and
                      understanding between the Parties with respect to the
                      subject matter hereof. Unless otherwise decided by
                      Shippigo, the annexures containing specific terms of use
                      supersedes all general terms of the Agreement, previous
                      letters of intent, heads of terms, prior discussions and
                      correspondence exchanged between the Parties in connection
                      with the Agreement referred to herein. Similarly, unless
                      otherwise decided by Shippigo, the SOPs / SLAs / Pricing /
                      announcements / notifications / addendums issued in
                      furtherance to this Agreement, shall supersedes the
                      provisions of this Agreement and of the annexures.
                    </li>
                    <li>
                      This Agreement and the rights and obligations herein shall
                      not be assigned by the User, without the written consent
                      of Shippigo.
                    </li>
                    <li>
                      The provisions which are by their nature, intended to
                      survive the termination of this Agreement, shall survive
                      the termination of this Agreement.
                    </li>
                    <li>
                      In case of ambiguity in clauses between this Terms of
                      Service and Conditions of Carriage of each carrier, the
                      strictest of the clause shall be applicable on the User.
                    </li>
                    <li>
                      This Agreement must be read along with Conditions of
                      Carriage of each Service Provider through whom shipment
                      has been or would be booked.
                    </li>
                    <li>
                      Any clause mentioned in Conditions of Carriage of each
                      Service Provider not mentioned in this Terms of Service
                      shall be applicable on the User/Customer
                    </li>
                    <li>
                      Conditions of Carriage of each Service Provider shall be
                      available on their respective website address.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>NO PARTNERSHIP OR AGENCY</strong>
                  <p>
                    Nothing in this Agreement (or any of the arrangements
                    contemplated herein) shall be deemed to constitute a
                    partnership between the Parties hereto, nor, except as may
                    be expressly provided herein, constitute any Party as the
                    agent of another Party for any purpose, or entitle any Party
                    to commit or bind another Party in any manner.
                  </p>
                </li>

                <li>
                  <strong>WAIVERS AND REMEDIES</strong>
                  <p>
                    No failure or delay by the Parties in exercising any right
                    or remedy provided by law under or pursuant to this
                    Agreement shall impair such right or remedy or operate or be
                    construed as a waiver or variation of it or preclude its
                    exercise at any subsequent time and no single or partial
                    exercise of any such right or remedy shall preclude any
                    other or further exercise of it or the exercise of any other
                    right or remedy. The rights and remedies of the Parties
                    under or pursuant to this Agreement are cumulative, may be
                    exercised as often as such Party considers appropriate and
                    are in addition to its rights and remedies under the general
                    laws of India.
                  </p>
                </li>

                <li>
                  <strong>SPECIFIC PERFORMANCE</strong>
                  <p>
                    The Parties shall be entitled to seek and enforce specific
                    performance of this Agreement, in addition to any other
                    legal rights and remedies, without the necessity of
                    demonstrating the inadequacy of monetary damages
                  </p>
                </li>

                <li>
                  <strong>INDIRECT AND CONSEQUENTIAL LOSSES</strong>
                  <p>
                    Save as expressly provided otherwise in this Agreement,
                    neither Party shall be liable under or in connection with
                    this Agreement for any loss of income, loss of profits or
                    loss of contracts, or for any indirect or consequential loss
                    or damage of any kind, in each case howsoever arising and
                    whether caused by tort (including negligence), breach of
                    contract or otherwise.
                  </p>
                </li>

                <li>
                  CONTACT INFORMATION & NOTIFICATION
                  <ol>
                    <li>
                      If any User has any question, issue, complaint regarding
                      any of our Services, please contact our customer service
                      on the email address mentioned on the website from time to
                      time.
                    </li>
                    <li>
                      If a User has any questions concerning Shippigo, the
                      Services, this Agreement, or anything related to any of
                      the foregoing, it can be reached on the email address
                      mentioned on the website or via the contact information
                      available from the following hyperlink
                      https://Shippigo.com.
                    </li>
                    <li>
                      The User hereby agrees and provides his consent to receive
                      communications, correspondences, updates, notifications,
                      etc. from Shippigo through email, SMS, WhatsApp, and any
                      other mode as agreed by the Parties from time to time. The
                      Parties agree that the said communications,
                      correspondences, updates, notifications, etc. will be
                      legally binding on them.
                    </li>
                    <li>
                      Notwithstanding anything provided contrary in this
                      Agreement, the User hereby: (i) agrees that the User has
                      voluntarily submitted the various KYC information and
                      documents (including but not limited to Aadhaar card /
                      OTP, PAN card, voter id, passport, driving license, GST
                      certificate, income tax returns, entity details, etc.) and
                      requisite information as required by Shippigo from time to
                      time; (ii) provides his consent for verification of the
                      information and documents submitted to Shippigo in order
                      to establish its genuineness in the manner permitted by
                      Applicable Laws; and (iii) provides his consent and
                      further authorizes Shippigo to share his relevant details
                      and documents (including but not limited to
                      business/registered name(s), phone number(s), registered /
                      corporate / correspondence / additional place of business
                      address(es), email-id(s), PAN card, TAN details, GST
                      details, bank account details, KYC documents, etc.) with
                      the concerned entity for processing of insurance claims
                      and with the concerned judicial authority, court, police,
                      complainant, etc. (as the case may be) in the event of a
                      complaint been filed against the User or dispute been
                      raised in relation to the shipment(s) made by the User.
                    </li>
                    <li>
                      Any notice, request, demand, claim or other communication
                      hereunder shall be deemed duly given if emailed or sent by
                      Registered Post or Speed Post.
                    </li>
                    <li>
                      It has been clarified that the Seller shall voluntarily
                      provide the requisite KYC information/documents and
                      subsequently the consent for verification of such
                      information/documents by Shippigo.
                    </li>
                  </ol>
                </li>

                <li>
                  <strong>SURVIVAL:</strong>
                  <p>
                    Any terms and conditions that by their nature or otherwise
                    reasonable should survive expiration or termination of this
                    Agreement, shall be deemed to survive the expiration or
                    termination of this Agreement. Such terms and condition
                    include but are not limited to indemnification,
                    confidentiality, payment terms, relationship, liability,
                    Jurisdiction, Governing Laws, and Dispute Resolution.
                  </p>
                </li>

                <li>
                  <strong>SUCCESSORS AND ASSIGNS:</strong>
                  <p>
                    Neither Party shall assign its rights or obligations under
                    this Agreement without the prior written permission of the
                    other party and any attempt to do so shall be void. Any such
                    assignment consented to by the Parties shall be confirmed
                    vide written confirmation signed by Parties including
                    assignee to the said effect.
                  </p>
                </li>

                <li>
                  <strong>COUNTERPARTS:</strong>
                  <p>
                    This Agreement may be executed in one or more counterparts,
                    each of which will be deemed to be an original and all of
                    which when taken together will constitute the same
                    agreement.
                  </p>
                </li>

                <li>
                  <strong>DEFINITIONS AND INTERPRETATION</strong>
                  <ol>
                    <li>
                      <strong>Definitions:</strong> In this Agreement, including
                      in the recitals hereof, the following words, expressions
                      and abbreviations shall have the following meanings,
                      unless the context otherwise requires:
                      <ol>
                        <li>
                          <strong>“Affiliate”</strong> means, in respect of any
                          company, any company or person who, (i) directly or
                          indirectly controls, is controlled by or is under the
                          control of, the first-mentioned company, or (ii) is
                          deemed under Applicable Law to be related corporation
                          of the first-mentioned company.
                        </li>
                        <li>
                          <strong>“Applicable Law”</strong> or{" "}
                          <strong>“Statutory Compliance” “Rules”</strong> or
                          <strong>“Regulations”</strong> or{" "}
                          <strong>“Law”</strong> shall mean any statute, law,
                          regulation ordinance, rule judgement, notification,
                          order, decree, by-law, permits, licenses, approvals,
                          consents, authorizations, government approvals,
                          directives, guidelines, requirements or other
                          governmental restrictions, or any similar form of
                          decision, or determination by, or any interpretation,
                          policy or administration, having the force of law of
                          any of the foregoing, by any regulatory authority,
                          whether in effect as of the effective date or at any
                          time after.
                        </li>
                        <li>
                          <strong>“Business Days”</strong> or{" "}
                          <strong>“Working Days”</strong> shall mean any day
                          other than Sunday or any day on which banks or customs
                          in India are generally closed for regular banking
                          business.
                        </li>
                        <li>
                          <strong>“Calendar Days”</strong> shall mean any day
                          from Sunday to Saturday.
                        </li>
                        <li>
                          <strong>“Confidential Information”</strong> means,
                          with respect to each Party, any information or trade
                          secrets, schedules, business plans including, without
                          limitation, commercial information, financial
                          projections, client information, administrative and/or
                          organizational matters of a confidential/secret nature
                          in whatever form which is acquired by, or disclosed
                          to, the other Party pursuant to this Agreement, and
                          includes any tangible or intangible non- public
                          information that is marked or otherwise designated as
                          <strong>
                            ‘confidential’, ‘proprietary’, ‘restricted’
                          </strong>
                          , or with a similar designation by the disclosing
                          Party at the time of its disclosure to the other
                          Party, or is otherwise reasonably understood to be
                          confidential by the circumstances surrounding its
                          disclosure, but excludes information which: (i) is
                          required to be disclosed in a judicial or
                          administrative proceeding, or is otherwise requested
                          or required to be disclosed pursuant to applicable law
                          or regulation, and (ii) which at the time it is so
                          acquired or disclosed, is already in the public domain
                          or becomes so other than by reason of any breach or
                          non-performance by the other Party of any of the
                          provisions of this Agreement;
                        </li>
                        <li>
                          <strong>“Force Majeure Event”</strong> includes act of
                          God, war, civil disturbance, strike, lockout, act of
                          terrorism, flood, fire, explosion, epidemic/pandemic
                          or legislation or restriction by any government or
                          other authority, or any other similar circumstance
                          beyond the control of any Party, which has the effect
                          of wholly or partially suspending the obligations
                          hereunder of the Party concerned; and
                        </li>
                        <li>
                          <strong>“Intellectual Property”</strong> means any
                          patent, copyright, trademark, trade name, service
                          mark, service name, brand mark, brand name, logo,
                          corporate name, domain name, industrial design, any
                          registrations and pending applications thereof, any
                          other intellectual property right (including without
                          limitation any know- how, business model, trade
                          secret, trade right, formula, computer program,
                          software, database and data right, information
                          sourcing & display methodologies, user interface,
                          techniques, inventions, machines, website page and
                          form, application program interface (API), algorithms,
                          internal books and manuals, project reports, pricing
                          data, source of supply, client & service provider
                          list) and any goodwill associated with the business.
                        </li>
                        <li>
                          <strong>“Banned / Restricted Products”</strong> shall
                          mean the products/goods/articles that are banned from
                          Shipping, according to the local laws, IATA, and
                          articles in the IATA dangerous & prohibited
                          commodities.
                        </li>
                        <li>
                          <strong>
                            “Consignee or End Consumer or Recipient or Receiver
                            or Buyer of the Product”
                          </strong>{" "}
                          shall mean any individual or company (not limited to
                          Proprietorship, Partnership, Pvt Ltd, Ltd Unlisted,
                          Ltd Listed, LLP, etc) who is addressed and meant to
                          receive the Shipment booked by the User.
                        </li>
                        <li>
                          <strong>
                            “Merchant” or “Customer”, “You”, “Your”, “User” and
                            “Beneficiary”
                          </strong>{" "}
                          shall mean any user of the Website who purchases any
                          Services of the Service Provider through the Website
                          or is a visitor to the Website and shall be an
                          individual or Proprietor or Partnership firm or a
                          corporate organization in form, not limited to, viz.,
                          Pvt Ltd, Ltd Unlisted, Ltd Listed, LLP. A Customer may
                          or may not be the Consignor.
                        </li>
                        <li>
                          <strong>“Services”</strong> shall mean available
                          services on the Website and shall include all kinds of
                          logistics services related to storage, management,
                          shipping, transportation, distribution of products,
                          made available by the Service Provider.
                        </li>
                        <li>
                          <strong>“Shipment”</strong> shall mean the movement or
                          transportation of goods (parcels or cargoes) or
                          documents in bulk or in one or more packages as
                          desired by the User, through the Service Provider.
                        </li>
                        <li>
                          <strong>“Forward Shipment”</strong> shall mean any
                          shipment picked by the Service Provider from the
                          User’s location to be delivered to the End Consumer.
                        </li>
                        <li>
                          <strong>“Service Provider”</strong> means the provider
                          of the Services on Shippigo Website. The Service
                          Provider shall be any company (registered with
                          Shippigo to list their Services on Website) or
                          Shippigo may provide such Services directly through
                          its owned or contracted partners.
                        </li>
                        <li>
                          <strong>“SHIPPIGO Policies”</strong> shall mean
                          Privacy Policy & Terms of Use (including Terms &
                          Conditions), Copyright Policy, Banned Products List,
                          which are either attached to this page or are part of
                          Shippigo Website & such other policies (including any
                          amendments thereof) which Shippigo may issue and make
                          applicable on the Customer & the Visitor from time to
                          time which are applicable to the Customer & the
                          Visitor.
                        </li>
                        <li>
                          <strong>“Proforma Invoice”</strong> shall mean the
                          invoice that is raised by Shippigo on the Customer
                          against the confirmed booking.
                        </li>
                        <li>
                          <strong>
                            “User Account” or “Admin panel” or “Dashboard” or
                            “Account Panel” or “Login Panel”
                          </strong>{" "}
                          shall mean a web page / Account on the Website
                          provided by Shippigo to the User with a unique login
                          id and password to update the Service status, on the
                          Website.
                        </li>
                        <li>
                          <strong>“Service Fee or Service Price”</strong> shall
                          mean the total cost paid by User, which includes,
                          Price paid for the Services at the time of booking and
                          any future consequential or concurrent charges arising
                          due to reasons not limited to Shipment Return to
                          Origin, Abandonment or Non-Abandonment of Cargo,
                          Clearance Charges, Service Provider Charges, Shipping
                          Company Charges, Address Correction Charges, Duty
                          Payable & its Service Charges, etc.
                        </li>
                        <li>
                          <strong>“Shipper or Consignor”</strong> shall mean any
                          individual or company (not limited to Proprietorship,
                          Partnership, Pvt Ltd, Ltd Unlisted, Ltd Listed, LLP,
                          etc.) who is meant to ship the parcel or document or
                          cargo to the Consignee through the Service Provider.
                        </li>
                        <li>
                          “Shipping Company” or “Courier Company” or “Logistics
                          Company” or “Supply Chain Company” shall mean any
                          logistics company providing any services not limited
                          to those as mentioned in point number 24.1.11 through
                          any self-owned or contracted Transportation vehicle
                          (viz., ship, airplane, any multi-wheeled surface
                          commercial vehicle.
                        </li>
                        <li>
                          <strong>"Shipping Document”</strong> shall mean
                          documents required by the Service Provider to fulfil
                          confirmed Services and is not limited to mentioned
                          documents. INVOICE / PACKING LIST with total listing
                          of each product in the package with count and what it
                          is made up of (eg: For Shirt: The Shipper to mention,
                          whether, Gents / Ladies and the composition of the
                          product, i.e., made up of 100% polyester or 50% cotton
                          / 50 % polyester; Artificial Necklace: Made up of
                          metal with plastic / glass beads), Authority letter
                          from the Shipper On letter head with sign, stamp &
                          telephone number (if organization) and with sign,
                          address and telephone number (if individual) KYC (as
                          per details required by Service Provider) to fulfill
                          the Shipment, Indemnity letter (as per details
                          required by Service Provider from time to time)
                        </li>
                        <li>
                          <strong>“Transit Time”</strong> shall mean the time
                          taken by the Service Provider towards fulfilment of
                          Services (from the time of pick-up of Shipment) with
                          respect to confirmed order placed through the Website,
                          excepting the time taken for clearance (not limited to
                          Customs / Excise / any Statutory body) or
                          documentation required at city / state / foreign shore
                          borders or other events beyond control.
                        </li>
                        <li>
                          <strong>“Transportation Mode”</strong> shall mean only
                          a single mode of transport or a combination or
                          different modes of transport for the Shipment and
                          shall include transportation through air, rail, ocean
                          & road transportation.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>Interpretation:</strong> Unless the context of
                      this Agreement otherwise requires:
                      <ol>
                        <li>
                          heading and bold typeface are only for convenience and
                          shall be ignored for the purpose of interpretation.
                        </li>
                        <li>
                          other terms may be defined elsewhere in the text of
                          this Agreement and, unless otherwise indicated, shall
                          have such meaning throughout this Agreement.
                        </li>
                        <li>
                          references to this Agreement shall be deemed to
                          include any amendments or modifications to this
                          Agreement, as the case may be.
                        </li>
                        <li>
                          the terms{" "}
                          <strong>
                            “hereof", “herein”, “hereby”, “hereto”
                          </strong>{" "}
                          and derivative or similar words refer to this entire
                          Agreement or specified Clauses of this Agreement, as
                          the case may be.
                        </li>
                        <li>
                          references to a particular section, clause, paragraph,
                          sub-paragraph, or schedule, exhibit or annexure shall
                          be a reference to that section, clause, paragraph,
                          sub- paragraph or schedule, exhibit or annexure in or
                          to this Agreement.
                        </li>
                        <li>
                          reference to any legislation or Applicable Law or to
                          any provision thereof shall include references to any
                          such law as it may, after the date hereof, from time
                          to time, be amended, supplemented, or re-enacted, and
                          any reference to statutory provision shall include any
                          subordinate legislation made from time to time under
                          that provision.
                        </li>
                        <li>
                          a provision of this Agreement must not be interpreted
                          against any Party solely on the ground that the Party
                          was responsible for the preparation of this Agreement
                          or that provision, and the doctrine of contra
                          proferentem does not apply vis-à-vis this Agreement.
                        </li>
                        <li>
                          references in the singular shall include references in
                          the plural and vice versa; and
                        </li>
                        <li>
                          references to the word <strong>“include”</strong>{" "}
                          shall be construed without limitation.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </Text>

          <hr />

          <SubHeading>
            <h2 className={styles.subHeading}>
              ANNEXURE A - Shippigo Service Specifications
            </h2>
          </SubHeading>
          <Text>
            <div>
              <ol>
                <li>
                  <strong>Scope of Services</strong>
                  <ol>
                    <li>
                      Shippigo is the author and owner of its logistics
                      software, hereinafter to be referred as{" "}
                      <strong>“Shippigo”</strong>, providing its Users an
                      automated shipping panel services integrated with the
                      courier partners. The User agrees that Shippigo has no
                      role and responsibility in the actual delivery and
                      shipment of the product unless specifically mentioned, and
                      Shippigo provides a platform, for the User to avail
                      shipping services.
                    </li>
                    <li>
                      The User agrees that the shipments shall be picked up by
                      Shippigo’s logistics partner from the Users’ locations as
                      communicated to Shippigo at the time of your sign-up
                      and/or at the time of shipment booking.
                    </li>
                    <li>
                      The tracking number and logistics partner would be
                      assigned by an automated process based on the pickup and
                      delivery pin code and type of shipment.
                    </li>
                    <li>
                      Users shall provide/display prominently on package the
                      shipping label having full details of the order number,
                      consignee details, product details, return address i.e.,
                      the shipping address and the gross value and collectable
                      value (net value) to be collected in case of COD (Cash on
                      Delivery) shipments. The Shippigo backend panel platform
                      from Shippigo shall enable the User to take a print of the
                      shipping label with all the details and the same shall be
                      appropriately pasted on the package before the handover to
                      the logistics partner happens. The User shall ensure and
                      be responsible to ensure that the label is pasted on the
                      continuous & non-joint side of the package and the label
                      printout is of high quality to be readable by any scanning
                      device used by the Logistics Company.
                    </li>
                    <li>
                      User shall agree that the shipment to be handed over to
                      the logistic partner on the behalf of Shippigo is in a
                      User’s company branded (trademark) tamper proof packing
                      and sealed with User’s company branded adhesive sealing
                      tape along with the COD order form pasted on the shipment.
                    </li>
                    <li>
                      The User will be solely responsible to comply with all
                      Applicable Law (State and Central Laws/Statutes) in
                      relation to booking and sale of the shipments carried and
                      delivered by the logistics partners of Shippigo in
                      pursuance of this Agreement.
                    </li>
                    <li>
                      It is expressly understood by the Parties that Shippigo is
                      a mere service provider to the User and not in any other
                      capacity whatsoever it may be called. It is further agreed
                      to by the Parties that Shippigo is not performing any
                      activity or job or providing service on behalf of the User
                      which is tantamount to seller or retailer and or
                      stockiest/distributor. The complete activity performed by
                      Shippigo under this Agreement is based on specific
                      instructions given by the User as part of the scope
                      defined and from time to time.
                    </li>
                    <li>
                      Shippigo reserves the right to provide web based (online)
                      tracking solutions for all shipments through its logistics
                      partners.
                    </li>
                    <li>
                      You agree that Shippigo’s logistics partner, at the time
                      of receiving the shipments from User, will only use ‘Air
                      Waybill’ provided to the User by Shippigo through its
                      logistics management software, Shippigo. It is always
                      agreed between the Parties hereto that for Shippigo and
                      its logistics partner, the ‘Consignor/ Shipper’ in the
                      ‘Air Waybill’ shall be the User who is shipping the goods.
                      It is clearly understood that Shippigo’s liability, if
                      any, and to the extent agreed herein, shall extend only to
                      User. The User shall be fully liable to its
                      customers/vendors and neither Shippigo nor any of their
                      logistics partner, shall have any direct or indirect
                      connection/ relationship or responsibility/obligation to
                      User’s customers/vendors, in any manner whatsoever.
                    </li>
                    <li>
                      User must ensure security of all shipments which have been
                      picked up from its customers by Shippigo’s logistics
                      partners as per Shippigo’s security procedures. User
                      confirms that the User is fully aware of the items
                      prohibited and/or restricted on Shippigo and/or Shippigo’s
                      logistics partner network and/or IATA norms and/or norms,
                      regulations, rules of various international, national
                      local statutory & industry body for carriage and
                      undertakes that no such prohibited and/or restricted items
                      of shipment shall be handed over to Shippigo’s logistics
                      partners for carriage by its customers.
                    </li>
                    <li>
                      User hereby agrees that it shall:
                      <ol>
                        <li>
                          not (directly or indirectly) use Shippigo
                          Services/Shippigo platform while being in the capacity
                          of a reseller, OTC (over the counter) or franchise of
                          any courier/logistics company, including and not
                          limited to that of Blue Dart, FedEx, Delhivery, Ecom
                          Express, Ekart, Xpressbees, Aramex, DHL, UPS, TNT,
                          SpotOn, Gati, Safexpress, TCI, Pidge, Loadshare,
                          Dunzo, Borzo, Zomato, Swiggy, Shree Maruti Courier,
                          Tirupati Courier, Vichare Courier, DTDC, Shadowfax,
                          Amazon Shipping.
                        </li>
                        <li>
                          not hold Shippigo responsible and shall not raise any
                          claim on Shippigo and/or the Service Provider where a
                          carrier (who has an existing contract with the User
                          for same scope of service and business segment) has
                          requested Shippigo to block the User from using
                          services of the carrier through Shippigo.
                        </li>
                        <li>
                          The User understands that Shippigo is not authorized
                          to solicit existing active customers of the Service
                          Provider.
                        </li>
                      </ol>
                      <p>
                        In the event Shippigo believes that you have breached
                        any of the above provision, then Shippigo would
                        inter-alia have the right to deactivate the Shippigo
                        account, retain the custody of your shipments and to
                        levy damages/charges (along with the applicable GST
                        amount and freight charges) of Rs. 10,00,00/- (Rupees
                        One Lakh only) or of such other amount as decided by
                        Shippigo in its sole discretion.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Obligation of the Merchant / User</strong>
                  <ol>
                    <li>
                      The Merchant agrees to ensure that they shall add
                      authorized representatives of their company to use the
                      Shippigo portal or process any shipment booking and/or
                      cancellation and/or return and/or COD adjustment and/or
                      wallet charge.
                    </li>
                    <li>
                      The User agrees that it shall not raise any claims of
                      refund for bookings done through the user email ids added
                      to their account and indemnifies Shippigo and/or the
                      Service Provider from any claim arising due to
                      unauthorized use of their Shippigo account.
                    </li>
                    <li>
                      The User agrees to ensure that each of the users of
                      Shippigo invited by them frequently change their passwords
                      to the login panel and secure them without sharing their
                      credentials with anyone. Shippigo or the Service Provider
                      shall not be liable for any breach by users of the
                      Merchant.
                    </li>
                    <li>
                      The User agrees and acknowledges that different service
                      type permits booking of different number of packages
                      within a shipment. The User agrees to book and handover
                      only a single package per shipment, for all Business to
                      Consumer <strong>(“B2C”)</strong> shipments booked on
                      Express or Surface (light or heavy weight) or Reverse
                      shipments or Time Definite Delivery{" "}
                      <strong>(“TDD”)</strong> shipments or Day Definite
                      Delivery <strong>(“DDD”)</strong> shipments (TDD and DDD
                      together known as
                      <strong>“Priority Service”</strong>) and agrees to waive
                      off any right to claim arising due to loss or damage to
                      the multi package shipment handed over to the Service
                      Provider and indemnify Shippigo and the Service Provide
                      against any claim arising towards the shipment. Some
                      carriers permit multiple packages within a shipment for
                      Business to Business
                      <strong>(“B2B”)</strong> shipment delivery and the User
                      agrees to check with the support team or sales contact
                      point, before booking any multiple package shipments.
                    </li>
                    <li>
                      In most shipments, the pick-ups are done by pick up
                      executive, who have physical limitation to pick shipments
                      up to a certain weight. In majority of the cases, no
                      single package should weigh above 20 Kgs. Any single
                      package weighing greater than 20 kgs, a carrier may
                      require using special handling equipment to ensure the
                      shipments are successfully picked and delivered. The
                      Service Provider reserves right to reject a pick-up if
                      adequate resources are not made available to pick the
                      shipment at the origin address. The User agrees to pay any
                      additional charges levied by the carrier towards shipment
                      handling for heavy weight shipments.
                    </li>
                    <li>
                      You agree that the User shall be responsible for proper,
                      tamper proof and damage proof packing of the products.
                    </li>
                    <li>
                      You agree that you shall use good quality company branded
                      tapes, duly engraved with your trademark/name, etc. and
                      not generic tapes for the packaging/sealing of the
                      goods/shipments. In case generic (brown/plain) tapes are
                      used in the packaging/sealing of the goods/shipments,
                      Shippigo and its Logistics partner shall have no
                      responsibility of any kind, in case of
                      pilferage/damaged/alteration/tapering/leakage etc. of the
                      goods/shipments. In such a scenario, the entire
                      responsibility shall be of the User.
                    </li>
                    <li>
                      User shall be ready with the packed order when the courier
                      person comes to receive the shipment, all pick-ups should
                      be logged before the cut off time as directed by the
                      customer support team of Shippigo, and no pickup beyond
                      the cut-off time of the logistics partner shall be
                      possible. User agrees that they shall contact the Courier
                      Company personnel for the pickup arrangements.
                    </li>
                    <li>
                      The User agrees to handover only those parcels for which
                      Shipment Booking Status has been confirmed as ‘Success’
                      within the User Account or confirmed through API.
                      Generation of shipment AWB number and shipping label does
                      not mean the shipment is confirmed by the Service
                      Provider. The shipping label is generated before shipment
                      confirmation by the Service Provider to aid the User on
                      better throughput and operational process efficiency.
                    </li>
                    <li>
                      The User agrees that the Service Provider reserves the
                      right to refuse any Shipment, in its sole discretion, that
                      may soil, taint, or otherwise damage other merchandise due
                      to its inadequate packaging.
                    </li>
                    <li>
                      In the event any packing service is rendered to any User,
                      such services shall be charged in addition to the Service
                      Price and the Shipment shall only be processed on the
                      payment of such additional charges.
                    </li>
                    <li>
                      User shall collect receipt(s) of the signed copy of the
                      shipping manifest; it is the proof of handover of shipment
                      to the courier companies. The User shall not raise any
                      claim or escalation for shipment picked without taking an
                      acknowledgment of the pick-up executive of the courier
                      company on the manifest with his/her name, signature, date
                      of pick-up, number of packaged picked.
                    </li>
                    <li>
                      The User understands and agrees to any special or detailed
                      inspection of Shipment whether carried out in the premises
                      of the Service Provider or at the Airport / Seaport /
                      Railway station or Yard or State Border (of origin or
                      destination) by the Shipping Line or the government
                      authority or the port authority. The User agrees that it
                      shall not claim from SHIPPIGO or its Service Provider any
                      additional cost incurred towards or arising out of
                      inspection of the Shipment. The User acknowledges and
                      agrees that they shall be liable for checking the
                      contents, packing, sealing, addressing, or labelling of
                      the Shipment, the shipping instruction or waybill.
                    </li>
                    <li>
                      User shall strictly only use the automated system for
                      generating the pickup and move the shipment only on the
                      Airway Bill number generated from the Shippigo
                      administration panel provided during signup by the User
                      for shipping services. If the User moves the shipment
                      through the physical shipping docket or physical airway
                      bill number – then damages of INR. 10000/- (Indian Rupees
                      Ten Thousand) only shall be charged per airway bill number
                      issued. User agrees to ensure that the shipping label
                      provided by Shippigo is properly pasted on the flat
                      surface without any fold, where the logistics carrier can
                      scan the barcode of the AWB number at the time of pick up.
                      In addition, User shall not book/ship two or more
                      shipments against a single AWB number, and any breach of
                      this condition by the User (whether intentional breach or
                      not) shall give right to Shippigo to claim the concerned
                      expenses (including the freight amount of all the
                      shipments) and liquidated damages of upto Rs. 25,000/- per
                      incident/shipment (and applicable GST amount) from the
                      User. Neither Shippigo nor its logistics partner shall be
                      liable for any claims (not limited to lost & damage)
                      raised against such shipments.
                    </li>
                    <li>
                      User should properly paste and insert the tax invoice, in
                      and on the package.
                    </li>
                    <li>
                      User shall agree that the Service is only for locations
                      already registered on User’s account panel i.e., the
                      orders will be picked by the courier companies from only
                      such locations which have been registered by the User.
                    </li>
                    <li>
                      User agrees to ensure that the packages are handed over to
                      the Service Partner from the origin address details
                      provided at the time of booking. In case the packages are
                      handed over from any other address, other than the booking
                      origin address, then damages of INR 10000/- (Indian Rupees
                      Ten Thousand) and applicable GST amount shall be charged
                      per airway bill number issued. Neither Shippigo nor the
                      Logistics Service Provider shall be liable for any claims
                      raised against such shipments.
                    </li>
                    <li>
                      User agrees to ensure that the shipments are scanned
                      within the origin address of the User’s pick-up address
                      through either the handled device or the mobile carried by
                      the pick-up executive of the logistics service provider at
                      the time of pick up. No shipment shall be handed over to
                      the pick-up executive without scanning by the executive
                      for whatsoever reason. Neither Shippigo nor its logistics
                      partner shall be liable for any claims raised against
                      shipment picked without scanning within the shipper’s
                      premises.
                    </li>
                    <li>
                      User agrees to follow below process with respect to
                      manifest acknowledgment by pick-up executive of the
                      logistics service partner at the time of pick up:
                      <ol>
                        <li>
                          It shall handover shipments only post acknowledgment
                          provided by the pick-up executive on the manifest at
                          the time of pick-up.
                        </li>
                        <li>
                          It waives of all rights to claim for any shipment that
                          was handed over without acknowledgement on the
                          manifest.
                        </li>
                        <li>
                          It shall strike off all unpicked shipments from the
                          manifest and take an acknowledgment counter sign
                          against the striked shipments.
                        </li>
                        <li>
                          Ensure all pages of the manifest are signed for
                          acknowledgment and pick-up date is mentioned by the
                          pick- up executive of the logistics service partner.
                        </li>
                        <li>
                          Ensure the last page of the manifest is acknowledged
                          by the pick-up executive with below information:
                          <ol>
                            <li>Full Name of the pick-up executive</li>
                            <li>Date of pick-up</li>
                            <li>Signature of the pick-up executive</li>
                            <li>Total count of shipments picked for the day</li>
                            <li>Mobile number of the pick-up executive</li>
                            <li>Logistics Service Provider Name</li>
                            <li>Remark, if any</li>
                          </ol>
                        </li>
                        <li>
                          Ensure all the manifest copies are saved and recorded
                          and made available to the Service Provider and/or
                          Shippigo at any time on demand.
                        </li>
                      </ol>
                    </li>
                    <li>
                      User agrees to ensure that all the shipment shall be
                      always packaged under Closed Circuit Television (CCTV)
                      camera and share any part of the CCTV video of the
                      packaging of products with the Service Provider and/or
                      Shippigo on demand to facilitate any investigation.
                    </li>
                    <li>
                      User agrees to ensure that the loading area (also known as
                      shipment handover area within the User’s warehouse) always
                      has a CCTV camera and the User shall share any part of the
                      CCTV video of the shipment pick-up for a date with the
                      Service Provider and/or Shippigo on demand to facilitate
                      any investigation.
                    </li>
                    <li>
                      User agrees to track each shipment on a continuous basis
                      and raise any escalation within 48 hours of shipment
                      pick-up, where tracking is not updated to shipment picked
                      status and waives off all right to claim if not
                      highlighted to Shippigo within the escalation timeline.
                      The User agrees that neither Shippigo, nor the Service
                      Provider shall be liable towards any such shipment not
                      escalated within 48 hours of pick-up. The User agrees to
                      share below accompanying details and information at the
                      time of raising escalation to admit for investigation:
                      <ol>
                        <li>
                          Scanned copy of acknowledged manifest for the day of
                          the respective carrier
                        </li>
                        <li>
                          All related information pertaining to the shipment,
                          whose tracking is not updated.
                        </li>
                        <li>
                          Images of the product and packaging of the shipment,
                          whose tracking is not updated.
                        </li>
                        <li>
                          Excel or CSV data of all shipments picked on that day
                          by the carrier
                        </li>
                        <li>
                          Any other detail as desired by the Service Provider
                          and/or Shippigo to aid investigation.
                        </li>
                      </ol>
                    </li>
                    <li>
                      User shall agree that in case of a reverse pick up of
                      orders (only national orders) requested by the User, it
                      shall be the responsible to pay reverse pick-up charges as
                      mentioned in the proposal.
                    </li>
                    <li>
                      User agrees to pay Shippigo any charges arising due to
                      shipment returning to the origin / designated return
                      address (RTO, i.e., address from where shipment was picked
                      up or an address designated to receive undelivered return
                      shipments) due to failures arising for and not limited to
                      below reasons:
                      <ol>
                        <li>
                          Failure on part of the end customer to pay cash to the
                          delivery executive of the courier company against the
                          COD value mentioned by the User at the time of
                          shipment booking as per the COD order form, and/or
                        </li>
                        <li>
                          Failed delivery due to end customer non-availability
                          at the destination address at the time of delivery,
                          and/or
                        </li>
                        <li>
                          Failed delivery by the courier company due to
                          non-acknowledgement of shipment receipt (i.e., Proof
                          of Delivery – POD) by the end consumer at the time of
                          delivery
                        </li>
                        <li>
                          Failed delivery arising due to non-attendance of any
                          form of communication (not limited to
                          telephonic/email/SMS/WhatsApp) sent by the Courier
                          Company and/or Shippigo
                        </li>
                        <li>
                          Failure on part of the User to respond on time and/or
                          failure to respond on the Non-Delivery Report (NDR)
                          with next course of actions against the shipment from
                          their Shippigo account panel.
                        </li>
                        <li>
                          Failed delivery arising due to local city
                          restrictions, limitations, embargo, temporary and/or
                          permanent non serviceability post shipment pick up,
                          incorrect address or pin code, etc.
                        </li>
                        <li>
                          Failure of the end customer or the User to pay any
                          international charges such as customs, or any such
                          similar levy/duty, and/or any destination charges
                          levied by the airline/courier company/statutory/port
                          and/or any advancement charges levied on
                          duties/taxes/VAT paid by the courier company on behalf
                          of the User / End Consumer or any other reason
                          whatsoever,
                        </li>
                      </ol>
                    </li>
                    <li>
                      It is the User’s responsibility to acknowledge delivered,
                      RTO delivered and/or Reverse delivered from the account
                      panel and validate with their end consumer on the
                      successful delivery of the shipment or pick-up of reverse
                      shipment and validate with the designated RTO address and
                      Reverse Delivery Address on successful delivery of
                      respective RTO or Reverse shipment.
                    </li>
                    <li>
                      The User agrees to raise any shipment non-delivery/Proof
                      of Delivery (POD) claim within 24 hours of tracking status
                      being marked delivered or return to origin (RTO) delivered
                      or reverse delivered and waives of any right to raise any
                      request for such claim after 24 hours.
                    </li>
                    <li>
                      User hereby agrees that it will not book / handover any
                      good/shipment which is banned, restricted, illegal,
                      prohibited, stolen or infringing of any third party
                      rights, or which contains any cash, jewellery (excluding
                      artificial jewellery), gold, silver, diamond, platinum,
                      precious metals, precious stones, currency, bullion,
                      letters and financial and security instruments, or any
                      reactive, hazardous or dangerous, perishable items/goods
                      which are in breach of any applicable law or of any
                      packaging/transportation guidelines of the concerned
                      courier partner; in which cases Shippigo shall not be
                      liable for the delivery of any such products. Without
                      prejudice to the generality of the aforesaid, an
                      indicative list of the dangerous and restrictive goods is
                      given at Annexure-B. It is the User’s responsibility to
                      check on courier company’s website, IATA’s guideline or
                      restricted and banned list, Local and International
                      Customs and Country regulations.
                    </li>
                    <li>
                      In the event User hands over or provides the aforesaid
                      goods/shipments to Shippigo/its courier partner, then
                      Shippigo/its courier partner shall not be responsible and
                      liable for any loss, damage, theft, or misappropriation of
                      such products even if service provider or delivery
                      personnel has the knowledge of the same and even if such
                      loss, damage, theft or misappropriation is caused due to
                      any reason attributable to service provider or delivery
                      personnel. The User undertakes that in the event any
                      article/good/shipment booked/handed over by it falls
                      within the category of the banned/illegal items or those
                      described above (including reactive, hazardous and
                      dangerous goods which are in breach of any applicable law
                      or of any packaging/transportation guidelines of the
                      concerned courier partner), then the User agrees to
                      indemnify Shippigo and its courier partner for any and all
                      issues, losses and damages arising pursuant thereto. In
                      addition, Shippigo would inter- alia have the right to
                      retain the custody of such shipments (including opening
                      and inspecting of shipments) and to levy damages/charges
                      (along with the applicable GST amount and freight charges)
                      of Rs. 10,00,000/- (Rupees Ten Lacs only) per shipment or
                      of such other amount as decided by Shippigo in its sole
                      discretion.
                    </li>
                    <li>
                      It is expressly understood and acknowledged by the User
                      that Shippigo is merely a technology company aggregating
                      logistics services from different courier & supply chain
                      companies for the User to avail services of these
                      Logistics Companies through Shippigo and as such Shippigo
                      & the Courier Company shall not be liable whatsoever for
                      any sale related issue (including but not limited to Taxes
                      thereon), third party actions, claims of any type or
                      nature, issues or disputes pertaining to COD including e -
                      COD or other refunds or pertaining to defective quality,
                      quantity, specifications, and aesthetics of the product
                      being sold by the User to the consignee (end consumer). It
                      is further clarified and agreed by the Parties that
                      Shippigo and the Courier Company is NOT performing any
                      activity or job of providing service on behalf of which
                      tantamount to them being a Seller, Buyer, or Retailer and
                      or Stockist, Agent (including mercantile agent or
                      collection agent), Distributor or Dealer OR by any like
                      names and conventions related to Trade or business etc.
                      The complete activity performed by the Courier Company
                      under this Agreement is as a mere “Logistics Service
                      Provider” and that by Shippigo is a mere “Technology
                      Enabled Aggregator of Logistics Services” and based on
                      specific instructions given by the User as part of the
                      services scope and procedures defined and from time to
                      time.
                    </li>
                    <li>
                      In addition, you shall not handover counterfeit or fraud
                      products/shipments to Shippigo/its courier partner,
                      failure of which will attract the consequences mentioned
                      in Annexure- B. Further, the consequences of shipping non-
                      essential items in Government prohibited areas and
                      disputed shipments/cases have been specified in
                      Annexure-B.
                    </li>
                    <li>
                      Shipments which cross national borders/ international
                      shipments may be subject to customs clearance, in the
                      destination country prior to delivery to the User. The
                      User/customer is responsible for making sure goods shipped
                      are acceptable for entry into the destination country. All
                      charges for shipment to and return from countries where
                      entry is not permitted shall be the User’s responsibility.
                      User also understands that POD may not be available for
                      all the cross-national borders/ international shipments as
                      the same may be routed by the courier partner through
                      local post (for e.g., shipments to U.S.A by Aramex are
                      delivered through local post (USPS), for which POD is not
                      available). Hence, Shippigo shall not be held liable for
                      any dispute in relation to the aspects mentioned in this
                      Clause.
                    </li>
                    <li>
                      User understands, agrees, and acknowledges that Shippigo
                      through its logistics partners is a mere bailee of the
                      goods/products, cash and is not an insurer of the same.
                      User hereby expressly and specifically waives all its
                      rights and claims against Shippigo and its logistics
                      partners arising out of or in relation to the principles
                      of insurance. The User agrees to be responsible for
                      procuring transit insurance for the shipments booked on
                      Shippigo and facilitate a copy of the same when demanded
                      by Shippigo or the Service Provider.
                    </li>
                    <li>
                      Insurance: To procure and maintain an insurance to protect
                      the Shipments during the performance of the Services. The
                      User’s insurance shall have all risk coverage protection
                      against all risks at every moment during the transit and
                      storage of the Shipments. The User in this act frees
                      SHIPPIGO or the Service Provider or the Shipping Company
                      from all responsibility for any incident or misfortune
                      occurred during the delivery of the Shipment from its
                      origin point to its final destination.
                    </li>
                    <li>
                      In case of damaged/pilferaged/tempered/pressed/leaked
                      shipment, receiver shall mention negative remarks on POD
                      copy to get claim for the shipment. In the absence of any
                      negative remarks on POD copy clearly stating such
                      damage/pilferage/tampering/pressing/leakage, no claim
                      shall be entertained by Shippigo at any point of time.
                    </li>
                    <li>
                      Claims for any kind of damage/pilferage/tampering/leakage
                      of the booked articles/goods/shipment shall be entertained
                      only if the outer packaging done by the shipper is
                      damaged/altered/tampered. However, if the outer packaging
                      done by the shipper is intact and not tampered with, in
                      such a case, no claim(s) for any
                      damage/pilferage/tampering/leakage shall be entertained by
                      Shippigo.
                    </li>
                    <li>
                      Shippigo shall not entertain any dispute(s) regarding
                      damage/pilferage/tampering/leakage/non- receipt of
                      delivery/fake delivery after a period of 24 hours from the
                      receipt/delivery of the said article/goods/shipment.
                      Further, Shippigo shall not entertain any request for
                      providing the POD of a shipment, after a period of 48
                      hours from the delivery/RTO of the shipment.
                    </li>
                    <li>
                      The User shall ensure that the correct and complete
                      description of the destination/address as well as all the
                      relevant information/details and documents (including but
                      not limited to the e-way bill number and valid GST
                      invoice) are mentioned/provided by the User while
                      booking/handing over a shipment. In case any
                      incomplete/incorrect information or documents are provided
                      by the User, the shipment may be returned from origin and
                      the shipping charges (both forward and RTO charges) shall
                      be levied, in addition to any damages/taxes imposed by the
                      statutory authorities, if any, in the transit of such
                      shipment. Such charges shall be irreversible and no claim
                      for the return of such charges shall be entertained by
                      Shippigo. Further, in case of breach of this clause,
                      Shippigo would inter- alia have the right to levy
                      damages/charges (along with the applicable GST amount) on
                      the User of Rs. 1,00,000/- (Rupees One Lac only) per
                      shipment or of such other amount as decided by Shippigo in
                      its sole discretion.
                    </li>
                    <li>
                      The User agrees and accepts to provide SHIPPIGO with a
                      general lien and pledge on all their Shipments in
                      SHIPPIGO’s or its Logistics Service Partner possession at
                      any point in time and it gives SHIPPIGO the right to sell
                      the contents and retain the proceeds of sale in settlement
                      of any amounts that the User may owe Shippigo.
                    </li>
                    <li>
                      The User shall ensure that the Invoice affixed on the
                      shipment bears complete requisite order details as is
                      required under GST law towards the sale of the shipment
                      including sale and sellers’ complete details and GSTIN;
                      Collectable Value in case of COD (Cash on Delivery)
                      shipment with routing Delivery Centre code and bar-coded
                      Air Waybill Number assigned by Shippigo’s Courier Company.
                      In no case Shippigo or the Courier Company shall be liable
                      to verify the content of the Invoice and/ or correctness
                      of the particulars specified therein. Further the Invoice
                      and/or packaging of the shipment shall also specifically
                      provide that for any return, COD including E - COD or
                      other refunds and/or any sale related issues concerning
                      the shipment including but not limited to the contents,
                      quality, specifications, appearance or aesthetics, or
                      quantity of the shipment, the Consignee (end consumer) is
                      required to contact the User only and Shippigo and/or the
                      Courier Company shall bear no liability in respect of the
                      same;
                    </li>
                    <li>
                      The User hereby agrees that, if the value of the
                      good(s)/shipment(s) is greater than or equal to Rs.
                      50,000/- and where the requirement of e-way bill is
                      mandatory, the User shall provide a valid e-way bill
                      (during the case of RTO) to SHIPPIGO, within 7 days from
                      the date of the good(s)/shipment(s) being marked as 'RTO
                      Initiated' on User’s dashboard. In case the User fails to
                      provide said e-way bill within the stipulated time, then
                      the concerned good(s)/shipment(s) may be marked as
                      'Disposed', and SHIPPIGO and/or it’s courier partner(s)
                      shall not be held liable for any liability in relation
                      thereto.
                    </li>
                    <li>
                      The User shall ensure that orders assigned to Shippigo’s
                      Courier Company including in respect of COD/ E-COD order
                      and/ or Collectable Value thereof is in conformity with
                      all applicable laws including but not limited to tax laws,
                      RBI norms, Legal Metrology Act etc.
                    </li>
                    <li>
                      In respect of E – COD option availed by consignee (end
                      consumer), the role of the courier company is limited to
                      facilitation of POS Device as a courier service provider
                      to the consignee (end consumer) for making payment
                      therefrom to Shippigo and to the User against delivery of
                      COD shipment and it is further acknowledged, represented
                      and warranted by the User that neither Shippigo nor the
                      courier company nor payment transit gateway operator shall
                      be liable or responsible for any fraudulent, dubious,
                      suspicious or unauthorized transaction being conducted by
                      the consignee therefrom. Shippigo and the courier company
                      is in no case a beneficiary or merchant to the said
                      payment transaction nor is an operator, administrator nor
                      manager of the payment transit gateway which is solely and
                      exclusively being managed and operated by third party
                      service provider.
                    </li>
                    <li>
                      It shall ensure that shipment handed over to the Courier
                      Company is adequately and suitably packed considering the
                      nature of the shipment in a tamper proof carriage worthy
                      packaging, amenable to reasonable handling during transit
                      and is adequate to withstand the normal rigors, jerks, and
                      jolts of transportation hazards or the like encountered
                      during transit. In any case Shippigo and/or the Courier
                      Company shall not be liable for any damage/ wrinkle caused
                      to the shipment due to insufficient/ unsuitable or
                      inadequate packaging thereof. Further it is acknowledged
                      by the User that Shippigo and the Courier Company shall
                      not be liable for any damage to the shipment in case where
                      only external packaging is damaged, but the contents of
                      the shipment is in good order.
                    </li>
                    <li>
                      CONSIGNEE (End Consumer) GRIEVANCE: For consignee related
                      grievances or disputes pertaining to the shipment or its
                      sale including but not limited to its return, defects,
                      quality, quantity, aesthetics, COD, or other refund
                      related or any other sale related issue concerning the
                      shipment, the User shall:
                      <ol>
                        <li>
                          ensure to provide contact number along with the name
                          and designation of the concerned grievance redressal
                          officer of the User on its website, packaging, or
                          invoice to redress the grievance of the consignee
                          promptly; and
                        </li>
                        <li>
                          In case where any such dispute or grievance is
                          conveyed to Shippigo by the courier company based on
                          end consumer grievance, and is communicated by
                          Shippigo to the User, User shall ensure to resolve the
                          same promptly with the consignee. The User shall on
                          demand indemnify, compensate, defend, and hold
                          Shippigo and the Courier Company harmless against any
                          actions and disputes arising against Shippigo and/or
                          Courier Company in connection with the same.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>FEES, MODIFICATION IN FEES & COD TERMS</strong>
                  <ol>
                    <li>
                      The User hereby agrees to pay all any applicable charges
                      arising due to use of its Shippigo Services, not limited
                      towards subscription fees, shipping rate, software as a
                      service <strong>(“SAAS”)</strong> charge, charges for any
                      storage if any, shipment return charges if application,
                      shipment handling charges, as per the current prevailing
                      rate mentioned in the Users admin panel or the latest
                      rates communicated or announced or any rates / charges
                      communicated during the course of the shipment delivery.
                    </li>
                    <li>
                      The User hereby agrees to pay Shippigo or directly to
                      respective company as advised by Shippigo and indemnify
                      Shippigo and the Service Provider from all and any
                      applicable charges arising due its shipment, not limited
                      to any additional shipping charges, duties, taxes, VAT,
                      demurrage, detention, disposal, abandonment, cess,
                      carting, loading, unloading, packing, unpacking,
                      inspection, documentation, penalties, fines, charges
                      arising due to statutory or security investigation,
                      disposition, carrier claims, port authority claims,
                      statutory claims, service provider claims / charges /
                      penalties, any third party claims and any other costs and
                      any legal fees incurred by the Service Provider or
                      Shippigo.
                    </li>
                    <li>
                      Shippigo reserves the right to apply other applicable
                      charges over and above the shipping base rates and
                      Shippigo service charge like COD charges and other fees
                      are in Users admin panel and/or the rate proposal shared
                      by Shippigo sales team.
                    </li>
                    <li>
                      Shippigo has rights to make any changes in the rate
                      mentioned in Users admin panel and prevailing.
                    </li>
                    <li>
                      Goods and Service tax and other taxes, duties and VAT are
                      applicable as per country taxation law.
                    </li>
                    <li>
                      Shippigo reserves the right to levy interest of 18% per
                      annum on the entire overdue amount in all cases.
                    </li>
                    <li>
                      You agree that chargeable weight for a shipment shall be
                      higher of volumetric (Dimension) weight and dead (Actual)
                      weight of the shipment and will be charged as per the
                      details shared in the rate proposal.
                    </li>
                    <li>
                      In case the booked chargeable weight differs and is less
                      than the actual chargeable weight (captured by the
                      shipping company), then shipping charges will be revised
                      to actual chargeable weight. You will be notified
                      regarding such discrepancy in the chargeable weight (on
                      the dashboard) and will be given 7 (seven) calendar days’
                      notice to either accept or reject the updated weight. In
                      the event, you accept the updated weight the same will be
                      the billed chargeable weight and if you reject the updated
                      weight the same will be revised on acceptance of revision
                      by the courier company. Further, in case you do not accept
                      or reject the updated weight, the same will be auto
                      accepted in 7 (seven) calendar days’ time. You agree to
                      waive off all rights to raise any claim if no dispute is
                      raised by you against any shipment post the 7 (seven)
                      calendar days’ time.
                    </li>
                    <li>
                      In the event Shippigo believes that you are shipping (or
                      have shipped) goods/shipments wherein the declared weight
                      differs and is less than the actual weight, then Shippigo
                      would inter-alia have the right to retain the custody of
                      such shipments and to levy damages/charges (along with the
                      applicable GST amount) on you of Rs. 1,00,000/- (Rupees
                      One Lac only) per shipment or of such other amount as
                      decided by Shippigo in its sole discretion.
                    </li>
                    <li>
                      Remittance of COD amount:
                      <ol>
                        <li>
                          Would be made on the next working day of receipt of
                          COD amount from the Courier Company.
                        </li>
                        <li>
                          COD remittances are processed on designated working
                          days between Monday to Friday and as advised from time
                          to time. Any changes in the COD remittance frequency
                          or cycle, shall be communicated to the registered
                          email id and the same shall be applicable for your
                          account.
                        </li>
                        <li>
                          The remittance of the COD amount will be made within 8
                          working days (variations or delays could arise
                          depending on destination city, frequency of cash
                          management service for respective destination center
                          of the courier company, force majeure events,
                          technical or system breakdowns or failures for the
                          courier company or Shippigo) from the delivery date of
                          the concerned shipment.
                        </li>
                        <li>
                          However, the said COD amount will not be paid or will
                          have to refunded by the User (if already paid) for the
                          shipments which were originally booked on COD, however
                          which were subsequently modified.
                        </li>
                        <li>
                          In case the COD amount which is already remitted to
                          the User due to wrong status (delivered) updated by
                          courier partner, the same amount shall be deducted
                          from future COD payments.
                        </li>
                        <li>
                          Further, in the event Shippigo is not able to remit
                          the COD amount to the User within a period of 365 days
                          from the due date, due to any reason which is not
                          attributable to Shippigo (including incorrect bank
                          details provided by the User), then the User hereby
                          agrees to waive all its rights and claims against
                          Shippigo and its logistics partners arising out of or
                          in relation to non-payment of the COD amount and
                          Shippigo shall have an unconditional right to forfeit
                          such unclaimed COD amount after the expiry of said 365
                          days.
                        </li>
                        <li>
                          Any queries in relation to COD remittance should be
                          raised as a ticket on email address mentioned on the
                          Website.
                        </li>
                      </ol>
                    </li>
                    <li>
                      For any claims by the User the signed copy of the manifest
                      sheet of the pick-up against which the courier company has
                      received the shipment has to be submitted along with the
                      claim request. Without the signed manifest the request
                      shall not be considered valid.
                    </li>
                    <li>
                      <strong>Said To Contain Basis & Inspection:</strong> It is
                      expressly understood by and between the Parties that all
                      products agreed to be delivered by Shippigo or its
                      logistics partners are on{" "}
                      <strong>“SAID TO CONTAIN BASIS”</strong> i.e. Shippigo or
                      its logistics partners shall be under no obligation and is
                      not expected to verify the description and contents of the
                      products declared by the User on the docket and as such,
                      the User shall undertake and ensure to make proper, true,
                      fair, correct and factual declaration on the docket
                      regarding description and value of products. The User
                      indemnifies Shippigo and the Service Provider against all
                      claims arising on account of the contents of the shipment.
                      Further, Shippigo is not responsible in any way whatsoever
                      for the merchantability of the products.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>
                    TERMS OF PAYMENT FOR PREPAID & SECURED POSTPAID ACCOUNTS
                    WITH ROLLING CREDIT
                  </strong>
                  <ol>
                    <li>
                      <strong>TERMS OF PAYMENT FOR PREPAID ACCOUNTS:</strong>
                      <ol>
                        <li>
                          User shall agree to deposit an amount in their
                          respective account to use our Services as per the
                          prepaid model. This Clause 4 shall be applicable only
                          in case of pre-paid accounts.
                        </li>
                        <li>
                          User agrees to recharge their account credits by
                          adding money (through NEFT, RTGS, Credit Card, Debit
                          Card, UPI, using different wallets (eg: PayTM, Amazon
                          Pay, PhonePe, GooglePay, etc.) to the account (also
                          known as Shippigo Credit Account) and choose the
                          amount according to your business needs and you can
                          use this amount to ship through air and surface both.
                        </li>
                        <li>
                          Shippigo reserves the right to activate your account,
                          once the credit of charged amount has been made by the
                          User.
                        </li>
                        <li>
                          User shall agree that with the shipment weight, it
                          will automatically get deducted from your credit
                          weight. As per the norms of Shippigo logistics, you
                          will be charged a minimum of 0.5 kgs (or in multiples
                          for the service type used) for your air shipping and
                          either a minimum of 0.5 kgs or 1 kgs or 5 kgs or 10
                          kgs or any other minimum weight defined for a carrier
                          (or in multiples for the service type used) for your
                          surface shipping. Please note that the weight charges
                          applied by the courier companies may differ but
                          however such charges shall be adjusted in/from your
                          Shippigo Credit Account limit on your Shippigo account
                          after picking up of the shipment.
                        </li>
                        <li>
                          Shippigo shall issue an invoice which will get auto
                          adjusted (if applicable) against the credit in your
                          account as the following conditions:
                          <ol>
                            <li>
                              If the invoice amount is more than the credit in
                              your account: User shall agree that in case where
                              the invoice amount is more than the credit in your
                              account, the freight invoice will be marked as
                              unpaid, and it will constantly get reflected in
                              you panel and invoice history. If you fail to pay
                              the invoice amount, then the outstanding amount
                              shall be adjusted from the COD and/or the shipping
                              will be suspended. To continue using Shippigo
                              Services, you need to recharge your account for
                              the unpaid invoice as well the new shipping limit.
                            </li>
                            <li>
                              If the invoice amount is less than the credit in
                              your account: User shall agree that in case where
                              the invoice amount raised is less than the credit
                              in your account, the freight invoice amount will
                              be automatically adjusted from your credit (if not
                              already adjusted) and shall be marked as paid. The
                              User shall then continue using Shippigo Services
                              from the remaining credit amount. If as on the
                              date of issuance of the invoice, freight invoice
                              amount has already been adjusted from the credit
                              in your account, the invoice shall be generated
                              with marked as paid.
                            </li>
                          </ol>
                        </li>
                        <li>
                          User shall agree that it will be your responsibility
                          to verify the invoices and inform Shippigo within 5
                          (five) working days in case of any disputes regarding
                          the contents of the invoice.
                        </li>
                        <li>
                          For any claims by the User like wrong freight being
                          applied, Cash on Delivery missing, pilferage, in
                          transit damage - the signed copy of the manifest sheet
                          of the pickup against which the courier company has
                          received the shipment has to be submitted along with
                          the claim request. Without the signed manifest the
                          request shall not be considered valid.
                        </li>
                        <li>
                          If due to any reason (including but not limited to the
                          reason of weight discrepancy), the balance amount of
                          the User in the Shippigo wallet becomes negative, then
                          Shippigo shall inter-alia have the right to
                          hold/retain the COD Amounts for the shipments of the
                          User.
                        </li>
                        <li>
                          The credit balance in the Shippigo wallet shall be
                          available for booking shipments only for a period of 1
                          years from the last shipment date. In case, the User
                          does not book any shipment for a continuous period of
                          1 years, then Shippigo shall have an unconditional
                          right to forfeit such credit balance in the Shippigo
                          wallet after the expiry of 1 year from the last
                          shipment date.
                        </li>
                        <li>
                          The User can request Shippigo to refund the credit
                          balance of the wallet. Any such refund request shall
                          be subject to refund being made to the original
                          source/mode of payment, standard time taken to process
                          such refund and mandatorily providing of necessary KYC
                          documents by the User to process the refund. Further,
                          Shippigo reserves a right to: (i) deny any request to
                          refund the credit balance to a source being different
                          from the original source/mode of payment; (ii) levy a
                          surcharge (as per its sole discretion) to refund the
                          credit balance to a source being different from the
                          original source/mode of payment; and/or (iii) levy
                          appropriate damages/charges (as per its sole
                          discretion) in case Shippigo is of the opinion that
                          the wallet is being/has been used by the User for any
                          unscrupulous/illegal activities or for purposes other
                          than for payment to SHIPPIGO.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <strong>
                        TERMS OF PAYMENT FOR SECURED POSTPAID ACCOUNTS WITH
                        ROLLING CREDIT
                      </strong>
                      <ol>
                        <li>
                          This Clause shall be applicable only in case of
                          secured postpaid accounts with rolling credit, and not
                          for normal prepaid accounts.
                        </li>
                        <li>
                          User agrees to recharge their Shippigo Credit Account
                          by transferring COD receivable (Delivered but COD
                          receipt pending) amount from their account panel and
                          choose the amount according to your business needs and
                          you can use this amount to ship through air and
                          surface both.
                        </li>
                        <li>
                          Shippigo reserves the right to activate your account
                          once the shipping credit has been made by the User.
                          Thereafter, Shippigo reserves the right to grant a
                          rolling credit limit to the User based on shipment
                          shipped by User. If required, the User may increase
                          its credit limit (over and above the limit granted by
                          Shippigo) by recharging its Shippigo Credit Account.
                        </li>
                        <li>
                          Shippigo reserves the right to adjust the used credit
                          limit amount from the upcoming remittance of the User.
                        </li>
                        <li>
                          User shall agree that with the shipment weight, it
                          will automatically get deducted from your credit
                          weight. As per the norms of Shippigo logistics, you
                          will be charged a minimum of 0.5 kgs (or in multiples
                          of the service selected) for your air shipping and
                          either a minimum of 0.5 kgs or 1 kgs or 5 kgs or 10
                          kgs or any other minimum weight defined for a carrier
                          (or in multiples for the service type used) for your
                          surface shipping. Please note that the weight charges
                          applied by the courier companies may differ but
                          however such charges shall be adjusted in/from your
                          Shippigo Credit Account limit on your Shippigo account
                          after pickup of the shipment.
                        </li>
                        <li>
                          Shippigo shall issue an invoice which will get auto
                          adjusted (if applicable) against the credit in your
                          account as the following conditions:
                          <ol>
                            <li>
                              If the invoice amount is more than the credit in
                              your account: User shall agree that in case where
                              the invoice amount is more than the credit in your
                              account, the freight invoice will be marked as
                              unpaid, and it will constantly get reflected in
                              your panel and invoice history. If you fail to pay
                              the invoice amount, then the outstanding amount
                              shall be adjusted from the COD and/or then the
                              shipping will be suspended. To continue using
                              Shippigo Services, you need to recharge your
                              account for the unpaid invoice as well the new
                              shipping limit.
                            </li>
                            <li>
                              If the invoice amount is less than the credit in
                              your account: User shall agree that in case where
                              the invoice amount raised is less than the credit
                              in your account, the freight invoice amount will
                              be automatically adjusted from your credit (if not
                              already adjusted) and shall be marked as paid. The
                              User shall then continue using Shippigo Services
                              from the remaining credit amount. If as on the
                              date of issuance of the invoice, freight invoice
                              amount has already been adjusted from the credit
                              in your account, the invoice shall be generated
                              with marked as paid.
                            </li>
                          </ol>
                        </li>
                        <li>
                          User shall agree that it will be your responsibility
                          to verify the invoices and inform the Shippigo within
                          5 (five) working days in case of any disputes
                          regarding the contents of the invoice.
                        </li>
                        <li>
                          For any claims by the User like wrong freight being
                          applied, Cash on Delivery missing, pilferage, in
                          transit damage - the signed copy of the manifest sheet
                          of the pickup against which the courier company has
                          received the shipment has to be submitted along with
                          the claim request. Without the signed manifest the
                          request shall not be considered valid.
                        </li>
                        <li>
                          If due to any reason (including but not limited to the
                          reason of weight discrepancy), the balance amount of
                          the User in the Shippigo Credit Account becomes
                          negative, then Shippigo shall inter-alia have the
                          right to hold/retain the COD Amounts for the shipments
                          of the User.
                        </li>
                        <li>
                          The credit balance in the Shippigo wallet shall be
                          available for booking shipments only for a period of 1
                          year from the last shipment date. In case, the User
                          does not book any shipment for a continuous period of
                          1 year, then Shippigo shall have an unconditional
                          right to forfeit such credit balance in the Shippigo
                          Credit Account after the expiry of 1 years from the
                          last shipment date.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>
                    RETURNS / RETURN TO ORIGIN (“RTO”) OF THE PRODUCTS
                  </strong>
                  <ol>
                    <li>
                      Shippigo reserves the right to return to the User, the
                      products which are not accepted by the customer for any
                      reason whatsoever, at the location(s) as specified by the
                      User.
                    </li>
                    <li>
                      Shippigo reserves the right to apply the RTO (return to
                      origin) charges same as the agreed shipping rates.
                    </li>
                    <li>
                      User shall agree that the returns will be initiated by the
                      logistics partners for all products which are not accepted
                      by the customer or could not be delivered to the customer
                      for any reason whatsoever. You will ensure that such
                      products are accepted at the location(s) specified by you
                      and share the Airway bill number against which the
                      shipment returned to the User.
                    </li>
                    <li>
                      In case of non-acceptance of the RTO shipment by the User
                      or in case the User is not reachable for RTO shipment,
                      Shippigo reserves the right to levy suitable
                      demurrage/incidental charges for extended storage of such
                      products for any period exceeding 5 (Five) business days
                      from initiation of the returns and up to 30 (thirty) days
                      from such date. In case of non-acceptance of the products
                      beyond 30 (thirty) days, Shippigo has the right to dispose
                      such products and the User will forfeit all claims in this
                      regard towards Shippigo and, the User will be required to
                      pay charges for disposing the product, along with all
                      other charges (including demurrage/incidental charges).
                      Further in such a case, Shippigo shall inter-alia have the
                      right to: (a) retain the COD Amounts of the defaulting
                      User; and/or (b) retain the custody of the shipments of
                      the defaulting User which are in the possession of
                      Shippigo or it’s logistics partner(s); and/or (c) forfeit
                      the security deposit amount of the defaulting User (if
                      any) lying with Shippigo.
                    </li>
                    <li>
                      The User agrees that RTOs cannot be revoked in general
                      circumstances and any option to revoke RTO status of
                      shipment to deliver to the consignee (end consumer) is
                      subject to approval of the Service Provider.
                    </li>
                    <li>
                      The User agrees to check in advance with the customer
                      support team of Shippigo, on feasibility of revoking RTO
                      status of a COD marked shipment to be delivered as a COD
                      shipment. Service Provider may revoke the RTO status of a
                      COD shipment on request and may deliver the shipment as a
                      prepaid shipment. The User agrees to waive off any right
                      to claim COD of such shipment delivered as prepaid
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>REVERSE PICKUPS</strong>
                  <ol>
                    <li>
                      <strong>“Reverse Pickup”</strong> means collection of the
                      products by Shippigo’s Logistics Partner from the end
                      customer’s address as specified by the User and the
                      delivery of such products at a location mutually agreed
                      between the Parties.
                    </li>
                    <li>
                      User shall agree that in case of a reverse pick up of
                      orders, it shall be their responsibility to pay any
                      additional reverse pick up charges shared by Shippigo from
                      time to time and visible in the account panel against the
                      service plan selected by the User.
                    </li>
                    <li>
                      Shippigo and Shippigo’s logistics partners shall not be
                      responsible for verifying the contents of the products
                      handed over by the customer to its delivery personnel in
                      case of reverse pick-up service without quality check. The
                      packaging of such products shall also be the sole
                      responsibility of the customer. The packaging should be
                      good enough to ensure no damage in transit. The sole
                      responsibility of the contents of the packed consignment
                      shall lie with the end customer. Shippigo and Shippigo’s
                      logistics partners shall be, in no way, responsible for
                      any shortage or damage of such consignments unless the
                      same is caused solely due to the gross negligence of the
                      Logistics Company.
                    </li>
                    <li>
                      Shippigo’s logistics partners shall verify basic details
                      of the products as per defined parameters at the time of
                      pick up from your end consumer, in case of reverse pick-up
                      service with quality check. The responsibility of
                      providing accurate, descriptive, and vivid details is of
                      the User and the responsibility of handing over the
                      correct product along with its accompanied packaging is
                      that of the end consumer. Shippigo and its logistics
                      service provider is not responsible for the condition of
                      the product and package provided by the end consumer. The
                      packaging should be good enough to ensure no damage in
                      transit. The sole responsibility of the contents of the
                      packaged consignment shall lie with the end consumer.
                      Shippigo and Shippigo’s logistics partners shall be, in no
                      way, responsible for any shortage or damage of such
                      consignments unless the same is caused due to the gross
                      negligence of the Logistics Company.
                    </li>
                    <li>
                      Shippigo and its logistics service provider shall not be
                      responsible towards any claim for damaged / used products
                      requested for reverse pick-up, irrespective of with or
                      without quality check.
                    </li>
                    <li>
                      It is the responsibility of the User/its customer that on
                      receipt of the shipment initiated through reverse pickup,
                      share the AWB number on which the logistics partner has
                      delivered the shipment.
                    </li>
                    <li>
                      The User agrees that there shall be no claim accepted
                      towards Reverse Pickup of Damaged products from the end
                      consumer.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>INDEMNIFICATION & LIABILITY</strong>
                  <ol>
                    <li>
                      Notwithstanding anything contrary contained in this
                      Agreement, the maximum liability of Shippigo per shipment
                      will be as per the Maximum Liability Limit mentioned in
                      the pricing proposal against each courier company &
                      service type in case of a claim under this Agreement (due
                      to any reason including damage, lost, theft, etc.) by the
                      User, provided that such claim is raised by the User
                      within the timelines specified under this Agreement and,
                      in any event, either not later than thirty (30) days from
                      the shipment pick up date or twenty four (24) hours of
                      shipment delivery- failing which the User forfeits and
                      waives its rights for such claim. Any claims by the User
                      should be submitted within the specified time along with
                      the copy of the signed shipping manifest.
                    </li>
                    <li>
                      In case the maximum liability is not mentioned against any
                      carrier – service that the User has availed off, for all
                      accepted claims, Shippigo’s liability is limited to lower
                      of INR 500 or product value or COD collectable value, or
                      cost of reconstruction or Cost of replacement.
                    </li>
                    <li>
                      The User forfeits and waives off any right to claim for
                      shipment delivered to end consumer or delivered to the
                      User on clean Proof of Delivery (POD), i.e., there is no
                      remark mentioned on the POD about visible damage or
                      tampering.
                    </li>
                    <li>
                      In relation to the above, it is clarified that:
                      <ol>
                        <li>
                          In case of a claim under this Agreement by the User
                          (due to any reason including damage, lost, theft,
                          etc.), Shippigo shall only be liable to pay as per the
                          Maximum Liability Limit mentioned in the pricing
                          proposal against each courier company & service type
                          or the product value or the invoice value or the cost
                          of reconstruction or the cost of replacement or COD
                          collectable value of the shipment, whichever is less,
                          except in cases where Shippigo has received a request
                          from the User (within 7 days of the lost/damage
                          declared date) for procuring certificate of facts
                          (COF) from the concerned courier company, as per the
                          terms of the proposal and provision to facilitate or
                          non-facilitate issuance of COF by various courier
                          company. In such a case, where the courier company
                          facilitates issuance of COF, Shippigo shall only be
                          required to arrange the COF from the concerned courier
                          company and will not be liable to pay any compensation
                          to the User. Facilitating First Information Report
                          (FIR) for any lost, damage, theft, etc. is at the
                          discretion of the Service Provider and the User waives
                          off all right to claim & demand a copy of FIR, if
                          rejected by the Service Provider. Where a courier
                          company does not facilitate issuance of COF and/or
                          filing of FIR, Shippigo’s liability is limited to the
                          maximum liability limit as per the terms of the
                          pricing proposal.
                        </li>
                        <li>
                          In case of failure on part of the User to mention the
                          product value and/or invoice value and/or COD
                          collectable value and/or product description, the User
                          Forfeits and waives all right to claim against such
                          shipments and Shippigo and the courier company shall
                          not be liable for any claim against such shipments
                        </li>
                        <li>
                          Any approved claim as per the maximum liability limit
                          shall be updated against the account as a Credit Note
                          (CN)
                        </li>
                        <li>
                          In relation to claims for damage shipments, it is
                          hereby clarified that the amount of compensation shall
                          depend upon the quantum/percentage of damage as
                          against the full product value of the shipment; and
                          the User agrees to forfeit and waive off any right to
                          claim for damage to the product, packaged inside a
                          package; and
                        </li>
                        <li>
                          the User agrees to forfeit and waive off any right to
                          claim for damage to the product, packaged inside a
                          package; and
                        </li>
                        <li>
                          In case of claims pertaining to international
                          shipments, the maximum liability shall not apply, and
                          the liability of Shippigo shall be limited to the
                          amount of compensation it will receive from the
                          concerned courier partner (based on their respective
                          policy not limited to service product, origin country,
                          destination country, product, value of shipment). For
                          e.g., in case of any international shipment through
                          DHL / Bluedart / FedEx / UPS / TNT / Aramex / Bombino
                          / DTDC / Delhivery / etc., the User shall be
                          compensated as per the following policy of DHL /
                          Bluedart / FedEx / UPS / TNT / Aramex / Bombino / DTDC
                          / Delhivery / Etc. (subject to any subsequent change
                          by DHL / Bluedart / FedEx / UPS / TNT / Aramex /
                          Bombino / DTDC / Delhivery / Etc.)
                          <ol>
                            <li>
                              The maximum liability limit of the international
                              courier company shall be as per the service type
                              selected, the product shipped, origin country,
                              destination country, value of the shipment.
                            </li>
                          </ol>
                        </li>
                        <li>
                          Any claim amount already credited to the User on
                          account of incorrect status or shipment shall be
                          refunded by the User (by way of deduction from the
                          User’s wallet, future COD amounts or otherwise) in
                          case the concerned shipment has been traced and
                          Delivered / RTO Delivered / Reverse Delivered to the
                          Consignee / Shipper.
                        </li>
                      </ol>
                    </li>
                    <li>
                      The User agrees that all claims relating to: (i) damage to
                      the shipment must be notified to Shippigo in writing
                      within twenty-four (24) hours of the delivery of shipment;
                      and (ii) loss/theft of the shipment must be notified to
                      Shippigo in writing within thirty (30) days of the
                      shipment pickup date or twenty-four (24) hours of shipment
                      delivery date.
                    </li>
                    <li>
                      It is hereby informed and agreed by the User, that
                      Shippigo and/or its courier partner shall not be
                      responsible and / or be liable for any claims, loss,
                      damage, theft, misappropriation or consequences arising
                      out of contents of any such shipments which include liquid
                      items/products (including but not limited to liquid
                      cosmetic and beauty products) and/or perishable
                      items/products and/or fragile item/products and/or
                      precious items/products and/or any document (critical or
                      non-critical)
                    </li>
                    <li>
                      It is hereby informed and agreed that Shippigo and/or its
                      courier partner shall not be responsible for any pilferage
                      or lost shipments for shipments packaged in non-branded
                      (i.e., without company’s trademark) package sealed by
                      non-branded adhesive tape.
                    </li>
                    <li>
                      It is hereby informed and agreed that Shippigo and/or its
                      courier partner shall not be responsible for any
                      statutory/airline/port authority/rail authority/rail
                      operator claim due to any reasons not limited to contents
                      of shipments, inadequate documentation, leakage, and
                      damage to other parcels caused due to the User’s shipments
                      and the User agrees to indemnify Shippigo and its courier
                      partner against any such claim
                    </li>
                    <li>
                      The User agrees that Shippigo and the Service Provider
                      assumes no liability to the User or any other person for
                      any loss or expense due to the User’s failure to comply
                      with any provisions.
                    </li>
                    <li>
                      It is hereby informed and agreed that SHIPPIGO and/or its
                      courier partner(s) shall not be responsible for any damage
                      to the shipments which include liquid or fragile
                      items/products (including but not limited to liquid
                      cosmetic, beauty products and glass items).
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>TERMINATION</strong>
                  <ol>
                    <li>
                      Shippigo services stay active till 5 (Five) days from the
                      date of the last unpaid invoice, the User shall be charged
                      for the period for which the invoice has been raised. The
                      User must request termination before the next billing
                      cycle starts and/or the next invoice, is generated, or the
                      cancelation request does not count. There is no pro-rated
                      refund of remaining service period in the current billing
                      cycle.
                    </li>
                    <li>
                      The customer can request for termination by simply writing
                      an email to the support email address mentioned on the
                      Website with the following information and request of
                      termination:
                      <ol>
                        <li>Name of the Entity.</li>
                        <li>Name of the webstore; and</li>
                        <li>Reason for termination.</li>
                      </ol>
                    </li>
                    <li>
                      The termination of this Agreement shall be without
                      prejudice to the accrued rights of Either Party, including
                      (without limitation) the shipping, payment, and any other
                      liability of that Party to the Other Party.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </Text>
          <hr />
          <SubHeading>
            <h2>
              ANNEXURE B - Prohibited / Banned, Dangerous, Restricted Products &
              Services
            </h2>
          </SubHeading>
          <Text>
            <div>
              <p>
                You shall not, directly, or indirectly, offer or attempt to
                offer or trade or attempt to trade in any item, the dealing of
                which is prohibited or restricted in any manner under the
                provisions of any applicable law, rule, regulation, or guideline
                for the time being in force.
              </p>
              <div>
                <article>
                  Without prejudice to the generality of the above, Shippigo
                  does not permit hosting / selling / shipping of following
                  items:
                </article>
                <ol style={{ paddingLeft: "0px" }}>
                  <li>
                    <strong>“Securities”</strong> within the meaning of the
                    Securities Contract Regulation Act, 1956, including shares,
                    bonds, debentures, etc. and / or any other financial
                    instruments / assets of any description. While this does not
                    prevent a share broking firm to host its website with
                    Shippigo, the trading of the shares through an e-store made
                    available by Shippigo (or Shippigo stores) is prohibited.
                  </li>
                  <li>
                    Living, dead creatures and / or the whole or any part of any
                    animal which has been kept or preserved by any means whether
                    artificial or natural including rugs, skins, specimens of
                    animals, antlers, horns, hair, feathers, nails, teeth, musk,
                    eggs, nests, other animal products of any description the
                    sale and purchase of which is prevented or restricted in any
                    manner by applicable laws (including those prohibited under
                    The Wildlife Protection Act, 1972).
                  </li>
                  <li>Weapons of any description.</li>
                  <li>
                    Liquor, tobacco products, drugs, psychotropic substances,
                    narcotics, intoxicants of any description, medicines,
                    palliative / curative substances.
                  </li>
                  <li>
                    Religious items, including books, artifacts, etc. of any
                    description or any other such item which is likely to affect
                    the religious sentiments of any person.
                  </li>
                  <li>
                    <strong>“Antiquities”</strong> and{" "}
                    <strong>“Art Treasures”</strong> in violation of the
                    provisions of the Antiquities and Art Treasures Act, 1972(
                    <strong>“the Act”</strong>).
                  </li>
                  <li>Used cellular phone SIM Cards.</li>
                  <li>
                    Furthermore, you agree to display and adhere to a term of
                    use or other user-type agreement, as well as a privacy
                    policy, governing Your operation of your Store and your
                    conduct with your Store’s customers.
                  </li>
                </ol>
              </div>

              <div>
                <article>
                  <strong>Dangerous Goods:</strong>
                </article>
                <ol style={{ paddingLeft: "0px" }}>
                  <li>Oil-based paint and thinners (flammable liquids)</li>
                  <li>Industrial solvents</li>
                  <li>Insecticides, garden chemicals (fertilizers, poisons)</li>
                  <li>Lithium batteries</li>
                  <li>Magnetized materials</li>
                  <li>
                    Machinery (chain saws, outboard engines containing fuel or
                    that have contained fuel)
                  </li>
                  <li>
                    Fuel for camp stoves, lanterns, torches, or heating elements
                  </li>
                  <li>Automobile batteries</li>
                  <li>Infectious substances</li>
                  <li>
                    Any compound, liquid or gas that has toxic characteristics
                  </li>
                  <li>Bleach</li>
                  <li>Flammable adhesives</li>
                  <li>Arms and ammunitions (including air guns)</li>
                  <li>Dry ice (Carbon Dioxide, Solid)</li>
                  <li>
                    Any Aerosols, liquids and/or powders or any other flammable
                    substances classified as Dangerous Goods for transport by
                    Air
                  </li>
                </ol>
              </div>

              <div>
                <article>
                  <strong>Restricted Items:</strong>
                </article>
                <ol style={{ paddingLeft: "0px" }}>
                  <li>Precious stones, gems, and jewellery</li>
                  <li>
                    Uncrossed (bearer) drafts / cheque, currency, and coins
                  </li>
                  <li>Poison</li>
                  <li>Firearms, explosives, and military equipment.</li>
                  <li>Hazardous and radioactive material</li>
                  <li>Foodstuff and liquor</li>
                  <li>Any pornographic material</li>
                  <li>Hazardous chemical items</li>
                </ol>
              </div>

              <div>
                <article>
                  <strong>Counterfeit or Fraud Products/Shipments:</strong>
                </article>
                <p>
                  It is the policy of Shippigo to conduct all business
                  activities in compliance with the rules and regulations
                  applicable to the industry and laws of India, with the highest
                  ethical standards. In this regard, Shippigo has a
                  zero-tolerance policy with respect to counterfeit or fraud
                  products/shipments (including products/shipments which are
                  misrepresented in their origin or quality, or which are fake,
                  cloned, duplicate or likewise products/shipments).
                </p>
                <p>
                  Accordingly, in the event Shippigo believes that you or any of
                  your customer are shipping/selling (or have shipped)
                  counterfeit or fraud product/shipment (including any
                  counterfeit electronic product, not limited to mobile phones,
                  smart watches and likewise products), Shippigo would
                  inter-alia have the right:
                </p>
                <ol style={{ paddingLeft: "0px" }}>
                  <li>to seize such product/shipment,</li>
                  <li>
                    to report the incident to the appropriate government
                    authority/police station,
                  </li>
                  <li>
                    to blacklist you/your customer from trading/doing business
                    with Shippigo,
                  </li>
                  <li>
                    to levy liquidated damages of upto Rs. 10,000 per
                    counterfeit/fraud shipment (amount and counterfeit/fraud
                    shipment to be decided by Shippigo at its sole discretion)
                    and applicable GST amount on said damages, on account of
                    estimated legal expenses which will be spent by Shippigo or
                    actual expenses in case the actual amount exceeds the above
                    threshold of Rs.10,000/-,
                  </li>
                  <li>
                    to levy liquidated damages of up to Rs. 1,00,000 (and
                    applicable GST amount on said damages) on you/your customer
                    (amount to be decided by Shippigo at its sole discretion) on
                    account of causing reputational and goodwill loss to
                    Shippigo,
                  </li>
                  <li>
                    to levy/charge a <strong>“security deposit”</strong> of an
                    appropriate amount (amount to be decided by Shippigo at its
                    sole discretion) from you to cover any future losses which
                    Shippigo may incur on account of counterfeit/fraud shipment
                    made by you,
                  </li>
                  <li>
                    to block/retain the entire COD amount of yours/your customer
                    lying with Shippigo/its courier partner,
                  </li>
                  <li>
                    to seize all the products of yours/your customer lying with
                    Shippigo/its courier partner and to dispose such products
                    (without any intimation to you) after a period of 90 days
                    from the date of seizure, and/or
                  </li>
                  <li>
                    to forfeit the entire security deposit amount lying with
                    Shippigo.
                  </li>
                </ol>
              </div>

              <div>
                <article>
                  <strong>Disputed Shipments/Cases:</strong>
                </article>
                <p>
                  Shippigo, in its sole discretion, shall have the right to levy
                  damages/charges (along with the applicable GST amount) on you
                  in relation to shipments/cases which have been disputed by the
                  courier companies, your customers or by any third party
                  (including any governmental authority/department). The amount
                  of said damages/charges shall be decided by Shippigo in its
                  sole discretion and may vary from case to case.
                </p>
              </div>

              <div>
                <article>
                  <strong>
                    Shipping Non-Essential Items in Government Prohibited Areas
                  </strong>
                </article>
                <p>
                  In the event Shippigo believes that you are shipping (or have
                  shipped) non-essential items/products in the
                  restricted/prohibited area (such as red and containment
                  zone/area, as declared by the Central or the relevant State
                  Governments of India), then Shippigo would inter-alia have the
                  right to levy penalty or liquidated damages on you of Rs.
                  10,000 per shipment (along with applicable GST amount) on
                  account of estimated legal expenses which will be spent by
                  Shippigo and for causing of reputational and goodwill loss to
                  Shippigo, or the actual damages/losses/expenses in case the
                  actual amount exceeds the above minimum threshold of
                  Rs.10,000/-, as may be determined at the sole discretion of
                  Shippigo.
                </p>
              </div>
            </div>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
