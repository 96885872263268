import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { toast } from "react-toastify";

const {
  REACT_APP_ENVIRONMENT,
  REACT_APP_CONSOLE_ERROR,
  REACT_APP_LOG_ERROR,
  REACT_APP_VERSION,
} = process.env;

function init() {
  if (REACT_APP_ENVIRONMENT !== "production") {
    console.log(process.env);
  }
  console.log("App version:", REACT_APP_VERSION);

  if (REACT_APP_LOG_ERROR === "yes") {
    Sentry.init({
      dsn: "https://3cb86572bb658e20a168fdef21ef1a8c@o4505622855286784.ingest.sentry.io/4505640314601472",
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.5,
      sampleRate: 0.5,
      release: REACT_APP_VERSION,
      environment: REACT_APP_ENVIRONMENT,
    });
  }
}

function log(error) {
  if (REACT_APP_CONSOLE_ERROR === "yes") {
    console.error(error);
  }

  if (REACT_APP_LOG_ERROR === "yes") {
    // log to sentry
    Sentry.captureException(error);
  }

  toast.error("Sorry, Something went wrong.");
}

export default {
  log,
  init,
};
