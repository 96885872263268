import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import {
  PrimaryButton as PrimaryButtonBase,
  SecondaryButton as SecondaryButtonBase,
} from "components/misc/Buttons.js";
import ProcessingIcon from "components/misc/ProcessingButtonIcon";
import Form from "./../common/form";
import Joi from "joi";
import _logger from "services/logService";
import _notification from "services/notificationService";
import "./../../styles/timeline.css";
import { trackOrder } from "services/trackService";
import { transformDate, transformTime } from "./../../shared/utility";

// for styled modal
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50`}
  }
  &.mainHeroModal__content {
    ${tw`xl:mx-auto m-4 sm:m-16 text-base max-w-screen-sm absolute inset-x-0 top-0 flex flex-col justify-center rounded-md bg-white outline-none`}
  }
  .content {
    ${tw`w-full p-5`}
  }
`;
const ModalTopBar = tw.div`flex flex-row justify-between bg-primary-500 py-4 px-5 rounded-t-md`;
const ModalContent = tw.div`w-full p-5`;
const ModalFooter = tw.div`flex flex-row w-full p-5 pt-0`;
const Heading = tw.h1`text-xl text-white`;
const CloseModalButton = tw.button`text-white`;
// for styled modal

const FormTag = tw.form`text-sm flex flex-col w-full`;
const SubmitButton = tw(PrimaryButtonBase)`px-6 py-2`;
const BackButton = tw(SecondaryButtonBase)`px-6 py-2`;

class TrackOrderModal extends Form {
  state = {
    data: {
      awb: "",
      captcha: "",
    },
    errors: {},
    orderData: {},
    trackingData: [],
    loadingIndicator: false,
  };

  schema = Joi.object({
    awb: Joi.string().alphanum().required().trim().label("AWB Number"),
    captcha: Joi.string().required().label("Captcha"),
  });

  toggleLoader = (boolean) => {
    this.setState({ loadingIndicator: boolean });
  };

  handleBack = () => {
    this.resetForm();
    this.setState({ orderData: {}, trackingData: [] });
  };

  doSubmit = async () => {
    try {
      this.toggleLoader(true);

      const { awb = "", captcha } = this.state.data;

      const response = await trackOrder({
        awb: awb.trim(),
        captcha,
      });

      const {
        success,
        data: trackingData,
        msg,
        status,
        transitDays,
        estimatedDeliveryDate,
      } = response;

      if (success && trackingData && trackingData.length) {
        // update state with tracking details
        this.setState({
          trackingData,
          orderData: { status, transitDays, estimatedDeliveryDate },
        });
      } else {
        _notification.show(msg || "No tracking details found.", "warn");
      }
    } catch (error) {
      _logger.log(error);
    } finally {
      this.toggleLoader(false);
    }
  };

  render() {
    const {
      loadingIndicator,
      trackingData,
      data: formData,
      orderData,
    } = this.state;
    const { modalIsOpen, toggleModal } = this.props;

    return (
      <StyledModal
        closeTimeoutMS={300}
        className="mainHeroModal"
        isOpen={modalIsOpen}
        onRequestClose={() => toggleModal()}
        shouldCloseOnOverlayClick={true}
      >
        <ModalTopBar>
          <Heading>Track Order</Heading>
          <CloseModalButton onClick={() => toggleModal()}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
        </ModalTopBar>
        <ModalContent>
          {trackingData.length ? (
            <React.Fragment>
              <div className="order-details">
                <div>
                  <p>
                    <span>AWB Number: </span>
                    <span>{formData.awb}</span>
                  </p>
                  <p>
                    <span>Status: </span>
                    <span>{orderData.status || "NA"}</span>
                  </p>
                </div>
                <div>
                  <p>
                    <span>Transit Days: </span>
                    <span>{orderData.transitDays ?? "NA"}</span>
                  </p>
                  <p>
                    <span>Estimated Delivery Date: </span>
                    <span>
                      {transformDate(orderData.estimatedDeliveryDate)}
                    </span>
                  </p>
                </div>
              </div>

              <div className="timeline">
                <ul>
                  {trackingData.map((track, index) => (
                    <li key={index}>
                      <div className="date-and-time">
                        <span className="date">
                          {transformDate(track.updatedOn)}
                        </span>
                        <span className="time mt-2">
                          {transformTime(track.updatedOn)}
                        </span>
                      </div>

                      <div className="status-and-location">
                        <span className="status">{track.status}</span>

                        {/* for small devices */}
                        <div className="date-and-time-small">
                          <span className="date">
                            {transformDate(track.updatedOn)}
                          </span>
                          <span> at </span>
                          <span className="time mt-2">
                            {transformTime(track.updatedOn)}
                          </span>
                        </div>

                        {track.details && <span>{track.details}</span>}
                        <span className="mt-2">{track.place}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </React.Fragment>
          ) : (
            <FormTag>
              {this.renderInput("awb", "AWB Number *", "text", "100")}
              <div tw="mt-2">{this.renderCaptcha("captcha")}</div>
            </FormTag>
          )}
        </ModalContent>
        <ModalFooter>
          {trackingData.length ? (
            <BackButton
              type="button"
              style={{ width: "fit-content" }}
              onClick={this.handleBack}
            >
              Back
            </BackButton>
          ) : (
            <SubmitButton
              type="submit"
              disabled={loadingIndicator}
              style={{ width: "fit-content" }}
              onClick={this.handleSubmit}
            >
              {loadingIndicator && <ProcessingIcon />}
              Track
            </SubmitButton>
          )}
        </ModalFooter>
      </StyledModal>
    );
  }
}

export default TrackOrderModal;
