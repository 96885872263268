const dateFormat = { year: "numeric", month: "short", day: "numeric" };
const timeFormat = { hour: "numeric", minute: "2-digit" };

export const transformDate = (date) => {
  if (!date) return "NA";

  const humanReadableDate = new Date(date).toLocaleDateString(
    "en-IN",
    dateFormat
  );

  return humanReadableDate;
};

export const transformTime = (date) => {
  if (!date) return "NA";

  const humanReadableTime = new Date(date).toLocaleTimeString(
    "en-IN",
    timeFormat
  );

  return humanReadableTime;
};
