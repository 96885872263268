import React, { Component } from "react";
import Input from "./input";
import Select from "./select";
import Radio from "./radio";
import TextArea from "./textarea";
import Captcha from "./captcha";

class Form extends Component {
  state = { data: {}, errors: {} };

  constructor(props) {
    super(props);
    this.captchaChildComponent = React.createRef();
  }

  validate = () => {
    const options = { abortEarly: false };
    const { error } = this.schema.validate(this.state.data, options);

    const errors = {};

    if (!error) return null;
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  validateProperty = ({ name, value }) => {
    const formControlSchema = this.schema.extract(name);
    const { error } = formControlSchema.validate(value);
    return error ? error.details[0].message : null;
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handleCaptchaChange = (currentTarget) => {
    this.handleChange({ currentTarget });
  };

  resetForm = () => {
    const data = { ...this.state.data };

    for (const name in data) {
      data[name] = "";
    }

    this.setState({ data });
  };

  resetCaptcha = () => {
    const data = { ...this.state.data };

    if (data["captcha"]) {
      this.captchaChildComponent.current.resetCaptcha();
    }
  };

  renderInput(name, label, type = "text", maxLength = "256") {
    const { data, errors } = this.state;

    return (
      <Input
        type={type}
        label={label}
        name={name}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
        maxLength={maxLength}
      />
    );
  }

  renderTextArea(name, label, type = "text", maxLength = "2048") {
    const { data, errors } = this.state;

    return (
      <TextArea
        type={type}
        label={label}
        name={name}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
        maxLength={maxLength}
      />
    );
  }

  renderSelect(name, label, options) {
    const { data, errors } = this.state;

    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  // with bootstrap styling
  renderRadio(name, label, options) {
    const { data, errors } = this.state;

    return (
      <Radio
        name={name}
        label={label}
        value={data[name]}
        options={options}
        error={errors[name]}
        onChange={this.handleChange}
      />
    );
  }

  // with bootstrap styling
  renderButton(label) {
    return (
      <button disabled={this.validate()} className="btn btn-primary mt-3">
        {label}
      </button>
    );
  }

  renderCaptcha(name) {
    const { errors } = this.state;

    return (
      <Captcha
        ref={this.captchaChildComponent}
        name={name}
        error={errors[name]}
        onChange={(value) => this.handleCaptchaChange({ name, value })}
      />
    );
  }
}

export default Form;
